import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { CourseActivitySection } from '@models/course/course-activity-section.model';
import { CourseCategory } from '@models/course-category.model';
import { Member } from '@models/member.model';
import { CourseAssignment } from '@models/course-assignment.model';
import { CourseMemberResult } from '@models/course/member/course-member-result.model';
import { CourseReminder } from '@models/course/course-reminder.model';
import { OrganizationLevelCourse } from '@models/organization/organization-level-course.model';
import { VisibilityPermission } from '@models/organization/visibility-permission.model';
import { CourseActivity } from './course/course-activity.model';

@JsonApiModelConfig({
  type: 'courses',
})
export class Course extends JsonApiModel {
  @Attribute()
  description!: string;

  @Attribute({ serializedName: 'estimated_time_in_minutes' })
  estimatedTimeInMinutes!: number;

  @Attribute({ serializedName: 'net_time_in_minutes' })
  netTimeInMinutes!: number;

  @Attribute()
  language!: string;

  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'certificate_template_id' })
  certificateId!: number | null;

  @Attribute({ serializedName: 'hide_course' })
  hideCourse!: boolean;

  @Attribute({ serializedName: 'allow_comments' })
  allowComments!: boolean;

  @Attribute({ serializedName: 'collect_feedback' })
  collectFeedback!: boolean;

  @Attribute({ serializedName: 'self_assignment' })
  selfAssignment!: boolean;

  @Attribute()
  deadline!: Date;

  @Attribute({ serializedName: 'time_limit' })
  timeLimit!: number;

  @Attribute({ serializedName: 'time_limit_unit' })
  timeLimitUnit!: string;

  @Attribute({ serializedName: 'repetition_enabled' })
  repetitionEnabled!: boolean;

  @Attribute({ serializedName: 'repetition_date' })
  courseRepetitionDate!: Date;

  @Attribute({ serializedName: 'repetition' })
  courseRepetition!: number;

  @Attribute({ serializedName: 'repetition_unit' })
  courseRepetitionUnit!: string;

  @Attribute({ serializedName: 'completion_level' })
  completionLevel!: number;

  @Attribute({ serializedName: 'no_completion' })
  noCompletion!: boolean;

  @Attribute({ serializedName: 'lesson_count' })
  lessonCount!: number;

  @Attribute({ serializedName: 'gamification_points' })
  gamificationPoints!: number;

  @Attribute({ serializedName: 'course_member_assignment_date' })
  courseMemberAssignmentDate!: Date;

  @Attribute({ serializedName: 'free_activities_order' })
  freeActivitiesOrder!: boolean;

  @Attribute({ serializedName: 'existing_results' })
  hasResults!: boolean;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute({ serializedName: 'author_id' })
  authorId!: string;

  // repetition: [repeat_never, repeat_after_a_month, repeat_after_6_month, repeat_after_a_year]
  @Attribute({ serializedName: 'cover_image' })
  coverImage!: string | null;
  // start_date: DateTime
  // end_date: DateTime
  // created_at: DateTime
  // updated_at: DateTime

  @Attribute({ serializedName: 'training_editors_count' })
  trainingEditorCount!: number;

  @Attribute({ serializedName: 'self_assignment_approval' })
  selfAssignmentApproval!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_group_leader' })
  selfAssignmentApprovalGroupLeader!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_manager' })
  selfAssignmentApprovalManager!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_author' })
  selfAssignmentApprovalAuthor!: boolean;

  @BelongsTo()
  author!: Member;

  @HasMany({ key: 'course_activity_sections' })
  courseActivitiesSections!: CourseActivitySection[];

  @HasMany({ key: 'course_activities' })
  courseActivities!: CourseActivity[];

  @HasMany({ key: 'course_categories' })
  courseCategories!: CourseCategory[];

  @HasMany({ key: 'course_assignments' })
  courseAssignments: CourseAssignment[] = [];

  @HasMany({ key: 'reminders' })
  courseReminders!: CourseReminder[];

  @HasMany({ key: 'organisation_levels_courses' })
  organizationLevelCourses!: OrganizationLevelCourse[];

  @HasMany({ key: 'visibility_permissions' })
  visibilityPermissions!: VisibilityPermission[];

  @HasMany({ key: 'editors' })
  editors!: Member[];

  courseResult: CourseMemberResult | undefined;
}
