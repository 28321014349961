export enum Section {
  TALENTS = 'talents',
  GROUPS = 'groups',
  COURSES = 'courses',
  LEARNING_PATHS = 'learningPaths',
  ILT_COURSES = 'iltCourses',
  CATEGORIES = 'categories',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  REPORTING = 'reporting',
  TRAINING_SCHEDULES = 'trainingSchedules',
}
