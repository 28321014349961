import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { User } from '@models/user.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Datastore } from '@services/datastore';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private authService: AuthService,
    public httpClient: HttpClient,
    private datastore: Datastore,
  ) {}

  public getCurrentUser(): Observable<User | null> {
    return this.authService.getUser();
  }

  public getUserById(userId: number): Observable<User> {
    return this.datastore.findRecord(User, userId.toString());
  }
}
