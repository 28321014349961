/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '@app/models/course.model';
import { Member } from '@app/models/member.model';
import { CourseMemberResult } from '@app/models/course/member/course-member-result.model';

@JsonApiModelConfig({
  type: 'courses_members',
})
export class CoursesMember extends JsonApiModel {
  @Attribute({ serializedName: 'course_id' })
  courseId!: string;

  @Attribute({ serializedName: 'member_id' })
  memberId!: string;

  @Attribute()
  status!: string;

  @BelongsTo()
  member: Member | undefined;

  @BelongsTo()
  course: Course | undefined;

  @BelongsTo({ key: 'course_member_result' })
  courseMemberResult!: CourseMemberResult;
}
