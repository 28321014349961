import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { LANGUAGES } from '@core/constants/language.constants';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private defaultLang = LANGUAGES.EN;
  private supportedLangs = [
    LANGUAGES.EN.toString(),
    LANGUAGES.DE.toString(),
    LANGUAGES.ES.toString(),
    LANGUAGES.FR.toString(),
    LANGUAGES.IT.toString(),
  ];
  private currentLanguage = LANGUAGES.EN.toString();

  constructor(
    private translateService: TranslateService,
    private appTitle: Title,
  ) {}

  setMemberLanguage(memberLang: string, companyDefaultLang: string): void {
    if (memberLang && this.supportedLangs.includes(memberLang)) {
      this.currentLanguage = memberLang;
    } else {
      const browserLang = this.translateService.getBrowserLang();
      if (browserLang && this.supportedLangs.includes(browserLang)) {
        this.currentLanguage = browserLang;
      } else if (
        companyDefaultLang &&
        this.supportedLangs.includes(companyDefaultLang)
      ) {
        this.currentLanguage = companyDefaultLang;
      }
    }

    this.setLanguage();
  }

  setLanguage(): void {
    this.translateService.addLangs(this.supportedLangs);
    this.translateService.setDefaultLang(this.defaultLang);

    this.translateService
      .use(this.currentLanguage)
      .pipe(take(1))
      .subscribe(() =>
        this.appTitle.setTitle(this.translateService.instant('app.title')),
      );
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }
}
