import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';

import { AuthService } from '@services/auth.service';
import { SnackBarService } from '@services/snack-bar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private snackbarService: SnackBarService,
    private notify: NotificationService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (errorResponse: unknown) => {
          if (
            !(errorResponse instanceof HttpErrorResponse) ||
            errorResponse.status === 0
          ) {
            return;
          }

          if (
            request.url.indexOf('token_refresh') === -1 &&
            errorResponse.status === 401
          ) {
            this.authService.logout(undefined, {
              return: location.href,
              sessionExpired: true,
            });
          } else if (
            request.url.indexOf('users/login') === -1 &&
            request.url.indexOf('members/personio_import') === -1 &&
            request.url.indexOf('members/custom_attributes') === -1 &&
            request.url.indexOf('users/resend_invitation') === -1 &&
            request.url.indexOf('h5p-service/content-creator') === -1 &&
            !request.url.endsWith('ilt_event_assignments')
          ) {
            if (errorResponse.status === 401) {
              this.snackbarService.error(
                `${this.translateService.instant(
                  'notification.error.e401.title',
                )}
                ${this.translateService.instant(
                  'notification.error.e401.message',
                )}`,
              );
            } else {
              this.notify.handleError(errorResponse);
            }
          }
        },
      ),
    );
  }
}
