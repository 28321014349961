import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { IH5PEventStatement } from '@shared/h5p/components/h5p-player-standalone/h5p-player-standalone.component';

@Injectable({
  providedIn: 'root',
})
export class ActivityEventQueueService {
  private lambdaUrl = `${environment.enqueueActivityEventEndpoint}`;
  constructor(private http: HttpClient) {}
  // TODO reporting under contstruction

  // tslint:disable-next-line:no-any
  pushEvent(content: IH5PEventStatement): Observable<any> {
    return this.http.post(
      this.lambdaUrl,
      {
        body: content,
      },
      {
        withCredentials: true,
      },
    );
  }
}
