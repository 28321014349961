import { ILTCourse } from '@models/ilt-course.model';
import { CourseCategory } from '@models/course-category.model';
import { Course } from '@models/course.model';
import { LearningPath } from '@models/learning-path.model';
import { orderBy } from 'lodash-es';

export class CourseCategoryHelper {
  static getCategoriesFromCoursesAndLPs(
    allCourses: Course[],
    allLearningPaths: LearningPath[] = [],
  ): CourseCategory[] {
    const allCourseCategories: CourseCategory[] = [];
    for (const course of allCourses) {
      if (!course.courseCategories) {
        course.courseCategories = [];
      }
      for (const category of course.courseCategories) {
        if (allCourseCategories.findIndex(x => x.id === category.id) === -1) {
          category.courses = [course];
          category.learningPaths = [];
          allCourseCategories.push(category);
        } else {
          if (!category.courses) {
            category.courses = [];
          }
          if (!category.learningPaths) {
            category.learningPaths = [];
          }
          if (category.courses.findIndex(y => y.id === course.id) === -1) {
            category.courses.push(course);
          }
        }
      }
    }

    for (const learningPath of allLearningPaths) {
      if (!learningPath.courseCategories) {
        learningPath.courseCategories = [];
      }
      for (const category of learningPath.courseCategories) {
        if (allCourseCategories.findIndex(x => x.id === category.id) === -1) {
          category.learningPaths = [learningPath];
          category.courses = [];
          allCourseCategories.push(category);
        } else {
          if (!category.learningPaths) {
            category.learningPaths = [];
          }
          if (!category.courses) {
            category.courses = [];
          }
          if (
            category.learningPaths.findIndex(y => y.id === learningPath.id) ===
            -1
          ) {
            category.learningPaths.push(learningPath);
          }
        }
      }
    }

    return allCourseCategories;
  }

  static getCategoriesFromLearningPaths(
    allLearningPaths: LearningPath[],
  ): CourseCategory[] {
    const allCourseCategories: CourseCategory[] = [];
    for (const path of allLearningPaths) {
      if (!path.courseCategories) {
        path.courseCategories = [];
      }
      for (const category of path.courseCategories) {
        if (allCourseCategories.findIndex(x => x.id === category.id) === -1) {
          category.learningPaths = [path];
          allCourseCategories.push(category);
        } else {
          if (!category.learningPaths) {
            category.learningPaths = [];
          }
          if (category.learningPaths.findIndex(y => y.id === path.id) === -1) {
            category.learningPaths.push(path);
          }
        }
      }
    }
    return allCourseCategories;
  }

  static getCategoriesFromILTCourses(
    allILTCourses: ILTCourse[],
  ): CourseCategory[] {
    const allCourseCategories: CourseCategory[] = [];
    for (const course of allILTCourses) {
      if (!course.courseCategories) {
        course.courseCategories = [];
      }
      for (const category of course.courseCategories) {
        if (allCourseCategories.findIndex(x => x.id === category.id) === -1) {
          allCourseCategories.push(category);
        }
        if (!category.iltCourses) {
          category.iltCourses = [];
        }
        if (category.iltCourses.findIndex(y => y.id === course.id) === -1) {
          category.iltCourses.push(course);
        }
      }
    }
    return allCourseCategories;
  }

  static sortCourseCategories(
    courseCategories: CourseCategory[],
  ): CourseCategory[] {
    const sortedCourseCategories: CourseCategory[] = [];

    const rootCourseCategories = courseCategories.filter(
      (courseCategory: CourseCategory) => courseCategory.parentId === undefined,
    );

    courseCategories
      .filter((courseCategory: CourseCategory) => !!courseCategory.parentId)
      .forEach(courseCategory => {
        const rootAncestor = this.getRootAncestor(
          courseCategories,
          courseCategory,
        );

        if (!rootAncestor) {
          rootCourseCategories.push(courseCategory);
        } else {
          if (
            !courseCategories.find(
              cC => cC.id === courseCategory.parentId?.toString(),
            ) &&
            rootAncestor.childIds.indexOf(parseInt(courseCategory.id, 10)) ===
              -1
          ) {
            // Only has grandparent
            rootAncestor.childIds.push(parseInt(courseCategory.id, 10));
          }
        }
      });

    this.addSortedCourseCategory(
      rootCourseCategories,
      courseCategories,
      sortedCourseCategories,
    );

    return sortedCourseCategories;
  }

  static hasNoAvailableAncestry(
    courseCategories: CourseCategory[],
    courseCategory: CourseCategory,
  ): boolean {
    return !this.getRootAncestor(courseCategories, courseCategory);
  }

  static addSortedCourseCategory(
    parents: CourseCategory[],
    courseCategories: CourseCategory[],
    sortedCourseCategories: CourseCategory[],
  ): void {
    orderBy(parents, 'order')
      .sort((a, b) => (a.ancestry ? 1 : b.ancestry ? -1 : 0))
      .map((parent: CourseCategory) => {
        sortedCourseCategories.push(parent);

        const children: CourseCategory[] = courseCategories.filter(
          (courseCategory: CourseCategory) =>
            parent.childIds.includes(+courseCategory.id),
        );

        this.addSortedCourseCategory(
          children,
          courseCategories,
          sortedCourseCategories,
        );
      });
  }

  private static getRootAncestor(
    courseCategories: CourseCategory[],
    courseCategory: CourseCategory,
  ): CourseCategory | undefined {
    const ancestry: string[] = courseCategory.ancestry?.split('/') || [];

    return courseCategories.find(cC => ancestry.indexOf(cC.id) > -1);
  }
}
