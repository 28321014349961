import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'course_talent_reports',
})
export class CourseMemberReport extends JsonApiModel {
  @Attribute({ serializedName: 'course_id' })
  courseId!: string;

  @Attribute({ serializedName: 'course_name' })
  courseName!: string;

  @Attribute({ serializedName: 'member_id' })
  memberId!: string;

  @Attribute({ serializedName: 'member_name' })
  memberName!: string;

  @Attribute({ serializedName: 'course_status' })
  courseStatus!: number;

  @Attribute({ serializedName: 'course_completed_date' })
  coursesCompletedDate!: Date;

  @Attribute({ serializedName: 'hire_date' })
  hireDate!: Date | null;

  @Attribute({ serializedName: 'member_progress' })
  courseProgressAvg!: string;

  @Attribute({ serializedName: 'course_score' })
  courseScore!: string;

  @Attribute({ serializedName: 'has_certificate' })
  hasCertificate!: boolean;

  @Attribute({ serializedName: 'member_avg_time_spent' })
  timeSpentInSecondsAvg!: number;

  @Attribute({ serializedName: 'net_time_duration' })
  netTimeInMinutes!: number;

  @Attribute({ serializedName: 'completion_level' })
  completionLevel!: string;

  @Attribute({ serializedName: 'member_course_rating' })
  courseRatingAvg!: string;

  @Attribute({ serializedName: 'orga_full_path' })
  orgaFullPath!: string;

  @Attribute({ serializedName: 'level_number' })
  orgaLevel!: string;

  @Attribute({ serializedName: 'orga_name' })
  orgaName!: string;

  @Attribute({ serializedName: 'has_feedback_report' })
  hasFeedbackReport!: string;

  @Attribute({ serializedName: 'course_assignment_date' })
  courseAssignmentDate!: Date;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute({ serializedName: 'member_course_deadline' })
  courseDeadlineDate!: Date;

  @Attribute({ serializedName: 'repetition_id' })
  repetitionId!: number;

  @Attribute({ serializedName: 'repetition_interval_date' })
  repetitionIntervalDate!: number;
}
