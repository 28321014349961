import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Member } from '../member.model';

@JsonApiModelConfig({
  type: 'member_options',
})
export class MemberOption extends JsonApiModel {
  @Attribute()
  key!: string;

  @Attribute()
  value!: string;

  @Attribute({ serializedName: 'option_type' })
  optionType!: string;

  @BelongsTo()
  member!: Member;
}
