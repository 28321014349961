import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Member } from '../member.model';

@JsonApiModelConfig({
  type: 'member_notifications',
})
export class MemberNotification extends JsonApiModel {
  @Attribute({ serializedName: 'name' })
  subject!: string;

  @Attribute({ serializedName: 'status' })
  notificationType!: string;

  @BelongsTo()
  member!: Member;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;
}
