import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { PermissionService } from '@services/permission.service';
import { IPermission } from '@core/interfaces/permission.interface';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private router: Router,
  ) {}

  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const navigateToDashboard = () => this.router.navigateByUrl('/my');
    return new Promise(resolve => {
      if (!next.data.roles) {
        return true;
      }

      this.permissionService
        .getPermission(next.data.roles.resource)
        .pipe(take(1))
        .subscribe(permission => {
          const key = next.data.roles.key as keyof IPermission;
          if (permission && !!permission[key]) {
            resolve(true);
          } else {
            navigateToDashboard();
            resolve(false);
          }
        });
    });
  }
}
