import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { CourseAssignment } from '@models/course-assignment.model';
import { ILTCourseAssignment } from '@models/ilt-course-assignment.model';
import { LearningPathAssignment } from './learning-path-assignment.model';
import { Course } from '@models/course.model';
import { Group } from '@models/group.model';
import { CompanySetting } from '@models/company-setting.model';
import { Gamification } from '@models/gamification/gamification.model';
import { MemberOption } from '@models/member/member-option.model';
import { ILTCourse } from './ilt-course.model';
import { ILTCourseEventAssignment } from './ilt-course/ilt-course-event-assignment.model';
import { OrganizationLevel } from '@models/organization/organization-level.model';
import { LearningPath } from '@models/learning-path.model';
import { CohortAssignment } from './training-schedule/training-schedule-cohort-assignment.model';

@JsonApiModelConfig({
  type: 'members',
})
export class Member extends JsonApiModel {
  @Attribute()
  email!: string;

  @Attribute({ serializedName: 'company_setting_id' })
  companySettingId!: string;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute({ serializedName: 'first_name' })
  firstName!: string;

  @Attribute({ serializedName: 'groups_count' })
  groupsCount!: number;

  @Attribute({ serializedName: 'last_name' })
  lastName!: string;

  @Attribute()
  gender!: string;

  @Attribute()
  status!: string;

  @Attribute({ serializedName: 'birth_date' })
  birthday!: Date | null;

  @Attribute({ serializedName: 'hire_date' })
  hireDate!: Date | null;

  @Attribute()
  language!: string;

  @Attribute({ serializedName: 'linked_in' })
  linkedIn!: string;

  @Attribute({ serializedName: 'other_profile' })
  otherProfile!: string;

  @Attribute({ serializedName: 'field_manager_id' })
  managerId!: string;

  @Attribute({ serializedName: 'manager_name' })
  managerName!: string;

  @Attribute()
  manager!: string;

  @Attribute()
  picture!: string;

  @Attribute()
  role!: string;

  @Attribute()
  department!: string;

  @Attribute({ serializedName: 'office_location' })
  officeLocation!: string;

  @Attribute({ serializedName: 'career_stage' })
  careerStage!: string;

  @Attribute()
  company!: string;

  @Attribute()
  salutation!: string;

  @Attribute({ serializedName: 'customer_code' })
  customerCode!: string;

  @Attribute({ serializedName: 'customer_number' })
  customerNumber!: string;

  @Attribute()
  position!: string;

  @Attribute()
  team!: string;

  @Attribute({ serializedName: 'employee_id' })
  employeeId!: string;

  @Attribute({ serializedName: 'time_format' })
  timeFormat!: string;

  @Attribute({ serializedName: 'time_zone' })
  timeZone!: string;

  @Attribute({ serializedName: 'show_gamification_badge' })
  showGamificationBadge!: string;

  @Attribute({ serializedName: 'show_gamification_ranking' })
  showGamificationRanking!: string;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute()
  consent!: boolean;

  @Attribute({ serializedName: 'reporting_feedback_permission' })
  reportingFeedbackPermission!: boolean;

  @Attribute({ serializedName: 'consent_date' })
  consentDate!: Date;

  @Attribute({ serializedName: 'ai_terms_agreed_to' })
  aiTermsAgreedTo!: boolean;

  @BelongsTo({ serializedName: 'company_setting' })
  companySetting!: CompanySetting;

  @BelongsTo()
  gamification: Gamification | undefined;

  @HasMany()
  groups!: Group[];

  @HasMany({ key: 'direct_groups' })
  directGroups!: Group[];

  @HasMany({ key: 'groups_via_rule' })
  groupsViaRule!: Group[];

  @HasMany()
  courses!: Course[];

  @HasMany({ key: 'ilt_courses' })
  iltCourses!: ILTCourse[];

  @HasMany({ key: 'learning_paths' })
  learningPaths!: LearningPath[];

  @Attribute({ serializedName: 'last_login' })
  lastLogin!: Date;

  @Attribute({ serializedName: 'last_activity' })
  lastActivity!: Date;

  @Attribute({ serializedName: 'brand_color' })
  brandColor!: string;

  @Attribute({ serializedName: 'interactive_elements_color' })
  interactiveElementsColor!: string;

  @Attribute({ serializedName: 'main_button_color' })
  mainButtonColor!: string;

  @Attribute({ serializedName: 'button_text_color' })
  buttonTextColor!: string;

  @Attribute({ serializedName: 'logo' })
  logo!: string;

  @Attribute({ serializedName: 'favicon' })
  favicon!: string;

  @Attribute({ serializedName: 'branding_name' })
  brandingName!: string;

  @Attribute({ serializedName: 'branding_id' })
  brandingID!: string;

  @Attribute({ serializedName: 'username' })
  userName!: string;

  @Attribute({ serializedName: 'force_password_change' })
  forcePasswordChange!: boolean;

  @Attribute({ serializedName: 'business_unit' })
  businessUnit!: string;

  @Attribute({ serializedName: 'division' })
  division!: string;

  @Attribute({ serializedName: 'custom_field_one' })
  customFieldOne!: string;

  @Attribute({ serializedName: 'custom_field_two' })
  customFieldTwo!: string;

  @Attribute({ serializedName: 'credits' })
  credits!: number;

  @HasMany({ key: 'course_assignments' })
  courseAssignments!: CourseAssignment[];

  @HasMany({ key: 'learning_path_assignments' })
  learningPathAssignments!: LearningPathAssignment[];

  @HasMany({ key: 'ilt_course_assignments' })
  iltCourseAssignments!: ILTCourseAssignment[];

  @HasMany({ key: 'ilt_event_assignments' })
  iltCourseEventAssignments!: ILTCourseEventAssignment[];

  @HasMany({ key: 'member_options' })
  memberOptions: MemberOption[] = [];

  @HasMany({ key: 'organisation_levels' })
  organizationLevels!: OrganizationLevel[];

  @HasMany({ key: 'cohort_assignments' })
  cohortAssignments: CohortAssignment[] = [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Attribute({ key: 'added_by' })
  addedBy? = '';

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get identifier(): string {
    return this.email || this.userName;
  }
}

export class MemberByRule extends Member {
  byRule!: boolean;
}
