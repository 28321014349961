/* tslint:disable:no-any */
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  template: `
    <div class="snack-bar">
      <mat-icon *ngIf="level == 'success'">check_circle</mat-icon>
      <mat-icon *ngIf="level == 'error'">cancel</mat-icon>

      <span class="snack-bar-msg">
        {{ message }}
      </span>
    </div>
  `,
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  public level: string;
  public message: string;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(@Inject(MAT_SNACK_BAR_DATA) private data: any) {
    this.level = data.level;
    this.message = data.message;
  }
}
