import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationCenterService {
  private notificationSubject = new Subject<boolean>();

  constructor() {}

  public notify(notify: boolean): void {
    this.notificationSubject.next(notify);
  }

  public notifyListener(): Observable<boolean> {
    return this.notificationSubject.asObservable();
  }
}
