<div class="dialog-content">
  <h2 mat-dialog-title>{{ 'attributes.talent.invite.importCsvTitle' | translate }}</h2>
  <div class="dialog-file">
    <ngx-dropzone
      #csvFileDropZone
      class="ngx-dropzone"
      (change)="onZipFileChange($event)"
      [multiple]="false"
      [accept]="'.csv'"
      [disableClick]="!!invitationCsvFile"
    >
      <ngx-dropzone-label class="ngx-dropzone-label"  *ngIf="!invitationCsvFile" fxLayout="column" fxLayoutAlign="center center">
        <button
          mat-stroked-button
          color="darkblue"
          class="filled no-border"
        >
          <span>{{ 'attributes.talent.invite.importCsvBtn' | translate }}</span>
        </button>

        <span class="mat-body">
          {{ 'reusable.dragFile' | translate }}
        </span>
      </ngx-dropzone-label>
      <div *ngIf="invitationCsvFile" class="ngx-dropzone-preview" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon class="cloud-upload">cloud_upload</mat-icon>
        <div *ngIf="!isValid" class="mat-body" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="highlight-off">highlight_off</mat-icon>
          <span>{{ 'attributes.talent.invite.importCsvInvalidFile' | translate }}</span>
        </div>
        <div *ngIf="isValid" class="mat-body" fxLayout="column" fxLayoutAlign="center center">
          <div fxLayout="row">
            <mat-icon class="check-circle">check_circle</mat-icon>
            <span>{{ 'attributes.talent.invite.importCsvValidFile' | translate }}</span>
          </div>
          <span [innerHTML]="'attributes.talent.invite.importCsvTalentsNumber' | translate:{ number: rowsNumber }"></span>
        </div>
      </div>
    </ngx-dropzone>
  </div>
  <div class="dialog-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="invitationCsvFile && isValid" class="description"> {{ 'courses.importCourseDesc' | translate }} </span>
    <div class="dialog-footer-actions" mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="12px">
      <button mat-stroked-button color="darkblue" [mat-dialog-close]="false">{{ 'common.cancel' | translate }}</button>
      <button *ngIf="!invitationCsvFile || isValid" [disabled]="!invitationCsvFile" color="yellow" class="no-border filled" mat-stroked-button [mat-dialog-close]="true" (click)="importCsv()">
        {{ 'common.import' | translate }}
      </button>
      <button *ngIf="invitationCsvFile && !isValid" color="darkblue" class="no-border filled" mat-stroked-button (click)="csvFileDropZone.showFileSelector()">
        {{ 'attributes.talent.invite.importCsvSelectNewFile' | translate }}
      </button>
    </div>
  </div>
</div>