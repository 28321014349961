import {
  Attribute,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';
import { Group } from '@models/group.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'bulk_invitations',
})
export class BulkInvitation extends JsonApiModel {
  @Attribute()
  emails!: string[];

  @Attribute()
  role!: string;

  @HasMany()
  groups!: Group[];
}
