import { environment } from 'environments/environment';

const { h5pBaseUrl, h5pSaveFrequencyInMinutes } = environment;

// TODO set member.id to user
export function getH5PIntegration(lang) {
  return {
    ajax: {
      contentUserData: '',
      setFinished: '',
    },
    ajaxPath: `${h5pBaseUrl}/ajax?action=`,
    editor: {
      filesPath: '/temp-files',
      fileIcon: {
        path: 'http://lumi.education/editor/images/binary-file.png',
        width: 50,
        height: 50,
      },
      ajaxPath: `${h5pBaseUrl}/ajax?action=`,
      baseUrl: h5pBaseUrl,
      ckeditorUrl: h5pBaseUrl + '/editor/ckeditor/',
      libraryUrl: '/editor/',
      copyrightSemantics: {
        name: 'copyright',
        type: 'group',
        label: 'Copyright information',
        fields: [
          {
            name: 'title',
            type: 'text',
            label: 'Title',
            placeholder: 'La Gioconda',
            optional: true,
          },
          {
            name: 'author',
            type: 'text',
            label: 'Author',
            placeholder: 'Leonardo da Vinci',
            optional: true,
          },
          {
            name: 'year',
            type: 'text',
            label: 'Year(s)',
            placeholder: '1503 - 1517',
            optional: true,
          },
          {
            name: 'source',
            type: 'text',
            label: 'Source',
            placeholder: 'http://en.wikipedia.org/wiki/Mona_Lisa',
            optional: true,
            regexp: {
              pattern: '^http[s]?://.+',
              modifiers: 'i',
            },
          },
          {
            name: 'license',
            type: 'select',
            label: 'License',
            default: 'U',
            options: [
              {
                value: 'U',
                label: 'Undisclosed',
              },
              {
                value: 'CC BY',
                label: 'Attribution',
                versions: [
                  {
                    value: '4.0',
                    label: '4.0 International',
                  },
                  {
                    value: '3.0',
                    label: '3.0 Unported',
                  },
                  {
                    value: '2.5',
                    label: '2.5 Generic',
                  },
                  {
                    value: '2.0',
                    label: '2.0 Generic',
                  },
                  {
                    value: '1.0',
                    label: '1.0 Generic',
                  },
                ],
              },
              {
                value: 'CC BY-SA',
                label: 'Attribution-ShareAlike',
                versions: [
                  {
                    value: '4.0',
                    label: '4.0 International',
                  },
                  {
                    value: '3.0',
                    label: '3.0 Unported',
                  },
                  {
                    value: '2.5',
                    label: '2.5 Generic',
                  },
                  {
                    value: '2.0',
                    label: '2.0 Generic',
                  },
                  {
                    value: '1.0',
                    label: '1.0 Generic',
                  },
                ],
              },
              {
                value: 'CC BY-ND',
                label: 'Attribution-NoDerivs',
                versions: [
                  {
                    value: '4.0',
                    label: '4.0 International',
                  },
                  {
                    value: '3.0',
                    label: '3.0 Unported',
                  },
                  {
                    value: '2.5',
                    label: '2.5 Generic',
                  },
                  {
                    value: '2.0',
                    label: '2.0 Generic',
                  },
                  {
                    value: '1.0',
                    label: '1.0 Generic',
                  },
                ],
              },
              {
                value: 'CC BY-NC',
                label: 'Attribution-NonCommercial',
                versions: [
                  {
                    value: '4.0',
                    label: '4.0 International',
                  },
                  {
                    value: '3.0',
                    label: '3.0 Unported',
                  },
                  {
                    value: '2.5',
                    label: '2.5 Generic',
                  },
                  {
                    value: '2.0',
                    label: '2.0 Generic',
                  },
                  {
                    value: '1.0',
                    label: '1.0 Generic',
                  },
                ],
              },
              {
                value: 'CC BY-NC-SA',
                label: 'Attribution-NonCommercial-ShareAlike',
                versions: [
                  {
                    value: '4.0',
                    label: '4.0 International',
                  },
                  {
                    value: '3.0',
                    label: '3.0 Unported',
                  },
                  {
                    value: '2.5',
                    label: '2.5 Generic',
                  },
                  {
                    value: '2.0',
                    label: '2.0 Generic',
                  },
                  {
                    value: '1.0',
                    label: '1.0 Generic',
                  },
                ],
              },
              {
                value: 'CC BY-NC-ND',
                label: 'Attribution-NonCommercial-NoDerivs',
                versions: [
                  {
                    value: '4.0',
                    label: '4.0 International',
                  },
                  {
                    value: '3.0',
                    label: '3.0 Unported',
                  },
                  {
                    value: '2.5',
                    label: '2.5 Generic',
                  },
                  {
                    value: '2.0',
                    label: '2.0 Generic',
                  },
                  {
                    value: '1.0',
                    label: '1.0 Generic',
                  },
                ],
              },
              {
                value: 'GNU GPL',
                label: 'General Public License',
                versions: [
                  {
                    value: 'v3',
                    label: 'Version 3',
                  },
                  {
                    value: 'v2',
                    label: 'Version 2',
                  },
                  {
                    value: 'v1',
                    label: 'Version 1',
                  },
                ],
              },
              {
                value: 'PD',
                label: 'Public Domain',
                versions: [
                  {
                    value: '-',
                    label: '-',
                  },
                  {
                    value: 'CC0 1.0',
                    label: 'CC0 1.0 Universal',
                  },
                  {
                    value: 'CC PDM',
                    label: 'Public Domain Mark',
                  },
                ],
              },
              {
                value: 'C',
                label: 'Copyright',
              },
            ],
          },
          {
            name: 'version',
            type: 'select',
            label: 'License Version',
            options: [],
          },
        ],
      },
      metadataSemantics: [
        {
          name: 'title',
          type: 'text',
          label: 'Title',
          placeholder: 'La Gioconda',
        },
        {
          name: 'license',
          type: 'select',
          label: 'License',
          default: 'U',
          options: [
            {
              value: 'U',
              label: 'Undisclosed',
            },
            {
              type: 'optgroup',
              label: 'Creative Commons',
              options: [
                {
                  value: 'CC BY',
                  label: 'Attribution (CC BY)',
                  versions: [
                    {
                      value: '4.0',
                      label: '4.0 International',
                    },
                    {
                      value: '3.0',
                      label: '3.0 Unported',
                    },
                    {
                      value: '2.5',
                      label: '2.5 Generic',
                    },
                    {
                      value: '2.0',
                      label: '2.0 Generic',
                    },
                    {
                      value: '1.0',
                      label: '1.0 Generic',
                    },
                  ],
                },
                {
                  value: 'CC BY-SA',
                  label: 'Attribution-ShareAlike (CC BY-SA)',
                  versions: [
                    {
                      value: '4.0',
                      label: '4.0 International',
                    },
                    {
                      value: '3.0',
                      label: '3.0 Unported',
                    },
                    {
                      value: '2.5',
                      label: '2.5 Generic',
                    },
                    {
                      value: '2.0',
                      label: '2.0 Generic',
                    },
                    {
                      value: '1.0',
                      label: '1.0 Generic',
                    },
                  ],
                },
                {
                  value: 'CC BY-ND',
                  label: 'Attribution-NoDerivs (CC BY-ND)',
                  versions: [
                    {
                      value: '4.0',
                      label: '4.0 International',
                    },
                    {
                      value: '3.0',
                      label: '3.0 Unported',
                    },
                    {
                      value: '2.5',
                      label: '2.5 Generic',
                    },
                    {
                      value: '2.0',
                      label: '2.0 Generic',
                    },
                    {
                      value: '1.0',
                      label: '1.0 Generic',
                    },
                  ],
                },
                {
                  value: 'CC BY-NC',
                  label: 'Attribution-NonCommercial (CC BY-NC)',
                  versions: [
                    {
                      value: '4.0',
                      label: '4.0 International',
                    },
                    {
                      value: '3.0',
                      label: '3.0 Unported',
                    },
                    {
                      value: '2.5',
                      label: '2.5 Generic',
                    },
                    {
                      value: '2.0',
                      label: '2.0 Generic',
                    },
                    {
                      value: '1.0',
                      label: '1.0 Generic',
                    },
                  ],
                },
                {
                  value: 'CC BY-NC-SA',
                  label: 'Attribution-NonCommercial-ShareAlike (CC BY-NC-SA)',
                  versions: [
                    {
                      value: '4.0',
                      label: '4.0 International',
                    },
                    {
                      value: '3.0',
                      label: '3.0 Unported',
                    },
                    {
                      value: '2.5',
                      label: '2.5 Generic',
                    },
                    {
                      value: '2.0',
                      label: '2.0 Generic',
                    },
                    {
                      value: '1.0',
                      label: '1.0 Generic',
                    },
                  ],
                },
                {
                  value: 'CC BY-NC-ND',
                  label: 'Attribution-NonCommercial-NoDerivs (CC BY-NC-ND)',
                  versions: [
                    {
                      value: '4.0',
                      label: '4.0 International',
                    },
                    {
                      value: '3.0',
                      label: '3.0 Unported',
                    },
                    {
                      value: '2.5',
                      label: '2.5 Generic',
                    },
                    {
                      value: '2.0',
                      label: '2.0 Generic',
                    },
                    {
                      value: '1.0',
                      label: '1.0 Generic',
                    },
                  ],
                },
                {
                  value: 'CC0 1.0',
                  label: 'Public Domain Dedication (CC0)',
                },
                {
                  value: 'CC PDM',
                  label: 'Public Domain Mark (PDM)',
                },
              ],
            },
            {
              value: 'GNU GPL',
              label: 'General Public License v3',
            },
            {
              value: 'PD',
              label: 'Public Domain',
            },
            {
              value: 'ODC PDDL',
              label: 'Public Domain Dedication and Licence',
            },
            {
              value: 'C',
              label: 'Copyright',
            },
          ],
        },
        {
          name: 'licenseVersion',
          type: 'select',
          label: 'License Version',
          options: [
            {
              value: '4.0',
              label: '4.0 International',
            },
            {
              value: '3.0',
              label: '3.0 Unported',
            },
            {
              value: '2.5',
              label: '2.5 Generic',
            },
            {
              value: '2.0',
              label: '2.0 Generic',
            },
            {
              value: '1.0',
              label: '1.0 Generic',
            },
          ],
          optional: true,
        },
        {
          name: 'yearFrom',
          type: 'number',
          label: 'Years (from)',
          placeholder: '1991',
          min: '-9999',
          max: '9999',
          optional: true,
        },
        {
          name: 'yearTo',
          type: 'number',
          label: 'Years (to)',
          placeholder: '1992',
          min: '-9999',
          max: '9999',
          optional: true,
        },
        {
          name: 'source',
          type: 'text',
          label: 'Source',
          placeholder: 'https://',
          optional: true,
        },
        {
          name: 'authors',
          type: 'list',
          field: {
            name: 'author',
            type: 'group',
            fields: [
              {
                label: "Author's name",
                name: 'name',
                optional: true,
                type: 'text',
              },
              {
                name: 'role',
                type: 'select',
                label: "Author's role",
                default: 'Author',
                options: [
                  {
                    value: 'Author',
                    label: 'Author',
                  },
                  {
                    value: 'Editor',
                    label: 'Editor',
                  },
                  {
                    value: 'Licensee',
                    label: 'Licensee',
                  },
                  {
                    value: 'Originator',
                    label: 'Originator',
                  },
                ],
              },
            ],
          },
        },
        {
          name: 'licenseExtras',
          type: 'text',
          widget: 'textarea',
          label: 'License Extras',
          optional: true,
          description: 'Any additional information about the license',
        },
        {
          name: 'changes',
          type: 'list',
          field: {
            name: 'change',
            type: 'group',
            label: 'Changelog',
            fields: [
              {
                name: 'date',
                type: 'text',
                label: 'Date',
                optional: true,
              },
              {
                name: 'author',
                type: 'text',
                label: 'Changed by',
                optional: true,
              },
              {
                name: 'log',
                type: 'text',
                widget: 'textarea',
                label: 'Description of change',
                placeholder: 'Photo cropped, text changed, etc.',
                optional: true,
              },
            ],
          },
        },
        {
          name: 'authorComments',
          type: 'text',
          widget: 'textarea',
          label: 'Author comments',
          description:
            // eslint-disable-next-line max-len
            'Comments for the editor of the content (This text will not be published as a part of copyright info)',
          optional: true,
        },
        {
          name: 'contentType',
          type: 'text',
          widget: 'none',
        },
      ],
      assets: {
        css: [
          `${h5pBaseUrl}/core/styles/h5p.css`,
          `${h5pBaseUrl}/core/styles/h5p-confirmation-dialog.css`,
          `${h5pBaseUrl}/core/styles/h5p-custom-dialog.css`,
          `${h5pBaseUrl}/core/styles/h5p-core-button.css`,
          `${h5pBaseUrl}/editor/libs/darkroom.css`,
          `${h5pBaseUrl}/editor/styles/css/h5p-hub-client.css`,
          `${h5pBaseUrl}/editor/styles/css/fonts.css`,
          `${h5pBaseUrl}/editor/styles/css/application.css`,
          `${h5pBaseUrl}/editor/styles/css/libs/zebra_datepicker.min.css`,
        ],
        js: [
          `${h5pBaseUrl}/core/js/jquery.js`,
          `${h5pBaseUrl}/core/js/h5p.js`,
          `${h5pBaseUrl}/core/js/h5p-event-dispatcher.js`,
          `${h5pBaseUrl}/core/js/h5p-x-api-event.js`,
          `${h5pBaseUrl}/core/js/h5p-x-api.js`,
          `${h5pBaseUrl}/core/js/h5p-content-type.js`,
          `${h5pBaseUrl}/core/js/h5p-confirmation-dialog.js`,
          `${h5pBaseUrl}/core/js/h5p-custom-dialog.js`,
          `${h5pBaseUrl}/core/js/h5p-action-bar.js`,
          `${h5pBaseUrl}/core/js/request-queue.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-editor.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor.js`,
          `${h5pBaseUrl}/editor/language/${lang}.js`,
          `${h5pBaseUrl}/editor/scripts/h5p-hub-client.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-semantic-structure.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-library-selector.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-form.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-text.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-html.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-number.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-textarea.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-file-uploader.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-file.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-image.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-image-popup.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-av.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-group.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-boolean.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-label.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-list.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-list-editor.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-library.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-library-list-cache.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-select.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-selector-hub.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-selector-legacy.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-dimensions.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-coordinates.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-none.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-metadata.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-metadata-author-widget.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-metadata-changelog-widget.js`,
          `${h5pBaseUrl}/editor/scripts/h5peditor-pre-save.js`,
          `${h5pBaseUrl}/editor/ckeditor/ckeditor.js`,
        ],
      },
      deleteMessage: 'Are you sure you wish to delete this content?',
      apiVersion: {
        majorVersion: 1,
        minorVersion: 24,
      },
    },
    hubIsEnabled: true,
    l10n: {
      H5P: {
        fullscreen: 'Fullscreen',
        disableFullscreen: 'Disable fullscreen',
        download: 'Download',
        copyrights: 'Rights of use',
        embed: 'Embed',
        size: 'Size',
        showAdvanced: 'Show advanced',
        hideAdvanced: 'Hide advanced',
        advancedHelp:
          'Include this script on your website if you want dynamic sizing of the embedded content:',
        copyrightInformation: 'Rights of use',
        close: 'Close',
        title: 'Title',
        author: 'Author',
        year: 'Year',
        source: 'Source',
        license: 'License',
        thumbnail: 'Thumbnail',
        noCopyrights: 'No copyright information available for this content.',
        reuse: 'Reuse',
        reuseContent: 'Reuse Content',
        reuseDescription: 'Reuse this content.',
        downloadDescription: 'Download this content as a H5P file.',
        copyrightsDescription: 'View copyright information for this content.',
        embedDescription: 'View the embed code for this content.',
        h5pDescription: 'Visit H5P.org to check out more cool content.',
        contentChanged: 'This content has changed since you last used it.',
        startingOver: "You'll be starting over.",
        by: 'by',
        showMore: 'Show more',
        showLess: 'Show less',
        subLevel: 'Sublevel',
        confirmDialogHeader: 'Confirm action',
        confirmDialogBody:
          'Please confirm that you wish to proceed. This action is not reversible.',
        cancelLabel: 'Cancel',
        confirmLabel: 'Confirm',
        proceedLabel: 'Proceed',
        licenseU: 'Undisclosed',
        licenseCCBY: 'Attribution',
        licenseCCBYSA: 'Attribution-ShareAlike',
        licenseCCBYND: 'Attribution-NoDerivs',
        licenseCCBYNC: 'Attribution-NonCommercial',
        licenseCCBYNCSA: 'Attribution-NonCommercial-ShareAlike',
        licenseCCBYNCND: 'Attribution-NonCommercial-NoDerivs',
        licenseCC40: '4.0 International',
        licenseCC30: '3.0 Unported',
        licenseCC25: '2.5 Generic',
        licenseCC20: '2.0 Generic',
        licenseCC10: '1.0 Generic',
        licenseGPL: 'General Public License',
        licenseV3: 'Version 3',
        licenseV2: 'Version 2',
        licenseV1: 'Version 1',
        licensePD: 'Public Domain',
        licenseCC010: 'CC0 1.0 Universal (CC0 1.0) Public Domain Dedication',
        licensePDM: 'Public Domain Mark',
        licenseC: 'Copyright',
        contentType: 'Content Type',
        licenseExtras: 'License Extras',
        changes: 'Changelog',
        contentCopied: 'Content is copied to the clipboard',
        connectionLost:
          'Connection lost. Results will be stored and sent when you regain connection.',
        connectionReestablished: 'Connection reestablished.',
        resubmitScores: 'Attempting to submit stored results.',
        offlineDialogHeader: 'Your connection to the server was lost',
        offlineDialogBody:
          // eslint-disable-next-line max-len
          'We were unable to send information about your completion of this task. Please check your internet connection.',
        offlineDialogRetryMessage: 'Retrying in :num....',
        offlineDialogRetryButtonLabel: 'Retry now',
        offlineSuccessfulSubmit: 'Successfully submitted results.',
      },
    },
    postUserStatistics: true,
    saveFreq: h5pSaveFrequencyInMinutes,
    url: h5pBaseUrl.includes('http') ? h5pBaseUrl : '/',
    user: {
      mail: '',
      name: '',
    },
    contents: {},
    development: environment.localRunner,
  };
}
