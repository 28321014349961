<div class="dialog-content">
  <h2 mat-dialog-title>{{ 'courses.importACourse' | translate }}</h2>
  <div class="dialog-file">
    <ngx-dropzone
      class="ngx-dropzone"
      (change)="onZipFileChange($event)"
      [multiple]="false"
      [accept]="'application/zip,application/x-zip-compressed'"
      [disableClick]="!!courseZipFile"
    >
      <ngx-dropzone-label class="ngx-dropzone-label"  *ngIf="!courseZipFile" fxLayout="column" fxLayoutAlign="center center">
        <button
          mat-stroked-button
          color="darkblue"
          class="filled no-border"
        >
          <span>{{ 'courses.importCourseBtn' | translate }}</span>
        </button>

        <span class="mat-body">
          {{ 'reusable.dragFile' | translate }}
        </span>
      </ngx-dropzone-label>
      <div *ngIf="courseZipFile" class="ngx-dropzone-preview" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>cloud_upload</mat-icon>
        <span class="mat-body">
          {{ courseZipFile.name }} {{ formatFileSize(courseZipFile.size) }}
        </span>
      </div>
    </ngx-dropzone>
  </div>
  <div class="dialog-footer" fxLayout="column" fxLayoutAlign="space-between">
    <div *ngIf="courseZipFile" fxLayout="row" fxLayoutAlign="start">
      <span class="description"> {{ 'courses.importCourseDesc' | translate }} </span>
    </div>
    <div fxLayout="row" fxLayoutAlign="flex-end">
      <div class="dialog-footer-actions" mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="12px">
        <button mat-stroked-button color="darkblue" [mat-dialog-close]="false">{{ 'common.cancel' | translate }}</button>
        <button [disabled]="!courseZipFile" color="yellow" class="no-border filled" mat-stroked-button [mat-dialog-close]="true" (click)="importCourse()">
          {{ 'common.import' | translate }}
      </button>
    </div>
    </div>
  </div>
</div>