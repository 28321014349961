import { Component, Input, OnInit } from '@angular/core';
import { InfoBubbleType } from './info-bubble.model';

@Component({
  selector: 'app-info-bubble',
  templateUrl: 'info-bubble.component.html',
  styleUrls: ['info-bubble.component.scss'],
})
export class InfoBubbleComponent implements OnInit {
  @Input() type =
    InfoBubbleType.Success ||
    InfoBubbleType.Error ||
    InfoBubbleType.Info ||
    InfoBubbleType.Warning ||
    InfoBubbleType.Notification;
  @Input() title? = '';
  @Input() message? = '';

  public className = '';
  public iconName = '';

  constructor() {}

  ngOnInit(): void {
    this.setClassName(this.type);
  }

  setClassName(bubbleType: InfoBubbleType) {
    switch (bubbleType) {
      case InfoBubbleType.Success: {
        this.className = 'info-bubble-container info-bubble-success';
        this.iconName = 'check_circle';
        break;
      }
      case InfoBubbleType.Error: {
        this.className = 'info-bubble-container info-bubble-error';
        this.iconName = 'cancel';
        break;
      }
      case InfoBubbleType.Info: {
        this.className = 'info-bubble-container info-bubble-info';
        this.iconName = 'info';
        break;
      }
      case InfoBubbleType.Warning: {
        this.className = 'info-bubble-container info-bubble-warning';
        this.iconName = 'warning';
        break;
      }
      case InfoBubbleType.Warning: {
        this.className = 'info-bubble-container info-bubble-warning';
        this.iconName = 'warning';
        break;
      }
      case InfoBubbleType.Notification: {
        this.className = 'info-bubble-container info-bubble-notification';
        this.iconName = 'hourglass_top';
        break;
      }
    }
  }
}
