<section ngClass.gt-sm="large">
  <div class="dialog-title">
    <mat-icon>warning</mat-icon>
    <h2>{{data.title}}</h2>
  </div>
  <div class="mat-body dialog-message" mat-dialog-content>
    <span [innerHTML]="data.message"></span>

    <span class="warning" *ngIf="data.warningMessage">
      {{ ' ' + data.warningMessage }}
    </span>
  </div>
  <mat-toolbar
    fxLayout="row"
    fxLayoutAlign="flex-end flex-end"
    fxLayoutGap="20px"
    fxLayout.lt-sm="column"
    fxLayoutGap.lt-sm="12px"
    ngClass.lt-sm="mobile"
  >
    <button mat-stroked-button color="darkblue" [mat-dialog-close]="false">
      {{ data.cancel ? (data.cancel | translate) : ('common.cancel' | translate) }}
    </button>
    <button class="no-border" color="yellow" mat-stroked-button [mat-dialog-close]="true">
      {{ data.confirm ? (data.confirm | translate) : ('common.proceed' | translate) }}
    </button>
  </mat-toolbar>
</section>
