import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { retry, startWith, switchMap } from 'rxjs/operators';
import { environment } from '@environment';
import { Datastore } from './datastore';
import { MemberNotification } from '@models/member/member-notification.model';
import { JsonApiQueryData } from '@elearnio/angular2-jsonapi';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MemberNotificationPollingService {
  public notifications$ = new BehaviorSubject<MemberNotification[]>([]);
  private subscription!: Subscription;

  constructor(
    private datastore: Datastore,
    private authService: AuthService,
    private api: ApiService,
  ) {
    this.subscriptionTimer();
  }

  refresh(): void {
    this.subscription.unsubscribe();
    this.subscriptionTimer();
  }

  delete(element: MemberNotification): void {
    this.datastore.deleteRecord(MemberNotification, element.id).subscribe();
  }

  deleteMultiple(own: boolean): Observable<void> {
    return this.api.delete(
      environment.backendBaseUrl,
      `/api/v1/member_notifications/delete_all_completed?own=${own}`,
    );
  }

  private subscriptionTimer(): void {
    this.subscription = interval(environment.pollingIntervalInSeconds * 1000)
      .pipe(
        startWith(0),
        retry(10),
        switchMap(() =>
          this.datastore.findAll(MemberNotification, {
            filter: {},
            include: 'member',
            sort: '-created_at',
          }),
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe((notifications: JsonApiQueryData<MemberNotification>) => {
        this.notifications$.next(notifications.getModels());
      });

    this.authService.registerLogoutCallback(() => {
      this.subscription.unsubscribe();
    });
  }
}
