/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { ILTCourseEvent } from './ilt-course-event.model';
import { ILTCourseAttendance } from './ilt-course-attendance.model';
import { PhysicalLocation } from '../physical-location.model';
import { VirtualLocation } from '../virtual-location.model';
import { Member } from '../member.model';
import { ILTCourse } from '../ilt-course.model';

@JsonApiModelConfig({
  type: 'event_days',
})
export class ILTCourseEventDay extends JsonApiModel {
  @Attribute({ serializedName: 'event_id' })
  iltCourseEventId!: string;

  @Attribute({ serializedName: 'anyone_attended', readonly: true })
  anyoneAttended!: boolean;

  @Attribute({ serializedName: 'physical_location_id' })
  physicalLocationId!: string;

  @Attribute({ serializedName: 'virtual_location_id' })
  virtualLocationId!: string;

  @Attribute()
  capacity!: number;

  @Attribute()
  order!: number;

  @Attribute({ serializedName: 'event_date' })
  eventDate!: string;

  @Attribute({ serializedName: 'start_time' })
  startTime!: string;

  @Attribute({ serializedName: 'end_time' })
  endTime!: string;

  @BelongsTo({ key: 'physical_location' })
  physicalLocation!: PhysicalLocation | null | undefined;

  @BelongsTo({ key: 'virtual_location' })
  virtualLocation!: VirtualLocation | null | undefined;

  @BelongsTo({ key: 'ilt_course' })
  iltCourse!: ILTCourse | null;

  @BelongsTo({ key: 'event' })
  iltCourseEvent!: ILTCourseEvent | null;

  @HasMany({ key: 'attendances' })
  iltCourseAttendences!: ILTCourseAttendance[];

  @HasMany({ key: 'instructors' })
  instructors!: Member[];

  get organizer(): string {
    return this.iltCourseEvent?.eventOrganizer
      ? this.iltCourseEvent.eventOrganizer
      : '';
  }
}
