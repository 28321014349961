export const CsvFileHeaderColumnNames = [
  'row_id',
  'email',
  'first_name',
  'last_name',
  'role',
  'groups',
  'employee_id',
  'position',
  'team',
  'department',
  'office_location',
  'status',
  'gender',
  'birth_date',
];
