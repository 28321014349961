import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
  HasMany,
} from '@elearnio/angular2-jsonapi';

import { TrainingSchedule } from './training-schedule.model';
import { CohortAssignment } from './training-schedule-cohort-assignment.model';
import { TrainingScheduleCohortItem } from './training-schedule-cohort-item.model';
import { Member } from '../member.model';

@JsonApiModelConfig({
  type: 'cohorts',
})
export class TrainingScheduleCohort extends JsonApiModel {
  @Attribute({ serializedName: 'created_at' })
  createdAt: Date | undefined;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'number_of_days' })
  numberOfDays!: number;

  @Attribute({ serializedName: 'number_of_members' })
  numberOfMembers!: number;

  @Attribute({ serializedName: 'start_date' })
  startDate!: Date;

  @Attribute({ serializedName: 'available_days' })
  availableDays!: string[];

  @Attribute({ serializedName: 'schedule_id' })
  scheduleId!: string;

  @Attribute({ serializedName: 'schedule_owner_id' })
  scheduleOwnerId!: string;

  @BelongsTo()
  host!: Member;

  @BelongsTo()
  schedule!: TrainingSchedule;

  @HasMany({ key: 'cohort_items' })
  items!: TrainingScheduleCohortItem[];

  @HasMany({ key: 'cohort_assignments' })
  cohortAssignments!: CohortAssignment[];

  @HasMany({ key: 'editors' })
  editors!: Member[];
}
