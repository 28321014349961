import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { PermissionService } from '@services/permission.service';
import { IPermission } from '@core/interfaces/permission.interface';

@Injectable({
  providedIn: 'root',
})
export class CourseRoleGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private router: Router,
  ) {}

  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const navigateToCoursesList = () =>
      this.router.navigateByUrl('/courses/my');
    const roles = next.data.roles;

    if (!roles) {
      return true;
    }

    return new Promise(resolve => {
      this.permissionService
        .getPermission(next.data.roles.resource)
        .pipe(take(1))
        .subscribe(permission => {
          const key = next.data.roles.key as keyof IPermission;
          if (permission && !!permission[key]) {
            resolve(true);
          } else {
            navigateToCoursesList();
            resolve(false);
          }
        });
    });
  }
}
