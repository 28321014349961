import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NO_REPORTING_CUSTOMERS } from '@core/constants/no-reporting-customers.constants';

@Injectable()
export class ReportingGuard implements CanActivate {
  private noReportingCustomers = NO_REPORTING_CUSTOMERS;

  constructor(private router: Router) {}

  canActivate(): boolean {
    const allowReporting =
      this.noReportingCustomers.indexOf(window.location.hostname) === -1;
    if (allowReporting) {
      return true;
    }

    // navigate to dashboard page
    this.router.navigateByUrl('');

    return false;
  }
}
