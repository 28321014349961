import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '@models/course.model';
import { ILTCourse } from '@models/ilt-course.model';
import { LearningPath } from '@models/learning-path.model';
import { OrganizationLevel } from '@models/organization/organization-level.model';

@JsonApiModelConfig({
  type: 'visibility_permissions',
})
export class VisibilityPermission extends JsonApiModel {
  @BelongsTo({ key: 'organisation_level' })
  organizationLevel!: OrganizationLevel;

  @BelongsTo()
  target!: Course | ILTCourse | LearningPath;

  @Attribute({ serializedName: 'organisation_level_id' })
  organizationLlevelId!: string;

  @Attribute({ serializedName: 'target_id' })
  targetId!: string;

  @BelongsTo({ key: 'inherited_from' })
  inheritedFrom!: OrganizationLevel | undefined;
}
