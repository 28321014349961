import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { AppToolbarComponent } from './app-toolbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { ImportCourseDialogModule } from '@shared/import-course-dialog/import-course-dialog.module';
import { AppToolbarFiltersModule } from '@shared/app-toolbar-filters/app-toolbar-filters.module';
import { MemberInviteEmailDialogModule } from '@shared/member-invite-email-dialog/member-invite-email-dialog.module';
import { MemberInviteCsvDialogModule } from '@shared/member-invite-csv-dialog/member-invite-csv-dialog.module';

@NgModule({
  declarations: [AppToolbarComponent],
  exports: [AppToolbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    ImportCourseDialogModule,
    MemberInviteEmailDialogModule,
    MemberInviteCsvDialogModule,
    AppToolbarFiltersModule,
  ],
})
export class AppToolbarModule {}
