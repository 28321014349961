import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SideAndTopBarService } from '@services/side-and-top-bar.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarGuard implements CanActivate {
  constructor(private sidebarService: SideAndTopBarService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(resolve => {
      if (route.data.sidebar) {
        this.sidebarService.setValues(route.data.sidebar);
      } else {
        this.sidebarService.setValues({});
      }
      resolve(true);
    });
  }
}
