import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Member } from './member.model';

@JsonApiModelConfig({
  type: 'meetings',
})
export class Meeting extends JsonApiModel {
  @Attribute()
  headline!: string;

  @Attribute()
  description!: string;

  @Attribute()
  location!: string;

  @Attribute({ serializedName: 'organizer_name' })
  organizerName!: string;

  @Attribute({ serializedName: 'duration_in_minutes' })
  durationInMinutes!: string;

  @Attribute({ serializedName: 'organizer_id' })
  organizerId!: number;

  @Attribute({ serializedName: 'virtual_location_type' })
  type!: string;

  @BelongsTo({ key: 'organizer' })
  meetingOrganizer!: Member;
}
