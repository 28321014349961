import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'languageFlagIcon',
})
export class LanguageFlagIconPipe implements PipeTransform {
  constructor() {}

  transform(langCode?: string): string {
    if (!langCode) {
      return 'flag-icon-de';
    }

    if (langCode === 'en') {
      return 'flag-icon-gb';
    }

    return `flag-icon-${langCode}`;
  }
}
