/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '@models/course.model';
import { ILTCourse } from '@models/ilt-course.model';
import { LearningPath } from '@models/learning-path.model';
import { LearningPathSectionCourseOrder } from './learning-path-section-course-order.model';
import { LearningPathSectionLabel } from './learning-path-section-label.model';

@JsonApiModelConfig({
  type: 'learning_path_courses_sections',
})
export class LearningPathCourseSection extends JsonApiModel {
  @Attribute({ serializedName: 'learning_path_id' })
  learningPathId!: string;

  @Attribute()
  title!: string;

  @Attribute()
  order!: number;

  @BelongsTo()
  learningPath!: LearningPath;

  @HasMany({ key: 'learning_path_section_courses_orders' })
  learningPathSectionCoursesOrders: LearningPathSectionCourseOrder[] = [];

  @HasMany()
  courses: (ILTCourse | Course | LearningPathSectionLabel)[] = [];
}
