import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Datastore } from '@app/core/services/datastore';
import { NotificationService } from '@app/core/services/notification.service';
import { Member } from '@app/models/member.model';
import { PasswordReset } from '@models/auth/password-reset.model';
import { take } from 'rxjs';

@Component({
  selector: 'app-edit-current-password-dialog',
  templateUrl: './edit-current-password-dialog.component.html',
  styleUrls: ['./edit-current-password-dialog.component.scss'],
})
export class EditCurrentPasswordDialogComponent implements OnInit {
  title: string | undefined;
  cancel: string | undefined;
  confirm: string | undefined;
  newPassword: string | undefined;
  confirmPassword: string | undefined;
  passwordResetForm: UntypedFormGroup;
  isShowPassword = false;
  passwords = {
    password: '',
    passwordConfirmation: '',
    forceUserPasswordChange: false,
  };
  parsedData = {
    email: '',
    token: '',
    expiresAt: '',
    companyId: '',
    memberId: '',
    username: '',
  };

  private member: Member;

  constructor(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private datastore: Datastore,
    private authService: AuthService,
    private router: Router,
    private notify: NotificationService,
  ) {
    this.passwordResetForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      { validators: this.checkPasswords },
    );
    this.member = data.member;
  }

  public ngOnInit(): void {
    this.updateParsedData();
  }

  public isSaveDisabled(): boolean {
    return this.passwordResetForm.status === 'INVALID';
  }

  public savePassword(): void {
    this.sendResetPassword();
  }

  updateParsedData(): void {
    const cookieInfo: any = this.authService.getAuthCookieInfo();
    this.parsedData = {
      email: this.member.email,
      token: this.authService.getAuthCookie(),
      // eslint-disable-next-line camelcase
      expiresAt: JSON.parse(cookieInfo)?.expires,
      // eslint-disable-next-line camelcase
      companyId: this.member?.companySettingId,
      // eslint-disable-next-line camelcase
      memberId: this.member.id,
      // eslint-disable-next-line camelcase
      username: this.member?.userName,
    };
  }

  checkPasswords(group: UntypedFormGroup): ValidationErrors | null {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    if (pass === confirmPass) {
      return null;
    }
    const error: ValidationErrors = { passwordMismatch: true };
    group.controls.confirmPassword.setErrors(error);
    return error;
  }

  private sendResetPassword(): void {
    const resetPass = this.datastore.createRecord(
      PasswordReset,
      Object.assign({}, this.passwords, this.parsedData),
    );
    resetPass
      .save()
      .pipe(take(1))
      .subscribe(
        () => {
          this.notify.showSuccess('notification.password.changed.success');
          this.authService.registerLoginCallback(() => {
            this.router.navigateByUrl('/my');
          });

          this.authService.login(
            this.member.userName,
            this.passwords.password,
            true,
          );
        },
        (err: HttpErrorResponse) => {
          this.notify.handleError(err);
        },
      );
  }
}
