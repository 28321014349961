<div *ngFor="let alert of alerts" class="{{cssClass(alert)}}" fxLayout="row" fxLayoutAlign="space-between center">
  <span [innerHTML]="alert.message"></span>
  <div>
    <button mat-button (click)="removeAlert(alert)">
      {{ 'notification.mark-as-read' | translate }}
    </button>
    <button mat-button (click)="removeAllAlerts()">
      {{ 'notification.mark-all-as-read' | translate }}
    </button>
  </div>
</div>
