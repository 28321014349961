import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environment';

const initUsetiful = (): void => {
  const a = document.getElementsByTagName('head')[0];
  const r = document.createElement('script');
  r.async = true;
  r.src = 'https://www.usetiful.com/dist/usetiful.js';
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = 'e0b72ec859bee9a9dce8957265df4df1';
  a.appendChild(r);
};

if (environment.production) {
  enableProdMode();

  if (environment.development) {
    initUsetiful();
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
