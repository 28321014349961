import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '@elearnio/angular2-jsonapi';

export interface ParsedError {
  id: number;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorParser {
  constructor(public translateService: TranslateService) {}

  public parseSimpleError(error: HttpErrorResponse | ErrorResponse): string {
    let code = 'notification.error.generic.message';
    if (error instanceof HttpErrorResponse) {
      if (error.error && error.error.errors) {
        code = error.error.errors[0].detail || code;
      }
    } else if (error.errors) {
      code = error.errors[0].detail || code;
    }
    return this.translateJsonapiErrorDetail(code);
  }

  public flattenJsonapiErrors(error: ErrorResponse): string {
    if (!error.errors) {
      return this.translate('notification.error.generic.message');
    }

    if (error.errors.length === 1) {
      return this.translateJsonapiErrorDetail(
        error.errors[0].detail || 'notification.error.generic.message',
      );
    }

    const result: string[] = [];
    // tslint:disable-next-line:no-any
    error.errors.map((err: any) => {
      result.push(
        this.translateJsonapiErrorDetail(
          err.detail || 'notification.error.generic.message',
        ),
      );
      // do we have arrays in detail after removing stepped_operation?
      // tslint:disable-next-line:no-any
      // err.detail.map((detail: any) => {
      //   result = result + '<li>' + this.translate(detail.message) + '</li>';
      // });
    });

    // TODO: html get converted to special chars, we need something like htmlSafe
    return '<ul><li>' + result.join('</li><li>') + '</li></ul>';
  }

  // Shortcut for translate function
  public translate(text: string): string {
    if (!text) {
      text = 'notification.error.generic.message';
    }

    // TODO: fix, that happens for example when clicking confirmation link in
    // email with expired token - possibly a binding issue in notification service
    if (!this) {
      return text;
    }

    return this.translateService.instant(text);
  }

  public flattenErrors(
    error: HttpErrorResponse | ErrorResponse | string | null,
  ): string {
    if (!error) {
      return this.translate('notification.error.generic.message');
    }

    if (typeof error === 'string') {
      return this.translate('' + error);
    }

    if (error instanceof HttpErrorResponse) {
      return this.parseSimpleError(error);
    }

    return this.flattenJsonapiErrors(error);
  }

  private translateJsonapiErrorDetail(detail: string): string {
    return detail
      .replace('- |', '|') // BE have no control ^^
      .split('|') // split
      .map(this.translate.bind(this)) // translate parts
      .join(' '); // join
  }
}
