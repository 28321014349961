import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { CourseCategory } from '@models/course-category.model';
import { Member } from '@models/member.model';
import { LearningPathAssignment } from '@models/learning-path-assignment.model';
// tslint:disable-next-line:max-line-length
import { LearningPathCourseSection } from '@models/learning-path/learning-path-course-section.model';
import { Course } from './course.model';
import { ILTCourse } from './ilt-course.model';
import { LearningPathMemberResult } from './learning-path/member/learning-path-member-result.model';
import { LearningPathReminder } from '@models/learning-path/learning-path-reminder.model';
import { OrganizationLevelLearningPath } from './organization/organization-level-learning-path.model';
import { VisibilityPermission } from './organization/visibility-permission.model';
import { LearningPathSectionLabel } from './learning-path/learning-path-section-label.model';

@JsonApiModelConfig({
  type: 'learning_paths',
})
export class LearningPath extends JsonApiModel {
  @Attribute()
  description!: string;

  @Attribute({ serializedName: 'estimated_time_in_minutes' })
  estimatedTimeInMinutes!: number;

  @Attribute({ serializedName: 'net_time_in_minutes' })
  netTimeInMinutes!: number;

  @Attribute()
  language!: string;

  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'certificate_template_id' })
  certificateId!: number | null;

  @Attribute()
  deadline!: Date;

  @Attribute({ serializedName: 'time_limit' })
  timeLimit!: number;

  @Attribute({ serializedName: 'time_limit_unit' })
  timeLimitUnit!: string;

  @Attribute({ serializedName: 'course_count' })
  courseCount!: number;

  @Attribute({ serializedName: 'gamification_points' })
  gamificationPoints!: number;

  @Attribute({ serializedName: 'total_gamification_points' })
  totalGamificationPoints!: number;

  @Attribute({ serializedName: 'free_courses_order' })
  freeCoursesOrder!: boolean;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute({ serializedName: 'cover_image' })
  coverImage!: string | null;

  @Attribute({ serializedName: 'self_assignment' })
  selfAssignment!: boolean;

  @Attribute({ serializedName: 'training_editors_count' })
  trainingEditorCount!: number;

  @Attribute({ serializedName: 'author_id' })
  authorId!: string;

  @Attribute({ serializedName: 'self_assignment_approval' })
  selfAssignmentApproval!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_group_leader' })
  selfAssignmentApprovalGroupLeader!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_manager' })
  selfAssignmentApprovalManager!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_author' })
  selfAssignmentApprovalAuthor!: boolean;

  @BelongsTo()
  author!: Member;

  @HasMany({ key: 'course_categories' })
  courseCategories!: CourseCategory[];

  @HasMany({ key: 'ilt_courses' })
  iltCourses!: ILTCourse[];

  @HasMany({ key: 'courses' })
  courses!: Course[];

  @HasMany({ key: 'learning_path_assignments' })
  learningPathAssignments!: LearningPathAssignment[];

  @HasMany({ key: 'learning_path_courses_sections' })
  learningPathCourseSections!: LearningPathCourseSection[];

  @HasMany({ key: 'learning_path_reminders' })
  reminders!: LearningPathReminder[];

  @HasMany({ key: 'organisation_levels_learning_paths' })
  organizationLevelLP!: OrganizationLevelLearningPath[];

  @HasMany({ key: 'visibility_permissions' })
  visibilityPermissions!: VisibilityPermission[];

  @HasMany({ key: 'editors' })
  editors!: Member[];

  allCourses!: (Course | ILTCourse | LearningPathSectionLabel)[];

  learningPathResult: LearningPathMemberResult | undefined;
}
