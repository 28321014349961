import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationService } from '@services/notification.service';
import { NotificationComponent } from './components/notification.component';

export const declarations = [NotificationComponent];

export const imports = [];

export const providers = [NotificationService];

@NgModule({
  declarations,
  imports: [CommonModule, ...imports],
  providers,
  exports: declarations,
})
export class NotificationModule {}
