<div class="settings-container" [class.expanded]="isSidebarExpanded" fxLayout="column" fxLayoutGap="50px">
  <div class="links-area">
    <a class="sidebar-item" routerLinkActive="active"
      routerLink="/settings/company" fxLayoutGap="8px">
      <mat-icon>location_city</mat-icon>
      <span class="sidebar-item-span">{{ 'settings.company.title' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
      routerLink="/settings/integrations" fxLayoutGap="8px">
      <mat-icon>extension</mat-icon>
      <span class="sidebar-item-span">{{ 'settings.integrations.title' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
      routerLink="/settings/authentication" fxLayoutGap="8px">
      <mat-icon>lock</mat-icon>
      <span class="sidebar-item-span">{{ 'settings.authentication.title' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
      routerLink="/settings/organization" fxLayoutGap="8px">
      <mat-icon>bubble_chart</mat-icon>
      <span class="sidebar-item-span">{{ 'settings.organization.title' | translate }}</span>
    </a>
  </div>

  <div class="links-area">
    <h3>{{ isSidebarExpanded ? ('settings.sections.customization' | translate | uppercase) : '' }}</h3>
    <a class="sidebar-item" routerLinkActive="active"
       routerLink="/settings/branding/list" fxLayoutGap="8px">
       <mat-icon>palette</mat-icon>
       <span class="sidebar-item-span">{{ 'settings.company.branding' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
       routerLink="/settings/custom-email/list" fxLayoutGap="8px">
       <mat-icon>drafts</mat-icon>
       <span class="sidebar-item-span">{{ 'settings.customEmailList.emails' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
       routerLink="/settings/news/list" fxLayoutGap="8px">
       <mat-icon>newspaper</mat-icon>
       <span class="sidebar-item-span">{{ 'settings.news.title' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
       routerLink="/settings/gamification" fxLayoutGap="8px">
       <mat-icon>emoji_events</mat-icon>
       <span class="sidebar-item-span">{{ 'settings.gamification.title' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
       routerLink="/settings/certificates" fxLayoutGap="8px">
       <mat-icon svgIcon="certificate"></mat-icon>
       <span class="sidebar-item-span">{{ 'settings.certificates.title' | translate }}</span>
    </a>
    <a class="sidebar-item" routerLinkActive="active"
       routerLink="/settings/learning-history" fxLayoutGap="8px">
       <mat-icon>history_edu</mat-icon>
       <span class="sidebar-item-span">{{ 'settings.learningHistory.title' | translate }}</span>
    </a>
  </div>
</div>
