/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '@app/models/course.model';
import { OrganizationLevel } from '@models/organization/organization-level.model';

@JsonApiModelConfig({
  type: 'organisation_levels_courses',
})
export class OrganizationLevelCourse extends JsonApiModel {
  @Attribute({ serializedName: 'course_id' })
  courseId!: string;

  @Attribute({ serializedName: 'organisation_level_id' })
  organizationLevelId!: string;

  @Attribute({ serializedName: 'show_in_catalog' })
  showInCatalogue!: boolean;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: boolean;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: boolean;

  @BelongsTo()
  iltCourse: Course | undefined;

  @BelongsTo({ key: 'organisation_level' })
  organizationLevel!: OrganizationLevel;

  @BelongsTo({ key: 'inherited_from' })
  inheritedFrom!: OrganizationLevel | undefined;
}
