<header class="app-header" [class.mobile]="mobileQuery.matches">
  <mat-toolbar class="app-header-toolbar" [class.mobile]="mobileQuery.matches" ngClass.lt-lg="tablet">
    <button *ngIf="mobileQuery.matches" mat-icon-button (click)="toggleDrawer()">
      <mat-icon>{{isOpened ? 'menu_open' : 'menu'}}</mat-icon>
    </button>
    <div class="fx-flex" [fxHide]="!mobileQuery.matches"></div>
    <div class="elearnio-logo-container">
      <img
        [ngClass]="{'hide': !logo}"
        alt="elearnio company logo"
        class="elearnio-logo"
        routerLink="/my/dashboard"
        [src]="logo"
      />
    </div>

    <ng-container *ngIf="!mobileQuery.matches">
      <a
        href=""
        routerLink="/my/dashboard"
        routerLinkActive="router-link-activated"
      >{{ 'sections.dashboard' | translate }}
      </a>

      <a
        href=""
        [routerLink]="getPermissionsService().getCoursePermissions().create ? '/courses/list': '/courses/my'"
        [class.router-link-activated]="
          router.isActive('courses', false) ||
          router.isActive('categories', false) ||
          router.isActive('ilt-courses', false) ||
          router.isActive('learning-paths', false) ||
          router.isActive('assignment-approvals', false) ||
          router.isActive('training-schedules', false)"
      >{{ 'sections.trainings' | translate }}
      </a>

      <a *ngIf="getPermissionsService().getMemberPermissions().update || getAssignPermission() || getAdminPermission() || getPeopleManagementPermission()"
         href=""
         [routerLink]="getAssignPermission() && !getAdminPermission() ? '/organizations/list' : '/talents/list'"
         [class.router-link-activated]="router.isActive('organizations', false) || router.isActive('talents', false) || router.isActive('groups', false)"
      >{{ 'sections.talents' | translate }}
      </a>

      <a *ngIf="showReporting && (getPermissionsService().getReportingPermissions().index || getReportPermission())"
          href=""
          routerLink="/reporting/talents"
          [class.router-link-activated]="router.isActive('reporting', false)"
      >{{ 'sections.reporting' | translate }}
      </a>
    </ng-container>

    <div class="fx-flex"></div>


    <ng-container *ngIf="!mobileQuery.matches">
      <button *ngIf="isAdminOrCoach"
              [matMenuTriggerFor]="notificationMenu"
              aria-label="Toggle menu"
              mat-icon-button
              class="button-header-text-align"
              [class.hidden]="!isAdminOrCoach"
              [class.router-link-activated]="isNotificationOpen"
              [disableRipple]="true"
              (menuOpened)="menuOpened()"
              (menuClosed)="menuClosed()"
      >
        <mat-icon class="notification-icon">
          <span class="unread-notifications" [class.hidden]="!notificationAlert"></span>
          access_time
        </mat-icon>
        <span fxShow fxHide.lt-lg>{{ 'notifications.notification' | translate }}</span>
      </button>

      <mat-menu #notificationMenu="matMenu" xPosition="before" class="notifications-menu" [backdropClass]="'backdrop-notification-center'">
        <app-notification-center
          #notificationCenter
          [currentMember]="currentMember"
        >
        </app-notification-center>
      </mat-menu>

      <button [matMenuTriggerFor]="helpMenu"
              #menuOption="matMenuTrigger"
              aria-label="Toggle menu"
              mat-icon-button
              class="button-header-text-align"
              (menuOpened)="menuOption.menuOpen"
              [class.router-link-activated]="menuOption.menuOpen"
              [disableRipple]="true"
      >
        <mat-icon class="info-icon">help_outline</mat-icon>
        <span fxShow fxHide.lt-lg>{{ 'sections.help' | translate }}</span>
      </button>

      <mat-menu #helpMenu="matMenu" xPosition="before">
        <button class="help-button" [class.hidden]="!isAdminOrCoach" mat-menu-item (click)="openHelpCenter()">
          <mat-icon>menu_book</mat-icon>

          <span>{{ 'main.header.helpCenter' | translate }}</span>
        </button>
        <button class="help-button" [class.hidden]="!isAdminOrCoach" mat-menu-item (click)="openRoadmap()">
          <mat-icon>map</mat-icon>

          <span>{{ 'main.header.roadmap' | translate }}</span>
        </button>
        <button class="help-button" mat-menu-item (click)="openUserback()">
          <mat-icon>bug_report</mat-icon>

          <span>{{ 'main.header.feedback' | translate }}</span>
        </button>
      </mat-menu>

      <button *ngIf="getPermissionsService().getCompanySettingPermissions().update"
              aria-label="settings"
              mat-icon-button
              routerLink="/settings/company"
              routerLinkActive="router-link-activated"
              class="button-header-text-align"
              [disableRipple]="true"
      >
        <mat-icon class="info-icon">settings</mat-icon>
        <span fxShow fxHide.lt-lg>{{ 'sections.settings' | translate }}</span>
      </button>

      <button id="user-profile-btn"
              [ngClass]="{'default-color': isDefaultBrandColor}"
              mat-flat-button
              [matMenuTriggerFor]="menu"
              aria-label="Toggle menu"
              mat-icon-button
              class="button-header-profile-align">
        <img
          *ngIf="currentMember?.picture"
          class="avatar"
          [src]="currentMember?.picture"
        />
        <span *ngIf="!currentMember?.picture" class="avatar-no-picture">
          <i class="fas fa-user-circle"></i>
        </span>
        <div fxLayout="column" class="dropdown-icon-grow">
          <span fxShow fxHide.lt-lg class="mat-body profile-first-name">{{ 'common.informalGreeting' | translate:{name: (currentMember?.firstName || '')} }}</span>
          <div *ngIf="showGamificationBadge" fxLayout="row">
            <mat-icon aria-hidden="false" class="color-icon">emoji_events</mat-icon>
            <ng-animated-counter *ngIf="params" [params]="params"></ng-animated-counter>
          </div>
        </div>
        <mat-icon class="dropdown-icon">expand_more</mat-icon>
      </button>

      <mat-menu #menu="matMenu" xPosition="before">
        <button
          mat-menu-item
          routerLink="/my/profile"
          routerLinkActive="router-link-activated"
        >
          <mat-icon>account_circle</mat-icon>
          <span>{{ 'common.profile' | translate }}</span>
        </button>

        <button
          id="log-out-btn"
          (click)="signOut($event)"
          mat-menu-item
          routerLink="/login"
          routerLinkActive="router-link-activated"
        >
          <mat-icon>logout</mat-icon>
          <span>{{ 'common.logout' | translate }}</span>
        </button>
      </mat-menu>
    </ng-container>
  </mat-toolbar>
</header>
