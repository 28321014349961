import {
  Attribute,
  JsonApiModelConfig,
  JsonApiModel,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'gamifications',
})
export class Gamification extends JsonApiModel {
  @Attribute({ serializedName: 'member_id' })
  memberId!: string;

  @Attribute({ serializedName: 'member_name' })
  memberName!: string;

  @Attribute()
  avatar!: string;

  @Attribute()
  score!: number;

  @Attribute()
  rank!: number;

  @Attribute()
  level!: string;

  @Attribute()
  image!: string;

  @Attribute({ serializedName: 'current_year_completed_courses' })
  currentYearCompleteCourses!: number;

  @Attribute({ serializedName: 'completed_courses' })
  completedCourses!: number;

  @Attribute({ serializedName: 'completion_rate' })
  completionRate!: number;

  @Attribute({ serializedName: 'current_year_score' })
  currentYearScore!: number;

  @Attribute({ serializedName: 'current_level_score' })
  currentLevelScore!: number;

  @Attribute({ serializedName: 'next_level_name' })
  nextLevelName!: string;

  @Attribute({ serializedName: 'next_level_image' })
  nextLevelImage!: string;

  @Attribute({ serializedName: 'next_level_score' })
  nextLevelScore!: number;

  @Attribute({ serializedName: 'current_year_level' })
  currentYearLevel!: string;

  @Attribute({ serializedName: 'current_year_image' })
  currentYearImage!: string;
}
