<div [ngStyle]="calcSize()" class="progress-wrapper">
  <progress
    [id]="id"
    [ngClass]="[allGreen ? 'is-success' : (allRed ? 'is-danger' : 'is-default')]"
    [ngStyle]="calcSize()"
    [value]="value"
    class="progress progress-bar"
    max="100"
  >
    {{ value }}
  </progress>

  <label *ngIf="showValue"
         [for]="id"
         class="progress-value"
  >{{ value || '-' }} %</label
  >
</div>
