import {
  Attribute,
  JsonApiModel,
  HasMany,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Member } from '@models/member.model';

@JsonApiModelConfig({
  type: 'company_settings',
})
export class CompanySetting extends JsonApiModel {
  @Attribute()
  email: string | undefined;

  @Attribute()
  slug: string | undefined;

  @Attribute({ serializedName: 'address_city' })
  addressCity!: string;

  @Attribute({ serializedName: 'address_street' })
  addressStreet!: string;

  @Attribute({ serializedName: 'address_zipcode' })
  addressZipcode!: string;

  @Attribute({ serializedName: 'brand_color' })
  brandColor!: string;

  @Attribute({ serializedName: 'interactive_elements_color' })
  interactiveElementsColor!: string;

  @Attribute({ serializedName: 'main_button_color' })
  mainButtonColor!: string;

  @Attribute({ serializedName: 'button_text_color' })
  buttonTextColor!: string;

  @Attribute({ serializedName: 'linked_in' })
  linkedIn!: string;

  @Attribute()
  logo!: string;

  @Attribute()
  favicon!: string;

  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'net_time_enabled' })
  enableIDDTime!: boolean;

  @Attribute({ serializedName: 'email_enabled' })
  emailEnabled!: boolean;

  @Attribute()
  consent!: boolean;

  @Attribute({ serializedName: 'time_zone' })
  timeZone!: string;

  @Attribute({ serializedName: 'default_language' })
  defaultLanguage!: string;

  @Attribute()
  website!: string;

  @Attribute({ serializedName: 'show_gamification_ranking' })
  showGamificationRanking!: boolean;

  @Attribute({ serializedName: 'show_gamification_badge' })
  showGamificationBadge!: boolean;

  @Attribute({ serializedName: 'enable_oauth' })
  enableOauth!: boolean;

  @Attribute({ serializedName: 'orga_enabled' })
  enableOrga!: boolean;

  @Attribute({ serializedName: 'orga_tree' })
  orgaTree!: any;

  @Attribute({ serializedName: 'ai_enabled' })
  enableAI!: boolean;

  @Attribute({ serializedName: 'username_login' })
  enableLoginUsername!: boolean;

  @Attribute({ serializedName: 'default_password' })
  defaultPassword!: string;

  @Attribute({ serializedName: 'channel_enabled' })
  channelEnabled!: boolean;

  @Attribute({ serializedName: 'google_enabled' })
  googleEnabled!: boolean;

  @Attribute({ serializedName: 'ms_teams_integration' })
  msTeamsIntegration!: any;

  @Attribute({ serializedName: 'google_integration' })
  googleIntegration!: any;

  @HasMany()
  members!: Member[];
}
