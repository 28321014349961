import { JsonApiModelConfig } from '@elearnio/angular2-jsonapi';

import { EmailRegistration } from '@models/auth/email-registration.model';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.identityManagerBaseUrl}/api/v1`,
  type: 'password_recoveries',
})
export class PasswordRecovery extends EmailRegistration {}
