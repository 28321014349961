import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import { Member } from './member.model';
import { ILTCourse } from './ilt-course.model';

@JsonApiModelConfig({
  type: 'payments',
})
export class Payments extends JsonApiModel {
  @BelongsTo()
  member!: Member;

  @BelongsTo({ key: 'ilt_course' })
  iltCourse!: ILTCourse;

  @Attribute({ serializedName: 'amount' })
  amount!: number;

  @Attribute({ serializedName: 'can_be_refunded' })
  canBeRefunded!: boolean;
}
