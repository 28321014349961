import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-h5p-player-header',
  templateUrl: './h5p-player-header.component.html',
  styleUrls: ['./h5p-player-header.component.scss'],
})
export class H5PPlayerHeaderComponent implements OnInit {
  @Input() hasNext = false;
  @Input() hasPrevious = false;
  @Input() isLastActivity = false;
  @Input() adminPreview = false;
  @Input() isPreview = false;
  @Input() showCourseNavigation = false;
  @Input() courseTitle = '';
  @Input() learningPathId = '';
  @Input() courseId = '';
  @Input() progress = 0;
  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onNext(): void {
    this.next.emit();
  }

  onPrevious(): void {
    this.previous.emit();
  }

  getQueryParams(): { [key: string]: boolean | string } {
    let queryParams = {};

    if (this.adminPreview) {
      queryParams = {
        adminPreview: true,
      };
    } else if (this.isPreview) {
      queryParams = {
        isPreview: true,
      };
    }

    if (this.learningPathId) {
      Object.assign(queryParams, { learningPathId: this.learningPathId });
    }

    return queryParams;
  }

  openUserback(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!Userback) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Userback.open();
  }
}
