/* tslint:disable:no-any */
import {
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { LearningPath } from '@models/learning-path.model';
import { Member } from '@models/member.model';
import { LearningPathMemberResult } from '@models/learning-path/member/learning-path-member-result.model';

@JsonApiModelConfig({
  type: 'learning_path_members',
})
export class LearningPathMember extends JsonApiModel {
  @BelongsTo()
  member: Member | undefined;

  @BelongsTo()
  learningPath: LearningPath | undefined;

  @BelongsTo({ key: 'learning_path_result' })
  learningPathMemberResult!: LearningPathMemberResult;
}
