import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { LearningPath } from '@models/learning-path.model';
import { Member } from '@models/member.model';
import { Group } from '@models/group.model';
import { CourseCategory } from '@models/course-category.model';

@JsonApiModelConfig({
  type: 'learning_path_assignments',
})
export class LearningPathAssignment extends JsonApiModel {
  @BelongsTo()
  source!: Member | Group;

  @BelongsTo()
  target!: LearningPath | CourseCategory;

  @Attribute({ serializedName: 'source_id' })
  sourceId!: string;

  @Attribute({ serializedName: 'target_id' })
  targetId!: string;

  @Attribute({ serializedName: 'target_owner_id' })
  targetOwnerId!: number;

  @Attribute({ serializedName: 'target_owner_name' })
  targetOwnerName!: string;

  @Attribute({ serializedName: 'self_assigned' })
  selfAssigned!: boolean;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;
}
