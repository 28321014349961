import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FiltersResources } from '../interfaces/filters-resources.interface';

@Injectable({
  providedIn: 'root',
})
export class ToolbarFiltersService {
  private filtersResources = new BehaviorSubject<FiltersResources>({});

  public setFilterOverwrite(resource: FiltersResources): void {
    this.filtersResources.next(resource);
  }

  public getFilterOverwrite(): Observable<FiltersResources> {
    return this.filtersResources.asObservable();
  }
}
