import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'dashboard/learn_time_current_year',
})
export class DashboardLearnTimeCurrentYear extends JsonApiModel {
  @Attribute({ serializedName: 'learn_minutes' })
  learnMinutes!: number;

  @Attribute({ serializedName: 'net_time_in_minutes' })
  netMinutes!: number;

  @Attribute({ serializedName: 'learn_minutes_current_year' })
  learnMinutesCurrentYear!: number;

  @Attribute({ serializedName: 'net_time_in_minutes_current_year' })
  netMinutesCurrentYear!: number;
}
