import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'members',
  modelEndpointUrl: 'members/assignees',
})
export class MemberTrimmed extends JsonApiModel {
  @Attribute()
  email!: string;

  @Attribute({ serializedName: 'full_name' })
  fullName!: string;

  @Attribute({ serializedName: 'username' })
  userName!: string;

  get name(): string {
    return this.fullName;
  }

  get identifier(): string {
    return this.email || this.userName;
  }
}
