export enum InfoBubbleType {
  Success,
  Error,
  Info,
  Warning,
  Notification,
}

export class InfoBubble {
  type?: InfoBubbleType;
  title?: string;
  message?: string;

  constructor(init?: Partial<InfoBubble>) {
    Object.assign(this, init);
  }
}
