import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { TrainingScheduleCohort } from '@models/training-schedule/training-schedule-cohort.model';
import { Member } from '@models/member.model';
import { Group } from '@models/group.model';

@JsonApiModelConfig({
  type: 'cohort_assignments',
})
export class CohortAssignment extends JsonApiModel {
  @BelongsTo()
  source!: Member | Group;

  @BelongsTo({ key: 'cohort' })
  target!: TrainingScheduleCohort;

  @Attribute({ serializedName: 'source_id' })
  sourceId!: string;

  @Attribute({ serializedName: 'target_id' })
  targetId!: string;

  @Attribute({ serializedName: 'target_owner_id' })
  targetOwnerId!: number;

  @Attribute({ serializedName: 'target_owner_name' })
  targetOwnerName!: string;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;
}
