import { Injectable } from '@angular/core';

// tslint:disable-next-line:no-any
declare let document: any;

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [];

@Injectable({ providedIn: 'root' })
export class ScriptInjectorService {
  // tslint:disable-next-line:no-any
  private scripts: any = {};

  setScripts(): void {
    // tslint:disable-next-line:no-any
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(scripts: string[]): Promise<void> {
    let promise = Promise.resolve();
    scripts.forEach(script => {
      promise = promise.then(() => this.loadScript(script));
    });
    return promise;
  }

  // tslint:disable-next-line:no-any
  loadScript(name: string): Promise<any> {
    return new Promise(resolve => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = () =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  unload(scripts: string[]): void {
    scripts.forEach(script => {
      this.scripts[script].loaded = false;
    });
  }

  isLoaded(script: string): boolean {
    return this.scripts[script].loaded;
  }
}
