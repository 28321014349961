import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'group_reports',
})
export class GroupReport extends JsonApiModel {
  @Attribute({ serializedName: 'id' })
  groupId!: string;

  @Attribute({ serializedName: 'name' })
  groupName!: string;

  @Attribute({ serializedName: 'members' })
  membersCount!: number;

  @Attribute({ serializedName: 'running' })
  coursesRunning!: number;

  @Attribute({ serializedName: 'courses' })
  coursesCount!: number;

  @Attribute({ serializedName: 'courses_missed' })
  coursesMissed!: number;

  @Attribute({ serializedName: 'avg_progress' })
  courseProgressAvg!: string;

  @Attribute({ serializedName: 'completed' })
  coursesCompleted!: number;

  @Attribute({ serializedName: 'completion_rate' })
  courseCompletionAvg!: string;

  @Attribute({ serializedName: 'avg_score' })
  courseScoreAvg!: string;

  @Attribute({ serializedName: 'learning_time' })
  timeSpentInSeconds!: number;

  @Attribute({ serializedName: 'net_time_in_seconds' })
  netTimeInSeconds!: number;

  @Attribute({ serializedName: 'points' })
  gamificationScore!: number;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;
}
