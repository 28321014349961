import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '@services/api.service';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(
    private api: ApiService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  public getImportCsvUrl(filename: string): Observable<string> {
    const subject = new Subject<string>();

    this.api
      .post(environment.backendBaseUrl, '/api/v1/members/csv_upload', {
        filename,
      })
      .pipe(take(1))
      .subscribe(
        response => {
          subject.next(response.body['upload_to']);
        },
        () => {
          subject.next('');
        },
      );
    return subject.asObservable();
  }

  public uploadFile(file: File, url: string, delimiter: string): void {
    this.api
      .put('', url, file, {
        responseType: 'text',
      })
      .pipe(take(1))
      .subscribe(
        response => {
          this.importCsv(this.getCsvS3Key(response.url), delimiter);
        },
        () => {},
      );
  }

  private getCsvS3Key(url: string): string {
    const urlSeparator = '/';
    const pathName = decodeURI(new URL(url).pathname).split(urlSeparator);
    pathName.splice(0, 2);
    return pathName.join(urlSeparator);
  }

  private importCsv(s3Key: string, delimiter: string): void {
    this.api
      .post(environment.backendBaseUrl, '/api/v1/members/csv_import', {
        // eslint-disable-next-line camelcase
        s3_key: s3Key,
        // eslint-disable-next-line camelcase
        csv_separator: delimiter,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.snackBarService.success(
          this.translateService.instant(
            'attributes.talent.invite.importCsvStartMessage',
          ),
        );
      });
  }
}
