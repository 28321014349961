import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.identityManagerBaseUrl}/api/v1`,
  type: 'password_resets',
})
export class PasswordReset extends JsonApiModel {
  @Attribute()
  email: string | undefined;

  @Attribute({ serializedName: 'company_id' })
  companyId: string | undefined;

  @Attribute({ serializedName: 'member_id' })
  memberId: string | undefined;

  @Attribute({ serializedName: 'expires_at' })
  expiresAt: Date | undefined;

  @Attribute()
  password: string | undefined;

  @Attribute({ serializedName: 'password_confirmation' })
  passwordConfirmation: string | undefined;

  @Attribute({ serializedName: 'old_password' })
  oldPassword: string | undefined;

  @Attribute()
  token: string | undefined;

  @Attribute({ serializedName: 'username' })
  username: string | undefined;

  @Attribute({ serializedName: 'force_user_password_change' })
  forceUserPasswordChange: boolean | undefined;
}
