import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { OrgaTreeRepresentableElement } from '@core/interfaces/organization/orga-tree-representable-element';
import { environment } from '@environment';
import { BrandPalette } from '@models/branding/brand-palette.model';
import { CompanyNewsSetting } from '@models/company/company-news-settings.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'organisation_levels',
})
export class OrganizationLevel
  extends JsonApiModel
  implements OrgaTreeRepresentableElement
{
  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'top_level' })
  topLevel!: string;

  @Attribute()
  level!: number;

  @Attribute({ serializedName: 'full_path' })
  fullPath!: string;

  @Attribute({ serializedName: 'parent_id' })
  parentId!: number;

  @Attribute({ serializedName: 'map_from' })
  company!: string;

  @Attribute({ serializedName: 'definition_name' })
  definitionName!: string;

  @Attribute({ serializedName: 'direct_children_ids' })
  directChildrenIds!: number[];

  @Attribute({ serializedName: 'all_descendant_ids' })
  allDescendantsIds!: number[];

  @Attribute({ serializedName: 'members_count', readonly: true })
  membersCount!: number;

  @Attribute({ serializedName: 'trainings_count' })
  trainingsCount!: number;

  @Attribute({ serializedName: 'self_assignable_count' })
  selfAssignableCount!: number;

  @Attribute({ serializedName: 'all_ancestors_ids' })
  allAncestorsIds!: number[];

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute({ serializedName: 'is_new' })
  isNew!: boolean;

  @Attribute({ serializedName: 'show_gamification_ranking' })
  showGamificationRanking!: boolean;

  @Attribute({ serializedName: 'permissions_count' })
  permissionsCount!: number;

  @BelongsTo({ key: 'brand_palette' })
  brandPalette!: BrandPalette;

  @BelongsTo({ key: 'company_news_setting' })
  news!: CompanyNewsSetting;
}
