import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MultiSelectServerSideSearchComponent } from './multi-select-server-side-search.component';
import { DirectivesModule } from '@core/directives/directives.module';

export const imports = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  MatFormFieldModule,
  MatSelectModule,
  NgxMatSelectSearchModule,
  DirectivesModule,
];

export const declarations = [MultiSelectServerSideSearchComponent];

@NgModule({
  declarations,
  exports: [MultiSelectServerSideSearchComponent],
  imports,
  providers: [],
})
export class MultiSelectServerSideSearchModule {}
