import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '@models/course.model';
import { Member } from '@models/member.model';

@JsonApiModelConfig({
  type: 'certificates',
})
export class Certificate extends JsonApiModel {
  @Attribute({ serializedName: 's3_link' })
  previewUrl!: string;

  @Attribute()
  member!: Member;

  @BelongsTo()
  course!: Course;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;
}
