import {
  Attribute,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { CourseAssignment } from '@models/course-assignment.model';
import { Member } from '@models/member.model';
import { LearningPathAssignment } from './learning-path-assignment.model';
import { ILTCourseAssignment } from '@models/ilt-course-assignment.model';
import { GroupRule } from '@models/group-rule.model';
import { CohortAssignment } from './training-schedule/training-schedule-cohort-assignment.model';
import { VisibilityGroup } from './group/visibility-group.model';
import { Course } from './course.model';
import { ILTCourse } from './ilt-course.model';
import { LearningPath } from './learning-path.model';

@JsonApiModelConfig({
  type: 'groups',
})
export class Group extends JsonApiModel {
  // TODO: Fetch from service
  public static readonly visibilityTypes = [
    { key: 'true', value: 'Public' },
    { key: 'false', value: 'Private' },
  ];

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute()
  language!: string;

  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'external_id' })
  externalId!: string;

  @Attribute({ serializedName: 'members_count' })
  membersCount!: number;

  @Attribute({ serializedName: 'trainees_count' })
  traineesCount!: number;

  @Attribute({ serializedName: 'trainer_count' })
  trainersCount!: number;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute()
  visibility!: boolean;

  @HasMany()
  leaders!: Member[];

  @HasMany()
  members!: Member[];

  @HasMany({ key: 'direct_members' })
  directMembers!: Member[];

  @HasMany({ key: 'members_via_rule' })
  membersViaRule!: Member[];

  @HasMany({ key: 'course_assignments' })
  courseAssignments: CourseAssignment[] = [];

  @HasMany({ key: 'learning_path_assignments' })
  learningPathAssignments: LearningPathAssignment[] = [];

  @HasMany({ key: 'ilt_course_assignments' })
  iltCourseAssignments: ILTCourseAssignment[] = [];

  @HasMany({ key: 'cohort_assignments' })
  cohortAssignments: CohortAssignment[] = [];

  @HasMany({ key: 'group_rules' })
  groupRules: GroupRule[] = [];

  @HasMany({ key: 'visibility_groups' })
  visibilityGroups: VisibilityGroup[] = [];

  @HasMany({ key: 'visibility_groups_courses' })
  visibleCourses: Course[] = [];

  @HasMany({ key: 'visibility_groups_ilt_courses' })
  visibleILTCourses: ILTCourse[] = [];

  @HasMany({ key: 'visibility_groups_learning_paths' })
  visibleLearningPaths: LearningPath[] = [];
}

export class GroupByRule extends Group {
  byRule!: boolean;
}
