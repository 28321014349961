<div class="app-notification-center" (click)="notificationClick($event)">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" fxFlex="grow">  
    <div class="app-notification-center-component width-100" fxLayout="row" fxLayoutAlign="flex-end center">
      <button
        class="notification-close class-to-check-close no-border no-padding"
        mat-icon-button
        (click)="onClose()"
      >
        <mat-icon class="class-to-check-close size-s">close</mat-icon>
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px" fxFlex="grow">
      <div class="app-notification-center-table-container elearnio-mat-table" [class.table-empty]="!getColumnsToDisplay().length">
        <div class="elearnio-mat-table-wrapper">
          <table mat-table matSort [dataSource]="dataSource" class="app-notification-center-table">
            <ng-container matColumnDef="operation" sticky>
              <th mat-header-cell *matHeaderCellDef class="operation-header"> {{ 'notifications.operation' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column">
                  <span class="mat-body name">
                    {{element.subject}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="timeStarted" class="center">
              <th mat-header-cell *matHeaderCellDef class="operation-time-started"> {{ 'notifications.timeStarted' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column">
                  <span class="mat-body name">
                    {{element.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status" class="center">
              <th mat-header-cell *matHeaderCellDef class="operation-status"> {{ 'notifications.status' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column">
                  <span class="mat-body name bold" [ngClass]="getNotificationTextColor(element)">
                    {{element.notificationType}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef class="operation-owner"> {{ 'notifications.owner' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column">
                  <span class="mat-body name">
                    {{element.member.name}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions" class="center">
              <th mat-header-cell *matHeaderCellDef class="operation-remove"> 
              </th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column">
                  <span *ngIf="canBeDeleted(element)" class="mat-body name">
                    <mat-icon class="delete-notification" (click)="onDelete(element)">delete</mat-icon>
                  </span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="getColumnsToDisplay(); sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="7">
                <div fxLayout="column" fxLayoutAlign="center center">
                  {{ 'notifications.noNotifications' | translate }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between flex-start" class="width-100">
        <div fxLayout="row" fxLayoutGap="16px">
          <button
            *ngIf="currentMember?.role === ROLES.ADMIN"
            class="no-border no-padding with-icon-margin"
            mat-stroked-button
            color="darkblue"
            [disabled]="disabledRefresh"
            (click)="onDeleteNotifications(false)"
          >
            <mat-icon class="size-sm">delete</mat-icon>
            {{ 'notifications.deleteAll' | translate }}
          </button>
          <button
            class="no-border no-padding with-icon-margin"
            mat-stroked-button
            color="darkblue"
            [disabled]="disabledRefresh"
            (click)="onDeleteNotifications(true)"
          >
            <mat-icon class="size-sm">delete</mat-icon>
            {{ 'notifications.deleteSelf' | translate }}
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="center flex-end" fxLayoutGap="12px" >
          <button
            class="filled no-border with-icon-margin"
            mat-stroked-button
            color="darkblue"
            [disabled]="disabledRefresh"
            (click)="onRefresh()"
          >
            <mat-icon class="size-sm">refresh</mat-icon>
            {{ 'notifications.refresh' | translate }}
          </button>
          <div fxLayout="column" fxLayoutAlign="center center" class="notification-timer">
            <span>
              {{ 'notifications.automaticallyRefresh' | translate }} 
              <span class="weight-600">
                {{ timeRemaining$ | async }}
              </span>
              {{ 'notifications.seconds' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>