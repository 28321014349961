import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ApiService } from '@services/api.service';
import { MemberService } from '@services/member.service';
import { IPermission } from '@core/interfaces/permission.interface';
import { SideAndTopBarService } from './side-and-top-bar.service';
import { Member } from '@app/models/member.model';
import { Roles } from '../constants/roles.constant';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private reportingPermissions: IPermission = {};
  private memberPermissions: IPermission = {};
  private groupPermissions: IPermission = {};
  private coursePermissions: IPermission = {};
  private iltPermissions: IPermission = {};
  private lpPermissions: IPermission = {};
  private companySettingPermissions: IPermission = {};
  private organizationPermissions: IPermission = {};
  private trainingSchedulePermissions: IPermission = {};
  private categoriesPermissions: IPermission = {};

  private currentMember: Member | null = null;
  private role = Roles;

  constructor(
    private api: ApiService,
    private memberService: MemberService,
    private sidebarService: SideAndTopBarService,
  ) {}

  public getReportingPermissions(): IPermission {
    return this.reportingPermissions;
  }

  public getCoursePermissions(): IPermission {
    return this.coursePermissions;
  }

  public getIltCoursePermissions(): IPermission {
    return this.iltPermissions;
  }

  public getLPPermissions(): IPermission {
    return this.lpPermissions;
  }

  public getCategoriesPermissions(): IPermission {
    return this.categoriesPermissions;
  }

  public getMemberPermissions(): IPermission {
    return this.memberPermissions;
  }

  public getGroupPermissions(): IPermission {
    return this.groupPermissions;
  }

  public getCompanySettingPermissions(): IPermission {
    return this.companySettingPermissions;
  }

  public getOrganizationPermissions(): IPermission {
    return this.organizationPermissions;
  }

  public getTrainingSchedulePermissions(): IPermission {
    return this.trainingSchedulePermissions;
  }

  public setGeneralPermissions(): void {
    this.getPermission('CourseTalentReport')
      .pipe(take(1))
      .subscribe(permission => {
        this.reportingPermissions = permission;
      });
    this.getPermission('Schedule')
      .pipe(take(1))
      .subscribe(permission => {
        this.trainingSchedulePermissions = permission;
      });
    this.getPermission('Course')
      .pipe(take(1))
      .subscribe(permission => {
        this.coursePermissions = permission;
      });
    this.getPermission('IltCourse')
      .pipe(take(1))
      .subscribe(permission => {
        this.iltPermissions = permission;
      });
    this.getPermission('LearningPath')
      .pipe(take(1))
      .subscribe(permission => {
        this.lpPermissions = permission;
      });
    this.getPermission('CourseCategory')
      .pipe(take(1))
      .subscribe(permission => {
        this.categoriesPermissions = permission;
      });
    this.getPermission('Member')
      .pipe(take(1))
      .subscribe(permission => {
        this.memberPermissions = permission;
      });
    this.getPermission('Group')
      .pipe(take(1))
      .subscribe(permission => {
        this.groupPermissions = permission;
      });
    this.getPermission('CompanySetting')
      .pipe(take(1))
      .subscribe(permission => {
        this.companySettingPermissions = permission;
      });
    this.getPermission('OrganisationLevel')
      .pipe(take(1))
      .subscribe(permission => {
        this.organizationPermissions = permission;
        if (
          this.currentMember &&
          this.currentMember.role === this.role.LEARNER
        ) {
          this.sidebarService.setValue(
            'hideMenuButton',
            permission.people_management,
          );
        }
      });
  }

  public getPermission(
    resourceName: string,
    resourceId?: string,
  ): Observable<IPermission> {
    const subject = new Subject<IPermission>();

    this.memberService
      .current()
      .pipe(take(1))
      .subscribe(member => {
        if (!member) {
          subject.next({});
          return;
        }
        this.currentMember = member;
        let url = `/api/v1/permission?&resource_class=${resourceName}`;
        if (resourceId) {
          url += `&resource_id=${resourceId}`;
        }

        this.api
          .get(environment.backendBaseUrl, url)
          .pipe(take(1))
          .subscribe(response => {
            subject.next(response.body);
          });
      });
    return subject.asObservable();
  }
}
