/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { CourseMemberResult } from './course-member-result.model';
import { CourseActivity } from '../course-activity.model';

@JsonApiModelConfig({
  type: 'course_member_answers',
})
export class CourseMemberAnswer extends JsonApiModel {
  @BelongsTo({ serializedName: 'course_activity' })
  courseActivity: CourseActivity | undefined;

  @BelongsTo({ serializedName: 'course_member_result' })
  courseResult: CourseMemberResult | undefined;

  @Attribute()
  content!: string;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;
}
