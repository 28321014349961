import { Injectable } from '@angular/core';
import { Datastore } from '@services/datastore';
import { User } from '@models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { environment } from '@environment';
import { take } from 'rxjs/operators';
import { JwtHelper } from '@helper/jwt-helper';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  static readonly identityManagerUrl = `${environment.identityManagerBaseUrl}/api/v1`;
  static readonly loginRoute = `${AuthService.identityManagerUrl}/users/login`;
  static readonly loginOauthRoute = `${AuthService.identityManagerUrl}/users/login_oauth`;
  static readonly logoutRoute = `${AuthService.identityManagerUrl}/users/logout`;
  static readonly refreshTokenRoute = `${AuthService.identityManagerUrl}/users/token_refresh`;
  static readonly jwtTokenInfoCookieName = 'eAuthTokenInfo';
  static readonly jwtTokenCookieName = 'eAuthToken';

  private userSubject: BehaviorSubject<User | null>;
  private loginCallbacks: Array<() => void> = [];
  private loginCallbacksOnce: Array<() => void> = [];
  private logoutCallbacks: Array<() => void> = [];
  private loginErrorCallbacks: Array<(error: HttpErrorResponse) => void> = [];

  private refreshTokenTimeout: number | undefined;

  constructor(
    private datastore: Datastore,
    private router: Router,
    public http: HttpClient,
    private cookies: CookieService,
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.userSubject = new BehaviorSubject<User>(null);
  }

  public getUser(): Observable<User | null> {
    return this.userSubject.asObservable();
  }

  public setUser(user: User): void {
    this.userSubject.next(user);
  }

  public isAuthenticated(): boolean {
    return !!this.userSubject.value;
  }

  public hasAuthCookie(): boolean {
    // we check here for the info cookie that have to be present if the auth
    // cookie is present - but because of http only we are not able to access
    // that cookie
    return !!this.cookies.get(AuthService.jwtTokenInfoCookieName);
  }

  public getAuthCookie(): string {
    return this.cookies.get(AuthService.jwtTokenCookieName);
  }

  public getAuthCookieInfo(): any {
    return this.cookies.get(AuthService.jwtTokenInfoCookieName);
  }

  // tslint:disable-next-line:no-any
  public refreshToken(): Observable<any> {
    // TODO: BehaviorSubject<any> to avoid double call on app init
    const request = this.http.post<any>(
      AuthService.refreshTokenRoute,
      {},
      { withCredentials: true, observe: 'response' },
    );

    request.pipe(take(1)).subscribe(
      response => {
        if (!this.isAuthenticated()) {
          this.onLogin(response, true);
        }
      },
      () => {
        this.onLogout();
      },
    );

    return request;
  }

  public login(
    email: string,
    password: string,
    remember: boolean,
    isUserName = false,
  ): Observable<any> {
    const data = {
      email,
      password,
      remember,
      isUserName,
    };

    const request = this.http.post(AuthService.loginRoute, data, {
      withCredentials: true,
      observe: 'response',
    });

    request.pipe(take(1)).subscribe(
      response => {
        this.onLogin(response, false);
      },
      (response: HttpErrorResponse) => {
        this.handleError(response);
      },
    );

    return request;
  }

  public loginWithSSO(
    code: string,
    companyId: string,
    state: string,
  ): Observable<any> {
    const data: { [key: string]: string } = {
      code,
      // eslint-disable-next-line camelcase
      company_id: companyId,
    };

    if (state) {
      data.state = state;
    }

    const request = this.http.post(AuthService.loginOauthRoute, data, {
      withCredentials: true,
      observe: 'response',
    });

    request.pipe(take(1)).subscribe(
      response => {
        this.onLogin(response, false);
      },
      (response: HttpErrorResponse) => {
        this.handleError(response);
      },
    );

    return request;
  }

  public logout(goTo = '/login', params = {}): void {
    this.onLogout();
    this.http
      .post(
        AuthService.logoutRoute,
        {},
        {
          withCredentials: true,
        },
      )
      .pipe(take(1))
      .subscribe(
        () => {
          this.router.navigate([goTo || '/login'], {
            queryParams: params,
            skipLocationChange: false,
          });
        },
        (response: HttpErrorResponse) => {
          this.handleError(response);
        },
      );
  }

  public registerLoginCallback(callback: () => void): void {
    this.loginCallbacks.push(callback);
    if (this.userSubject.value) {
      return callback();
    }
  }

  public registerLoginCallbackOnce(
    callback: () => void,
    triggerNow = true,
  ): void {
    if (!this.userSubject.value) {
      this.loginCallbacksOnce.push(callback);
    } else if (triggerNow && this.userSubject.value) {
      return callback();
    }
  }

  public registerLogoutCallback(callback: () => void): void {
    this.logoutCallbacks.push(callback);
    if (!this.userSubject.value) {
      return callback();
    }
  }

  public registerLoginErrorCallback(
    callback: (error: HttpErrorResponse) => void,
  ): void {
    this.loginErrorCallbacks.push(callback);
  }

  public getSubdomain(): string {
    return window.location.hostname.split('.')[0];
  }

  private navigateToSubdomain(
    subdomain: string,
    navigateToSubdomainCurrentPath: boolean,
  ): void {
    if (!environment.production) {
      return;
    }

    const protocol = window.location.protocol;
    const mainDomain = environment.domain;
    const hash = navigateToSubdomainCurrentPath ? window.location.hash : '#/my';
    window.location.href = `${protocol}//${subdomain}.${mainDomain}/${hash}`;
  }

  private handleError(errorResponse: HttpErrorResponse): void {
    console.error('authenticate::auth error:', errorResponse);
    for (const callback of this.loginErrorCallbacks) {
      callback(errorResponse);
    }
  }

  private setUserFromResponse(response: any): User {
    const data = response.body.data;
    data.attributes = JwtHelper.underscoredAttributes2camelCased(
      data.attributes,
    );

    const user = new User(this.datastore, data);
    this.setUser(user);
    return user;
  }

  private onLogout(): void {
    this.stopRefreshTokenTimer();
    for (const callback of this.logoutCallbacks) {
      callback();
    }
    this.userSubject.next(null);
  }

  private onLogin(
    response: HttpResponse<any>,
    navigateToSubdomainCurrentPath: boolean,
  ): void {
    const user = this.setUserFromResponse(response);

    if (user.subdomain && this.getSubdomain() !== user.subdomain) {
      this.navigateToSubdomain(user.subdomain, navigateToSubdomainCurrentPath);
      return;
    } else if (
      environment.production &&
      !user.subdomain &&
      this.getSubdomain() !== environment.baseSubdomain
    ) {
      const hash = navigateToSubdomainCurrentPath
        ? window.location.hash
        : '#/my';
      window.location.href = `https://${environment.domain}/${hash}`;
    }

    for (const callback of this.loginCallbacks) {
      callback();
    }

    for (const callback of this.loginCallbacksOnce) {
      callback();
    }

    this.loginCallbacksOnce = [];

    this.startRefreshTokenTimer();
  }

  private nextTokenRefreshInSeconds(): number {
    const cookieData = this.cookies.get(AuthService.jwtTokenInfoCookieName);

    if (!cookieData) {
      return 59 * 60;
    }

    const cookieDataObject = JSON.parse(cookieData);

    const nextRefresh = cookieDataObject.expires as number;
    if (!nextRefresh) {
      return 59 * 60;
    }

    return nextRefresh - 60;
  }

  private startRefreshTokenTimer() {
    this.refreshTokenTimeout = window.setTimeout(
      () => this.refreshToken(),
      this.nextTokenRefreshInSeconds() * 1000,
    );
  }

  private stopRefreshTokenTimer() {
    window.clearTimeout(this.refreshTokenTimeout);
  }
}
