<mat-form-field appearance="outline">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    disableOptionCentering
    panelClass="multi-select-server-side-select-panel"
    [formControl]="multiLocalCtrl"
    [multiple]="true"
    #multiSelect
    (selectionChange)="selectionChanged($event)"
  >
    <mat-select-trigger>
      {{multiLocalCtrl.value &&  multiLocalCtrl.value[0] ? getName(multiLocalCtrl.value[0]) : ''}}
      <span *ngIf="multiLocalCtrl.value?.length > 1" class="additional-selection">
        (+{{multiLocalCtrl.value.length - 1}} {{multiLocalCtrl.value?.length === 2
          ? ('common.other' | translate)
          : ('common.others' | translate)
        }})
      </span>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [noEntriesFoundLabel]="noEntriesFoundLabel"
        [placeholderLabel]="placeholderLabel"
        [formControl]="multiLocalFilterCtrl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let resource of filteredResourcesMulti | async"
      [value]="resource"
      [ngClass]="getNodeDepthClass(resource)"
    >
      {{getName(resource)}}
    </mat-option>
  </mat-select>
</mat-form-field>
