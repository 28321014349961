import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarComponent } from '@shared/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private readonly duration = 5000;

  constructor(private snackBar: MatSnackBar) {}

  public error(message: string): void {
    this.openSnackBar('error', message);
  }

  public success(message: string): void {
    this.openSnackBar('success', message);
  }

  private openSnackBar(level: string, message: string): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: { level, message },
      duration: this.duration,
    });
  }
}
