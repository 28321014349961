import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';
import { LearningPathReminder } from '@models/learning-path/learning-path-reminder.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'learning_path_reminder_types',
})
export class LearningPathReminderType extends JsonApiModel {
  @Attribute({ serializedName: 'reminder_type' })
  reminderType!: string;

  @BelongsTo({ key: 'learning_path_reminder' })
  reminder!: LearningPathReminder;
}
