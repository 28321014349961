import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import { Course } from '@models/course.model';
import { ILTCourse } from '@models/ilt-course.model';
import { LearningPathCourseSection } from './learning-path-course-section.model';
import { LearningPathSectionLabel } from './learning-path-section-label.model';

@JsonApiModelConfig({
  type: 'learning_path_section_courses_orders',
})
export class LearningPathSectionCourseOrder extends JsonApiModel {
  @Attribute()
  order!: number;

  @Attribute({ serializedName: 'learning_path_courses_section_id' })
  learningPathCoursesSectionId!: string;

  @Attribute({ serializedName: 'coursable_id' })
  courseId?: string;

  @Attribute({ serializedName: 'coursable_type' })
  coursableType?: string;

  @Attribute({ serializedName: 'current_user_author' })
  currentUserAuthor?: boolean;

  @BelongsTo()
  learningPathCourseSection!: LearningPathCourseSection;

  @BelongsTo({ key: 'coursable' })
  course!: Course | ILTCourse | LearningPathSectionLabel;
}
