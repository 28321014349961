/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { ILTCourse } from '@app/models/ilt-course.model';
import { OrganizationLevel } from '@models/organization/organization-level.model';

@JsonApiModelConfig({
  type: 'organisation_levels_ilt_courses',
})
export class OrganizationLevelIltCourse extends JsonApiModel {
  @Attribute({ serializedName: 'ilt_course_id' })
  iltId!: string;

  @Attribute({ serializedName: 'organisation_level_id' })
  organizationLevelId!: string;

  @Attribute({ serializedName: 'show_in_catalog' })
  showInCatalogue!: boolean;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: boolean;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: boolean;

  @BelongsTo()
  course: ILTCourse | undefined;

  @BelongsTo({ key: 'organisation_level' })
  organizationLevel!: OrganizationLevel;

  @BelongsTo({ key: 'inherited_from' })
  inheritedFrom!: OrganizationLevel | undefined;
}
