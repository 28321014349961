import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'notifications',
})
export class NotificationSettings extends JsonApiModel {
  @Attribute()
  name!: string;

  @Attribute()
  template!: number;

  @Attribute()
  email?: boolean;

  @Attribute()
  online?: boolean;
}
