export const FULL_DAY_ITEM_ID = 'full_day_items';
export const DATE_FORMAT = 'YYYYMMDD';

export enum ITEM_TYPES {
  BLOCK = 'Block',
  MEETING = 'Meeting',
  COURSE = 'Course',
  SCHEDULED_ILT_COURSE = 'ScheduledIltCourse',
  EVENT_DAY = 'EventDay',
}
