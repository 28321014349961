import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '@models/course.model';
import { Member } from '@models/member.model';
import { CourseAssignment } from '@models/course-assignment.model';
import { LearningPath } from './learning-path.model';
import { LearningPathAssignment } from './learning-path-assignment.model';
import { TreeRepresentableElement } from '@core/interfaces/tree-representable-element';
import { ILTCourse } from './ilt-course.model';
import { ILTCourseAssignment } from './ilt-course-assignment.model';

@JsonApiModelConfig({
  type: 'course_categories',
})
export class CourseCategory
  extends JsonApiModel
  implements TreeRepresentableElement
{
  @Attribute()
  ancestry!: string;

  @Attribute({ serializedName: 'child_ids' })
  childIds!: number[];

  @Attribute({ serializedName: 'courses_count' })
  coursesCount!: number;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute()
  description!: string;

  @Attribute()
  language!: string;

  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'parent_id' })
  parentId!: string;

  @Attribute({ serializedName: 'self_assignment' })
  selfAssignment!: boolean;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute()
  order!: number;

  @BelongsTo()
  author!: Member;

  @HasMany()
  courses!: Course[];

  @HasMany({ key: 'ilt_courses' })
  iltCourses!: ILTCourse[];

  @HasMany({ key: 'course_assignments' })
  courseAssignments: CourseAssignment[] = [];

  @HasMany({ key: 'learning_paths' })
  learningPaths!: LearningPath[];

  @HasMany({ key: 'learning_path_assignments' })
  learningPathAssignments: LearningPathAssignment[] = [];

  @HasMany({ key: 'ilt_course_assignments' })
  iltCourseAssignments: ILTCourseAssignment[] = [];

  get elementsCount(): number {
    return this.coursesCount;
  }
}
