import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.identityManagerBaseUrl}/api/v1`,
  type: 'email_registrations',
})
export class EmailRegistration extends JsonApiModel {
  @Attribute()
  email!: string;

  @Attribute()
  language!: string;
}
