import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '@models/course.model';
import { CourseCategory } from '@models/course-category.model';
import { Member } from '@models/member.model';
import { Group } from '@models/group.model';

@JsonApiModelConfig({
  type: 'course_assignments',
})
export class CourseAssignment extends JsonApiModel {
  @BelongsTo()
  source!: Member | Group;

  @BelongsTo()
  target!: Course | CourseCategory;

  @Attribute({ serializedName: 'source_id' })
  sourceId!: string;

  @Attribute({ serializedName: 'target_id' })
  targetId!: string;

  @Attribute({ serializedName: 'learning_path_assignment_id' })
  learningPathAssignmentId!: string;

  @Attribute({ serializedName: 'target_owner_id' })
  targetOwnerId!: number;

  @Attribute({ serializedName: 'target_owner_name' })
  targetOwnerName!: string;

  @Attribute({ serializedName: 'self_assigned' })
  selfAssigned!: boolean;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;
}
