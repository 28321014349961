import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { PermissionService } from '@services/permission.service';
import { IPermission } from '../interfaces/permission.interface';
import { MemberService } from '../services/member.service';
import { Roles } from '../constants/roles.constant';
import { Member } from '@app/models/member.model';

@Injectable()
export class TalentsGuard implements CanActivate {
  ROLES = Roles;
  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private memberService: MemberService,
  ) {}

  public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const navigateToDashboard = () => this.router.navigateByUrl('/my');
    return new Promise(resolve => {
      if (!next.data.roles) {
        return true;
      }

      this.memberService.current().subscribe((member: Member | null) => {
        if (
          member &&
          (member.role === this.ROLES.ADMIN ||
            member.role === this.ROLES.GLOBAL_COACH ||
            member.role === this.ROLES.GLOBAL_MANAGER)
        ) {
          resolve(true);
        } else {
          const permissions =
            this.permissionService.getOrganizationPermissions();
          const navigateToTalentsList = (permissions: IPermission) => {
            if (
              permissions.people_management ||
              permissions.assign_all_entities
            ) {
              resolve(true);
            } else {
              navigateToDashboard();
              resolve(false);
            }
          };

          if (permissions === ({} as IPermission)) {
            this.permissionService
              .getPermission('OrganisationLevel')
              .pipe(take(1))
              .subscribe(permission => {
                navigateToTalentsList(permission);
              });
          } else {
            navigateToTalentsList(permissions);
          }
        }
      });
    });
  }
}
