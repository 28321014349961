export function getAssets(lang) {
  return {
    css: [
      'core/dist/h5p.css',
      'core/dist/h5p-confirmation-dialog.css',
      'core/dist/h5p-custom-dialog.css',
      'core/dist/h5p-core-button.css'
    ],
    editorCss: [
      'editor/libs/darkroom.css',
      'editor/styles/css/h5p-hub-client.css',
      'editor/styles/css/fonts.css',
      'editor/styles/css/application.css',
      'editor/styles/css/libs/zebra_datepicker.min.css',
    ],
    js: [
      'core/dist/core-integration-bundle.js',
      'editor/dist/bundle.js',
    ],
    currentTranslation: [
      `editor/language/${lang}.js`,
    ],
    allTranslations: [
      `editor/language/en.js`,
      `editor/language/de.js`,
    ],
  };
  
}