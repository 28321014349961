<section ngClass.gt-sm="large">
  <div class="dialog-title">
    <h2>{{data.title}}</h2>
  </div>
  <div class="mat-body dialog-message" mat-dialog-content fxLayoutGap="12px" fxLayout="column">
    <div
        class="container-form-inactive-user"
        fxLayout="row"
        fxLayoutGap="8px"
      >
        <mat-icon>warning</mat-icon>
        <div fxLayout="column" fxLayoutGap="2px">
          <h3 class="bold">
            {{ 'reset-password.currentWarningMessageTitle' | translate }}
          </h3>
          <span class="container-form-inactive-user-description">
            {{ 'reset-password.currentWarningMessage' | translate }}
          </span>
        </div>
      </div>
    <form class="width-100 height-100" [formGroup]="passwordResetForm" #resetPasswordForm="ngForm" fxLayout="column"
            fxLayoutAlign="flex-start center">
      <mat-form-field class="width-100" appearance="outline">
        <mat-label>
          {{ 'reset-password.newPassword' | translate }}
        </mat-label>
        <input
          matInput
          [type]="isShowPassword ? 'text' : 'password'"
          formControlName="password"
          id="reset-password"
          name="reset-password"
          [(ngModel)]="passwords.password"
          [placeholder]="'userParams.passwordPlaceholder' | translate"
          required 
          ng-pattern="/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/"
          required
          minlength="6"
        />
        <mat-icon color="accent" matSuffix
                  (click)="isShowPassword = !isShowPassword">{{isShowPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <mat-form-field class="width-100" appearance="outline">
        <mat-label>
          {{ 'reset-password.confirmNewPassword' | translate }}
        </mat-label>
        <input
          matInput
          [type]="isShowPassword ? 'text' : 'password'"
          formControlName="confirmPassword"
          id="reset-password-confirm"
          name="reset-password-confirm"
          [(ngModel)]="passwords.passwordConfirmation"
          [placeholder]="'userParams.passwordPlaceholder' | translate"
          required 
          ng-pattern="/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/"
          required
          minlength="6"
        />
        <mat-icon color="accent" matSuffix
                  (click)="isShowPassword = !isShowPassword">{{isShowPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="passwordResetForm.hasError('passwordMismatch') && (passwordResetForm.touched || passwordResetForm.dirty)">
          {{ 'userParams.passwordMismatch' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <mat-toolbar
    fxLayout="row"
    fxLayoutAlign="flex-end flex-end"
    fxLayoutGap="20px"
    fxLayout.lt-sm="column"
    fxLayoutGap.lt-sm="12px"
    ngClass.lt-sm="mobile"
  >
    <button mat-stroked-button color="darkblue" [mat-dialog-close]="false">
      {{ data.cancel ? data.cancel  : ('common.cancel' | translate) }}
    </button>
    <button [disabled]="isSaveDisabled()" class="no-border" color="yellow" mat-stroked-button [mat-dialog-close]="true" (click)="savePassword()">
      {{ data.confirm ? data.confirm : ('common.save' | translate) }}
    </button>
  </mat-toolbar>
</section>
