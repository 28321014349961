export function stringArrayEqual(arr1: string[], arr2: string[]): boolean {
  const superSet: { [key: string]: number } = {};
  for (const i of arr1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of arr2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (const e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
}

export function arrayDiff<T extends { id?: string }>(
  arr1: T[],
  arr2: T[],
): T[] {
  return arr1.filter(({ id: id1 }) => !arr2.some(({ id: id2 }) => id2 === id1));
}

export function stringArrayDiff(arr1: string[], arr2: string[]): string[] {
  return arr1.filter(s1 => !arr2.some(s2 => s1 === s2));
}
