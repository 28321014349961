<div class="notification-modal-dialog"
     *ngIf="notify.isOpen"
>
  <div class="notification-pane">
    <div class="notification-pane-content">
      <!-- Header -->
      <header [ngClass]="['notification-header', notify.notification.type]">
        <div [ngClass]="[notify.notification.type]">
          <div
            *ngIf="notify.notification.type === 'error'"
            class="close-modal-button white"
          ></div>
        </div>
      </header>

      <!-- Notification description -->
      <main class="content">
        <div class="notification-title">{{ notify.notification.title }}</div>
        <div class="notification-detail">{{ notify.notification.message }}</div>

        <div [innerHTML]="notify.notification.detailedMessage"></div>
        <!-- Submit button (as close) -->
        <button
          [ngClass]="['notification-submit', notify.notification.type]"
          (click)="notify.close()"
        >
          {{ notify.notification.button }}
        </button>
      </main>

      <!-- Close button -->
      <div class="close-modal-button-container" (click)="notify.close()">
        <div class="close-modal-button gray"></div>
      </div>
    </div>
  </div>
</div>
