import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HeaderService } from '@services/header.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderGuard implements CanActivate {
  constructor(private headerService: HeaderService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(resolve => {
      if (route.data.hideHeader) {
        this.headerService.setValue('showHeader', !route.data.hideHeader);
      } else {
        this.headerService.setValue('showHeader', true);
      }
      resolve(true);
    });
  }
}
