export class JwtHelper {
  static underscoredAttributes2camelCased(
    attributes: Record<string, any>,
  ): Record<string, any> {
    Object.entries(attributes).forEach(([key, value]) => {
      delete attributes[key];
      key = key
        .split('_')
        .map(input => input.charAt(0).toUpperCase() + input.slice(1))
        .join('');
      key = key.charAt(0).toLowerCase() + key.slice(1);
      attributes[key] = value;
    });
    return attributes;
  }
}
