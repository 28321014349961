import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.identityManagerBaseUrl}/api/v1`,
  type: 'company_admin_registrations',
})
export class CompanyAdminRegistration extends JsonApiModel {
  @Attribute()
  password!: string;

  @Attribute()
  token!: string;

  @Attribute()
  email!: string;

  @Attribute()
  company!: string;

  @Attribute()
  subdomain!: string;

  @Attribute({ serializedName: 'expires_at' })
  expiresAt!: number;

  @Attribute({ serializedName: 'first_name' })
  firstName!: string;

  @Attribute({ serializedName: 'last_name' })
  lastName!: string;
}
