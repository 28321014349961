import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursesCategoriesSidebarComponent } from './app-sidebar.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { SettingsSidebarModule } from '@shared/settings-sidebar/settings-sidebar.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TrainingStatusModule } from '@shared/training-status/training-status.module';

@NgModule({
  declarations: [CoursesCategoriesSidebarComponent],
  exports: [CoursesCategoriesSidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatBadgeModule,
    MatDividerModule,
    SettingsSidebarModule,
    MatIconModule,
    TrainingStatusModule,
  ],
})
export class AppSidebarModule {}
