import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';
import { LearningPath } from '../learning-path.model';
import { LearningPathReminderType } from '@models/learning-path/learning-path-reminder-types.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'learning_path_reminders',
})
export class LearningPathReminder extends JsonApiModel {
  @Attribute()
  time!: number;

  @Attribute({ serializedName: 'time_unit' })
  timeUnit!: string;

  @Attribute({ serializedName: 'reminder_scope' })
  reminderScope!: string;

  @BelongsTo({ key: 'learning_path' })
  learningPath!: LearningPath;

  @HasMany({ key: 'learning_path_reminder_types' })
  reminderTypes!: LearningPathReminderType[];
}
