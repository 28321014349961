/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-h5p-player-timer-component',
  templateUrl: './h5p-player-timer.component.html',
  styleUrls: ['h5p-player-timer.component.scss'],
})
export class H5pPlayerTimerComponent implements OnInit, OnDestroy {
  @Input() durationInMinutes = 5;
  @Output() timerZero: EventEmitter<void> = new EventEmitter<void>();

  minutes = 0;
  seconds = 0;
  private subscription!: Subscription;

  constructor() {
    this.minutes = 0;
    this.seconds = 0;
  }

  ngOnInit(): void {
    const durationInSeconds = this.durationInMinutes * 60;
    const timer$ = interval(1000);

    this.subscription = timer$.subscribe(elapsedSeconds => {
      const remainingSeconds = durationInSeconds - elapsedSeconds;
      this.minutes = Math.floor(remainingSeconds / 60);
      this.seconds = remainingSeconds % 60;

      if (remainingSeconds <= 0) {
        this.subscription.unsubscribe();
        this.timerZero.emit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
