import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'dashboard_my_tasks',
})
export class DashboardMyTask extends JsonApiModel {
  @Attribute()
  name!: string;

  @Attribute()
  dueDate!: Date;
}
