import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'brand_palettes',
})
export class BrandPalette extends JsonApiModel {
  @Attribute({ serializedName: 'id' })
  brandPaletteId!: number;

  @Attribute({ serializedName: 'name' })
  brandPaletteName!: string;

  @Attribute({ serializedName: 'brand_color' })
  brandColor!: string;

  @Attribute({ serializedName: 'interactive_elements_color' })
  interactiveElementsColor!: string;

  @Attribute({ serializedName: 'main_button_color' })
  mainButtonColor!: string;

  @Attribute({ serializedName: 'button_text_color' })
  buttonTextColor!: string;

  @Attribute({ serializedName: 'logo' })
  logo!: string;

  @Attribute({ serializedName: 'favicon' })
  favicon!: string;

  @Attribute({ serializedName: 'default_palette' })
  defaultPalette!: boolean;
}
