/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Course } from '../course.model';
import { CourseActivity } from './course-activity.model';

@JsonApiModelConfig({
  type: 'course_activity_sections',
})
export class CourseActivitySection extends JsonApiModel {
  @Attribute({ serializedName: 'course_id' })
  courseId!: string;

  @Attribute()
  title!: string;

  @Attribute()
  order!: number;

  @BelongsTo()
  course!: Course;

  @HasMany({ key: 'course_activities' })
  courseActivities: CourseActivity[] = [];
}
