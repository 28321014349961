/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { ILTCourse } from '@app/models/ilt-course.model';
import { Member } from '@app/models/member.model';

@JsonApiModelConfig({
  type: 'ilt_courses_members',
})
export class ILTCoursesMember extends JsonApiModel {
  @Attribute({ serializedName: 'ilt_course_id' })
  iltCourseId!: string;

  @Attribute({ serializedName: 'member_id' })
  memberId!: string;

  @Attribute()
  status!: string;

  @BelongsTo()
  member: Member | undefined;

  @BelongsTo()
  iltCourse: ILTCourse | undefined;
}
