/* tslint:disable:no-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public get(server: string, path: string, options?: unknown): Observable<any> {
    return this.http.get(
      server + path,
      Object.assign({ observe: 'response' as 'body' }, options),
    );
  }

  public post(
    server: string,
    path: string,
    data: unknown,
    options?: unknown,
  ): Observable<any> {
    return this.http.post(
      server + path,
      data,
      Object.assign({ observe: 'response' as 'body' }, options),
    );
  }

  public put(
    server: string,
    path: string,
    data: unknown,
    options?: unknown,
  ): Observable<any> {
    return this.http.put(
      server + path,
      data,
      Object.assign({ observe: 'response' as 'body' }, options),
    );
  }

  public delete(
    server: string,
    path: string,
    options?: unknown,
  ): Observable<any> {
    return this.http.delete(
      server + path,
      Object.assign({ observe: 'response' as 'body' }, options),
    );
  }
}
