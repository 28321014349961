/* tslint:disable:no-any */
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
  constructor() {
    if (environment.sentryErrorHandlerDsn) {
      import('@sentry/browser').then(Sentry => {
        Sentry.init({
          dsn: environment.sentryErrorHandlerDsn,
          sampleRate: 0.5,
        });
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleError(error: any): void {
    error = error.originalError || error;

    console.error(error);

    this.handleErrorWithSentry(error);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleErrorWithSentry(error: any): void {
    if (!environment.sentryErrorHandlerDsn) {
      return;
    }
    import('@sentry/browser').then(Sentry => {
      Sentry.captureException(error);
    });
  }
}
