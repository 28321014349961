/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import { ILTCourse } from '../../ilt-course.model';
import { ILTCertificate } from '@models/template/ilt-certificate.model';
import { Member } from '@app/models/member.model';

@JsonApiModelConfig({
  type: 'ilt_courses_member_results',
})
export class ILTCourseMemberResult extends JsonApiModel {
  @Attribute({ serializedName: 'completion_date' })
  completionDate!: Date;

  @Attribute({ serializedName: 'course_gamification_points' })
  gamificationPoints!: number;

  @Attribute({ serializedName: 'ilt_course_id' })
  iltCourseId!: string;

  @BelongsTo()
  iltCourse!: ILTCourse;

  @BelongsTo()
  member!: Member;

  @BelongsTo({ key: 'ilt_certificate' })
  certificate!: ILTCertificate;
}
