import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'learning_path_section_labels',
})
export class LearningPathSectionLabel extends JsonApiModel {
  @Attribute({ serializedName: 'title' })
  title!: string;
}
