import { JsonApiModelConfig } from '@elearnio/angular2-jsonapi';

import { EmailTemplate } from './email-template.model';
import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'system_templates',
})
export class SystemEmailTemplate extends EmailTemplate {}
