const s3BucketBaseUrl =
  'https://s3.eu-central-1.amazonaws.com/dev-elearnio-net-company-storage';
const baseSubdomain = 'dev';
let subdomain = '';

export const environment = {
  production: true,
  development: true,
  localRunner: false,

  defaultBrandColor: '#57A7B5',
  defaultAccentColor: '#C7E4EA',

  pollingIntervalInSeconds: 60,

  // Internal services
  backendBaseUrl: getBackendBaseUrl(),
  identityManagerBaseUrl: getIdentityManagerBaseUrl(),
  enqueueActivityEventEndpoint: `${getBackendBaseUrl()}/api/v1/activity_answers`,
  s3BucketBaseUrl,

  // h5p
  h5pBaseUrl: getH5PBaseUrl(),
  h5pSaveFrequencyInMinutes: false,

  // requests without credentials
  withoutCredentialUrls: [s3BucketBaseUrl],

  // External services
  googleTagManagerId: 'GTM-MNJ7FFM',
  sentryErrorHandlerDsn:
    'https://b9a74946bcca48e2bef52a86db925c61@o378142.ingest.sentry.io/5201122',

  // subdomains
  domain: `${baseSubdomain}.elearnio.net`,
  baseSubdomain,
};

function setSubDomain(): void {
  const currentSubdomain = window.location.hostname.split('.')[0];

  if (baseSubdomain !== currentSubdomain) {
    subdomain = currentSubdomain;
  }
}

function getBackendBaseUrl(): string {
  return getFullDomainUrl() + 'backend';
}

function getIdentityManagerBaseUrl(): string {
  return getFullDomainUrl() + 'identity-manager';
}

function getH5PBaseUrl(): string {
  return getFullDomainUrl() + 'h5p-service';
}

function getFullDomainUrl(): string {
  if (!subdomain) {
    setSubDomain();
  }
  if (!subdomain) {
    return 'https://dev.elearnio.net/';
  }
  return `https://${subdomain}.dev.elearnio.net/`;
}
