import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackBarService } from '@services/snack-bar.service';
import { SnackBarComponent } from '@shared/snack-bar/snack-bar.component';

export const declarations = [SnackBarComponent];

export const imports = [MatIconModule, MatSnackBarModule];

export const providers = [SnackBarService];

@NgModule({
  declarations,
  imports: [CommonModule, ...imports],
  providers,
  exports: declarations,
})
export class SnackBarModule {}
