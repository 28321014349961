import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';

import { HeaderComponent } from './header.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { NgAnimatedCounterModule } from '@bugsplat/ng-animated-counter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotificationCenterModule } from '@shared/notification-center/notification-center.module';

export const declarations = [HeaderComponent];

export const imports = [
  MatButtonModule,
  MatIconModule,
  MatDividerModule,
  MatMenuModule,
  MatToolbarModule,
  TranslateModule,
  MatBadgeModule,
  NgAnimatedCounterModule,
  FlexLayoutModule,
  MatDialogModule,
  NotificationCenterModule,
];

@NgModule({
  declarations,
  imports: [CommonModule, RouterModule, ...imports],
  exports: [...declarations],
})
export class HeaderModule {}
