/* tslint:disable:no-any */
import { Injectable } from '@angular/core';
import { H5PEvent } from '@core/constants/h5p-events.constant';

export interface IH5PEventSingleResult extends IH5PEventResult {
  subContentId: string;
  answer: any;
}

export interface IH5PEventMultiResult extends IH5PEventResult {
  children: Array<IH5PEventSingleResult | IH5PEventMultiResult | null>;
}

interface IH5PEventResult {
  score: number;
  maxScore: number;
  duration: number;
  completion: boolean;
  success: boolean;
  state: string;
}

@Injectable({
  providedIn: 'root',
})
export class H5PEventService {
  private customNavigationActivities = [
    H5PEvent.VERSION.QUIZ,
    H5PEvent.VERSION.FEEDBACK,
    H5PEvent.VERSION.IMAGE_SLIDER,
  ];

  private customMobileNavigationActivities = [
    H5PEvent.VERSION.QUIZ,
    H5PEvent.VERSION.FEEDBACK,
  ];

  private allowNavigationLibraries = [H5PEvent.VERSION.CHECKLIST];

  constructor() {}

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  public parseEvent(
    event: any,
    instance: any,
  ): IH5PEventSingleResult | IH5PEventMultiResult | null {
    return this.getEventResult(event, instance);
  }

  public shouldParseEvent(event: any, instance: any): boolean {
    return (
      event.getVerb() === H5PEvent.EVENT.COMPLETED ||
      this.shouldParseAnswered(event) ||
      this.shouldParseProgressed(event, instance)
    );
  }

  public isCompletedFeedbackActivity(library: string, verb: string): boolean {
    return (
      library === H5PEvent.VERSION.FEEDBACK && verb === H5PEvent.EVENT.COMPLETED
    );
  }

  public isCompletedCustomNavigationActivity(
    library: string,
    verb: string,
  ): boolean {
    return (
      this.isCustomNavigationActivity(library) &&
      verb === H5PEvent.EVENT.COMPLETED
    );
  }

  public isShowBaseNavigationEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.BASE_NAVIGATION;
  }

  public isShowCustomNavigationEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.CUSTOM_NAVIGATION;
  }

  public isResetEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.RESET;
  }

  public isSkipEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.SKIP;
  }

  public isExitEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.EXIT;
  }

  public isEnableNavigationEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.ENABLE_NAVIGATION;
  }

  public isDisableNavigationEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.DISABLE_NAVIGATION;
  }

  public showScrollIconEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.ENABLE_SCROLL_INDICATOR;
  }

  public hideScrollIconEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.DISABLE_SCROLL_INDICATOR;
  }

  public showTimerEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.ENABLE_TIMER;
  }

  public hideTimerEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.DISABLE_TIMER;
  }

  public isNextEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.NEXT;
  }

  public isPrevEvent(event: any): boolean {
    return event.getVerb() === H5PEvent.EVENT.PREV;
  }

  public isCustomNavigationActivity(
    library: string,
    isMobile = false,
  ): boolean {
    return isMobile
      ? this.customMobileNavigationActivities.some(a => library === a)
      : this.customNavigationActivities.some(a => library === a);
  }

  public allowNavigation(versionedLibraryName: string): boolean {
    return this.allowNavigationLibraries.some(a => versionedLibraryName === a);
  }

  public isAnsweredTaskActivity(
    versionedLibraryName: string,
    instance: any,
  ): boolean {
    return (
      !!instance &&
      versionedLibraryName === H5PEvent.VERSION.TASK &&
      (instance.textValue || instance.file?.path)
    );
  }

  public getRatingStarFeedback(instance: any): any {
    const ratingStar = instance.getXAPIData().children.find((c: any) => {
      const activityTypeId =
        c.statement.context.contextActivities.category[0].id;
      return activityTypeId.indexOf(H5PEvent.ID.RATING_STAR) !== -1;
    });
    return (
      ratingStar &&
      ratingStar.statement &&
      ratingStar.statement.result &&
      ratingStar.statement.result.score &&
      ratingStar.statement.result.score.raw
    );
  }

  /* eslint-enable @typescript-eslint/explicit-module-boundary-types */

  private parseParentWithChildrenEvent(
    statement: any,
    instance: any,
  ): IH5PEventMultiResult {
    const eventResult = statement.result;
    const children: Array<IH5PEventSingleResult | IH5PEventMultiResult | null> =
      [];

    instance.getXAPIData().children.forEach((c: any, index: number) => {
      const activityTypeId =
        c.statement.context.contextActivities.category[0].id;
      if (activityTypeId) {
        children.push(
          this.mapEvent(
            activityTypeId,
            c.statement,
            instance.getQuestions()[index],
          ),
        );
      }
    });

    const currentState = instance.getCurrentState();

    return {
      score: eventResult?.score.raw,
      maxScore: eventResult?.score.max,
      duration: this.calcDuration(eventResult?.duration),
      completion: eventResult?.completion,
      success: eventResult?.success,
      children,
      state: JSON.stringify(currentState),
    };
  }

  private parseMemoryGameEvent(
    statement: any,
    state: any,
  ): IH5PEventSingleResult {
    const eventResult = statement.result;
    const subContentId: string =
      statement.object.id?.split('subContentId=').pop() || '';

    return {
      subContentId,
      answer: '',
      score: eventResult.score.raw,
      maxScore: eventResult.score.max,
      duration: this.calcDuration(eventResult.duration),
      completion: eventResult.completion,
      success: eventResult.success,
      state: JSON.stringify(state),
    };
  }

  private parseResponseEvent(
    statement: any,
    state: any,
  ): IH5PEventSingleResult {
    return this.parseCommonSingleEvent(
      statement,
      state,
      statement.result.score.raw === statement.result.score.max,
    );
  }

  private parseCoursePresentation(instance: any): IH5PEventSingleResult {
    const duration =
      Math.round((Date.now() - instance.activityStartTime) / 10) / 100;

    return {
      subContentId: '',
      answer: '',
      score: instance.slides.length,
      maxScore: instance.slides.length,
      duration,
      completion: true,
      success: true,
      state: JSON.stringify(instance.getCurrentState()),
    };
  }

  private parseHotspotSearchEvent(
    statement: any,
    state: any,
  ): IH5PEventSingleResult {
    return this.parseCommonSingleEvent(
      statement,
      state,
      statement.result.score.raw === statement.result.score.max,
    );
  }

  private parseSimpleEvent(statement: any, state: any): IH5PEventSingleResult {
    const eventResult = statement.result;
    return this.parseCommonSingleEvent(statement, state, eventResult.success);
  }

  private parseCommonSingleEvent(
    statement: any,
    state: any,
    success: boolean,
  ): IH5PEventSingleResult {
    const eventResult = statement.result;
    const subContentId: string =
      statement.object.id?.split('subContentId=').pop() || '';

    return {
      subContentId,
      answer: eventResult.response ? eventResult.response : '',
      score: eventResult.score.raw,
      maxScore: eventResult.score.max,
      duration: this.calcDuration(eventResult.duration),
      completion: eventResult.completion,
      success,
      state: JSON.stringify(state),
    };
  }

  private parseInteractiveVideoEvent(
    statement: any,
    instance: any,
  ): IH5PEventMultiResult {
    const eventResult = statement.result;
    const children: Array<IH5PEventSingleResult | IH5PEventMultiResult | null> =
      [];

    instance.getXAPIData().children.forEach((c: any) => {
      const activityTypeId =
        c.statement.context.contextActivities.category[0].id;
      if (activityTypeId) {
        const childInstance = instance.interactions.find(
          (i: any) =>
            i.getXAPIData()?.statement.object.id === c.statement.object.id,
        );

        children.push(
          this.mapEvent(activityTypeId, c.statement, childInstance),
        );
      }
    });

    return {
      score: eventResult.score.raw,
      maxScore: eventResult.score.max,
      duration: this.calcDuration(eventResult.duration),
      completion: eventResult.completion,
      success: eventResult.success,
      children,
      state: JSON.stringify(instance.getCurrentState()),
    };
  }

  private getEventResult(
    event: any,
    instance: any,
  ): IH5PEventSingleResult | IH5PEventMultiResult | null {
    const activityTypeId: string | null = this.getActivityId(event, 'category');
    const activityParentType: string | null = this.getActivityId(
      event,
      'parent',
    );

    if (activityParentType) {
      return this.mapEvent(
        activityParentType,
        instance.parent.getXAPIData().statement,
        instance.parent,
      );
    }

    if (activityTypeId) {
      return this.mapEvent(activityTypeId, event.data.statement, instance);
    }

    return null;
  }

  private mapEvent(
    activityTypeId: string,
    statement: any,
    instance: any,
  ): IH5PEventSingleResult | IH5PEventMultiResult | null {
    if (activityTypeId.indexOf(H5PEvent.ID.QUIZ) !== -1) {
      return this.parseParentWithChildrenEvent(statement, instance);
    }

    if (activityTypeId.indexOf(H5PEvent.ID.FEEDBACK) !== -1) {
      return this.parseParentWithChildrenEvent(statement, instance);
    }

    if (activityTypeId.indexOf(H5PEvent.ID.MEMORY_GAME) !== -1) {
      return this.parseMemoryGameEvent(statement, instance.getCurrentState());
    }

    if (
      activityTypeId.indexOf(H5PEvent.ID.IMAGE_HOTSPOT_QUESTION) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.IMAGE_HOTSPOT_VIDEO_QUESTION) !== -1
    ) {
      return this.parseHotspotSearchEvent(
        statement,
        instance.getCurrentState(),
      );
    }

    if (
      activityTypeId.indexOf(H5PEvent.ID.ESSAY) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.QUESTION_TEXT) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.TRUE_FALSE) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.MULTI_CHOICE) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.MARK_WORDS) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.CHECKLIST) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.SCORM) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.DRAG_DROP) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.IMAGE_SLIDER) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.TASK) !== -1
    ) {
      return this.parseCommonSingleEvent(
        statement,
        instance.getCurrentState(),
        statement.result.success,
      );
    }

    if (activityTypeId.indexOf(H5PEvent.ID.INTERACTIVE_VIDEO) !== -1) {
      return this.parseInteractiveVideoEvent(statement, instance);
    }

    if (
      activityTypeId.indexOf(H5PEvent.ID.AUDIO) !== -1 ||
      activityTypeId.indexOf(H5PEvent.ID.LINK) !== -1
    ) {
      return this.parseSimpleEvent(statement, instance.getCurrentState());
    }

    if (activityTypeId.indexOf(H5PEvent.ID.COURSE_PRESENTATION) !== -1) {
      return this.parseCoursePresentation(instance);
    }

    if (activityTypeId.indexOf(H5PEvent.ID.DRAG_TEXT) !== -1) {
      const passPercentage =
        instance.params?.dragWords?.behaviour?.passPercentage || 100;
      return this.parseCommonSingleEvent(
        statement,
        instance.getCurrentState(),
        passPercentage <=
          (statement.result.score.raw * 100) / statement.result.score.max,
      );
    }

    if (activityTypeId.indexOf(H5PEvent.ID.FILL_BLANKS) !== -1) {
      const passPercentage =
        instance.params?.blanks?.behaviour?.passPercentage || 100;
      return this.parseCommonSingleEvent(
        statement,
        instance.getCurrentState(),
        passPercentage <=
          (statement.result.score.raw * 100) / statement.result.score.max,
      );
    }

    if (this.isCommonEvent(activityTypeId)) {
      return this.parseResponseEvent(statement, instance.getCurrentState());
    }

    return null;
  }

  private calcDuration(duration: string): number {
    if (!duration) {
      return -1;
    }

    const durationMatch = duration.match(/[+-]?\d+(\.\d+)?/g);
    if (durationMatch && durationMatch.length) {
      return parseInt(durationMatch[0], 10);
    }

    return -1;
  }

  private shouldParseAnswered(event: any): boolean {
    const activityType: string | null = this.getActivityId(event, 'category');

    return (
      event.getVerb() === H5PEvent.EVENT.ANSWERED &&
      !!activityType &&
      this.isAnsweredEvent(activityType)
    );
  }

  private shouldParseProgressed(event: any, instance: any): boolean {
    const activityType: string | null = this.getActivityId(event, 'category');

    return (
      event.getVerb() === H5PEvent.EVENT.PROGRESSED &&
      !!activityType &&
      this.isCoursePresentationEnd(activityType, event, instance)
    );
  }

  private isCoursePresentationEnd(
    activityType: string,
    event: any,
    instance: any,
  ): boolean {
    const endingPoint = event.getVerifiedStatementValue([
      'object',
      'definition',
      'extensions',
      'http://id.tincanapi.com/extension/ending-point',
    ]);
    return (
      activityType.indexOf(H5PEvent.ID.COURSE_PRESENTATION) !== -1 &&
      endingPoint + 1 >= instance.slides.length
    );
  }

  private getActivityId(event: any, prop: string): string | null {
    return event.getVerifiedStatementValue([
      'context',
      'contextActivities',
      prop,
      0,
      'id',
    ]);
  }

  private isCommonEvent(activityTypeId: string): boolean {
    return [
      H5PEvent.ID.CONTENT_PAGE,
      H5PEvent.ID.DIALOG_CARDS,
      H5PEvent.ID.FILE,
      H5PEvent.ID.FLASHCARDS,
      H5PEvent.ID.IMAGE_HOTSPOTS,
      H5PEvent.ID.RATING_NPS,
      H5PEvent.ID.RATING_STAR,
      H5PEvent.ID.TIMELINE,
      H5PEvent.ID.SLIDES,
    ].some(a => activityTypeId.indexOf(a) !== -1);
  }

  private isAnsweredEvent(activityTypeId: string): boolean {
    return [
      H5PEvent.ID.CHECKLIST,
      H5PEvent.ID.DRAG_DROP,
      H5PEvent.ID.DRAG_TEXT,
      H5PEvent.ID.FILL_BLANKS,
      H5PEvent.ID.FLASHCARDS,
      H5PEvent.ID.ESSAY,
      H5PEvent.ID.IMAGE_HOTSPOT_VIDEO_QUESTION,
      H5PEvent.ID.MARK_WORDS,
      H5PEvent.ID.QUESTION_TEXT,
      H5PEvent.ID.RATING_NPS,
      H5PEvent.ID.RATING_STAR,
      H5PEvent.ID.MULTI_CHOICE,
      H5PEvent.ID.TRUE_FALSE,
      H5PEvent.ID.IMAGE_HOTSPOT_QUESTION,
      H5PEvent.ID.TASK,
    ].some(a => activityTypeId.indexOf(a) !== -1);
  }
}
/* tslint:enable:no-any */
