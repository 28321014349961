import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';
import { Course } from '@models/course.model';
import { CourseReminderType } from '@models/course/course-reminder-types.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'reminders',
})
export class CourseReminder extends JsonApiModel {
  @Attribute()
  time!: number;

  @Attribute({ serializedName: 'time_unit' })
  timeUnit!: string;

  @Attribute({ serializedName: 'reminder_scope' })
  reminderScope!: string;

  @BelongsTo()
  course!: Course;

  @HasMany({ key: 'reminder_types' })
  ReminderTypes!: CourseReminderType[];
}
