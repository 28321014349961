<div [ngClass]="type === 'courses' ? 'courses' : (type === 'ilt-courses' ? 'ilt-courses' : 'learning-paths')">
  <div *ngIf="type === 'ilt-courses' ? isNew && !isInProgress : isNew" class="new status">
    {{ isNewText ? isNewText : ('common.new' | translate | lowercase) }}
  </div>
  <div *ngIf="isInProgress" class="in-progress status">
    {{ isInProgressText ? isInProgressText : 'courses.status.running' | translate }}
  </div>
  <div
    *ngIf="isFinished"
    class="completed status"
    [ngClass]="{ 'failed': isFailed, 'completed': !isFailed }"
  >
    {{ isFinishedText ? isFinishedText : (isFailed ? 'courses.status.failed' : 'courses.status.finished') | translate }}
  </div>
</div>
