import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import { TrainingScheduleCohort } from './training-schedule-cohort.model';
import { TrainingSchedule } from './training-schedule.model';
import { Member } from '../member.model';

@JsonApiModelConfig({
  type: 'cohort_members',
})
export class TrainingScheduleCohortMember extends JsonApiModel {
  @Attribute({ serializedName: 'id' })
  cohortMemberId!: number;

  @Attribute({ serializedName: 'cohort_id' })
  cohortId!: number;

  @Attribute({ serializedName: 'member_id' })
  memberId!: number;

  @Attribute({ serializedName: 'orga_unit_id' })
  orgaUnitId!: number;

  @Attribute({ serializedName: 'orga_level_number' })
  orgaLevel!: number;

  @Attribute({ serializedName: 'orga_name' })
  orgaName!: string;

  @Attribute({ serializedName: 'orga_full_path' })
  orgaFullPath!: string;

  @Attribute({ serializedName: 'progress' })
  progress!: string;

  @Attribute({ serializedName: 'status' })
  status!: string;

  @BelongsTo()
  cohort!: TrainingScheduleCohort;

  @BelongsTo()
  schedule!: TrainingSchedule;

  @BelongsTo()
  member!: Member;
}
