import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputErrorComponent } from '@shared/error/components/form/input-error';

@NgModule({
  declarations: [InputErrorComponent],
  imports: [CommonModule],
  exports: [InputErrorComponent],
})
export class ErrorModule {}
