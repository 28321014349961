<div class="times-up-dialog">
  <section ngClass.gt-sm="large">
    <h2 mat-dialog-title>
      {{ 'modals.timesUp' | translate }}
    </h2>

    <div class="warning-body">
      <img
        class="quiz-time-up"
        routerLink=""
        [src]="quizTimeUp"
      />

      <app-info-bubble
        class="width-100"
        [type]="InfoBubbleType.Info"
        [message]="'modals.timerWarning' | translate">
      </app-info-bubble>
    </div>

    <mat-toolbar
      fxLayout="row"
      fxLayoutAlign="flex-end flex-end"
      fxLayoutGap="20px"
      >
      <button 
        mat-stroked-button 
        color="yellow"
        class="no-border filled"
        mat-dialog-close
      >
        {{ 'modals.endQuiz' | translate }}
      </button>
    </mat-toolbar>
  </section>
</div>