import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';

// Pages
import { routes } from './app.routes';

import { AuthGuard } from '@guards/auth.guard';
import { RoleGuard } from '@guards/role.guard';
import { TalentsGuard } from '@guards/talents.guard';
import { ReportingGuard } from '@guards/reporting.guard';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  enableTracing: false,
  scrollOffset: [0, 64],
  scrollPositionRestoration: 'enabled',
  useHash: true,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  providers: [AuthGuard, RoleGuard, TalentsGuard, ReportingGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
