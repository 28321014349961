import { Time12To24Pipe } from '@pipes/time-12-to-24/time-12-to-24.pipe';

export interface ScheduleTime {
  hours: string;
  minutes: string;
}

export function getTrainingScheduleTimeString(time: string): string {
  if (!time) {
    return '';
  }

  const parsedTime = getTrainingScheduleTime(time);
  if (!parsedTime) {
    return '';
  }

  return `${parsedTime.hours}:${parsedTime.minutes}`;
}

export function getTrainingScheduleTime(time: string): ScheduleTime | null {
  if (!time) {
    return null;
  }

  let scheduleTime = time;
  if (time.length > 6) {
    // is AM/PM from BE
    scheduleTime = new Time12To24Pipe().transform(time);
  }

  return {
    hours: scheduleTime.substring(0, 2),
    minutes: scheduleTime.substring(3, 5),
  };
}
