import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import * as dayjs from 'dayjs';

import { CourseReminder } from '@models/course/course-reminder.model';
import { ILTCourseAssignment } from './ilt-course-assignment.model';
import { ILTCourseEvent } from './ilt-course/ilt-course-event.model';
import { Member } from '@models/member.model';
import { ILTCourseMemberResult } from './ilt-course/member/ilt-course-member-result.model';
import { CourseCategory } from './course-category.model';
import { OrganizationLevelIltCourse } from './organization/organization-level-ilt-course.model';
import { VisibilityPermission } from './organization/visibility-permission.model';
import { ILTCoursesMember } from './ilt-course/member/ilt-course-member.model';

@JsonApiModelConfig({
  type: 'ilt_courses',
})
export class ILTCourse extends JsonApiModel {
  @Attribute()
  description!: string;

  @Attribute()
  language!: string;

  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'certificate_template_id' })
  certificateId!: number | null;

  @Attribute({ serializedName: 'completion_level' })
  completionLevel!: number;

  @Attribute({ serializedName: 'gamification_points' })
  gamificationPoints!: number;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute({ serializedName: 'cover_image' })
  coverImage!: string | null;

  @Attribute({ serializedName: 'self_assignment' })
  selfAssignment!: boolean;

  @Attribute({ serializedName: 'last_canceled_assignment_self_assigned?' })
  lastAssignmentSelfAssigned!: boolean;

  @Attribute({ serializedName: 'duration_in_hours' })
  durationInHours!: number;

  @Attribute({ serializedName: 'net_time_in_minutes' })
  netTimeInMinutes!: number;

  @Attribute({ serializedName: 'training_editors_count' })
  trainingEditorCount!: number;

  @Attribute({ serializedName: 'self_assignment_approval' })
  selfAssignmentApproval!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_group_leader' })
  selfAssignmentApprovalGroupLeader!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_manager' })
  selfAssignmentApprovalManager!: boolean;

  @Attribute({ serializedName: 'self_assignment_approval_author' })
  selfAssignmentApprovalAuthor!: boolean;

  @Attribute({ serializedName: 'author_id' })
  authorId!: string;

  @Attribute({ serializedName: 'price_enabled' })
  priceEnabled!: boolean;

  @Attribute({ serializedName: 'price' })
  price!: number;

  @BelongsTo()
  author!: Member;

  @HasMany({ key: 'course_categories' })
  courseCategories!: CourseCategory[];

  @HasMany({ key: 'reminders' })
  courseReminders!: CourseReminder[];

  @HasMany({ key: 'ilt_course_assignments' })
  iltCourseAssignments!: ILTCourseAssignment[];

  @HasMany({ key: 'events' })
  iltCourseEvents!: ILTCourseEvent[];

  @HasMany({ key: 'organisation_levels_ilt_courses' })
  organizationLevelIltCourses!: OrganizationLevelIltCourse[];

  @HasMany({ key: 'visibility_permissions' })
  visibilityPermissions!: VisibilityPermission[];

  @HasMany({ key: 'ilt_courses_member_results' })
  courseResults!: ILTCourseMemberResult[];

  @HasMany({ key: 'editors' })
  editors!: Member[];

  iltCoursesMember: ILTCoursesMember | undefined;
  courseResult: ILTCourseMemberResult | undefined;

  public get hasFutureEvent(): boolean {
    return !!this.iltCourseEvents?.some(event => {
      const date = dayjs(event.startDate).format('YYYY-MM-DD');
      const currentDay = dayjs();
      return currentDay.isBefore(date, 'day') || currentDay.isSame(date);
    });
  }
}
