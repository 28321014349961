export const H5PEvent = {
  ID: {
    AUDIO: 'http://h5p.org/libraries/H5P.Audio',
    CHECKLIST: 'http://h5p.org/libraries/H5P.Checklist',
    CONTENT_PAGE: 'http://h5p.org/libraries/H5P.ContentPage',
    COURSE_PRESENTATION: 'http://h5p.org/libraries/H5P.CoursePresentation',
    DIALOG_CARDS: 'http://h5p.org/libraries/H5P.Dialogcards',
    DRAG_DROP: 'http://h5p.org/libraries/H5P.DragQuestion',
    DRAG_TEXT: 'http://h5p.org/libraries/H5P.DragText',
    ESSAY: 'http://h5p.org/libraries/H5P.Essay',
    FEEDBACK: 'http://h5p.org/libraries/H5P.Feedback',
    FILE: 'http://h5p.org/libraries/H5P.File',
    FLASHCARDS: 'http://h5p.org/libraries/H5P.Flashcards',
    FILL_BLANKS: 'http://h5p.org/libraries/H5P.Blanks',
    IMAGE_SLIDER: 'http://h5p.org/libraries/H5P.ImageSlider-1.0',
    IMAGE_HOTSPOT_QUESTION: 'http://h5p.org/libraries/H5P.ImageHotspotQuestion',
    IMAGE_HOTSPOT_VIDEO_QUESTION:
      'http://h5p.org/libraries/H5P.ImageHotspotVideoQuestion',
    IMAGE_HOTSPOTS: 'http://h5p.org/libraries/H5P.ImageHotspots',
    INTERACTIVE_VIDEO: 'http://h5p.org/libraries/H5P.InteractiveVideo',
    LINK: 'http://h5p.org/libraries/H5P.Link',
    MARK_WORDS: 'http://h5p.org/libraries/H5P.MarkTheWords',
    MEMORY_GAME: 'http://h5p.org/libraries/H5P.MemoryGame',
    MULTI_CHOICE: 'http://h5p.org/libraries/H5P.MultiChoice',
    QUIZ: 'http://h5p.org/libraries/H5P.QuestionSet',
    QUESTION_TEXT: 'http://h5p.org/libraries/H5P.QuestionText',
    RATING_NPS: 'http://h5p.org/libraries/H5P.RatingNPS',
    RATING_STAR: 'http://h5p.org/libraries/H5P.RatingStar',
    SCORM: 'http://h5p.org/libraries/H5P.Scorm-1.0',
    SLIDES: 'http://h5p.org/libraries/H5P.Slides',
    TASK: 'http://h5p.org/libraries/H5P.Task-1.0',
    TIMELINE: 'http://h5p.org/libraries/H5P.Timeline',
    TRUE_FALSE: 'http://h5p.org/libraries/H5P.TrueFalse',
  },
  EVENT: {
    ANSWERED: 'answered',
    ATTEMPTED: 'attempted',
    COMPLETED: 'completed',
    INTERACTED: 'interacted',
    PROGRESSED: 'progressed',
    BASE_NAVIGATION: 'show-base-navigation',
    CUSTOM_NAVIGATION: 'show-custom-navigation',
    RESET: 'reset',
    SKIP: 'skip',
    EXIT: 'exit',
    NEXT: 'next',
    PREV: 'prev',
    ENABLE_NAVIGATION: 'enable-navigation',
    DISABLE_NAVIGATION: 'disable-navigation',
    ENABLE_SCROLL_INDICATOR: 'show-scroll-icon',
    DISABLE_SCROLL_INDICATOR: 'hide-scroll-icon',
    ENABLE_TIMER: 'show-timer',
    DISABLE_TIMER: 'hide-timer',
  },
  VERSION: {
    AUDIO: 'H5P.Audio 1.4',
    CHECKLIST: 'H5P.Checklist 1.0',
    CONTENT_PAGE: 'H5P.ContentPage 1.0',
    DIALOG_CARDS: 'H5P.Dialogcards 1.8',
    FEEDBACK: 'H5P.Feedback 1.0',
    FILE: 'H5P.File 1.0',
    IMAGE: 'H5P.Image 1.1',
    VIDEO: 'H5P.InteractiveVideo 1.21',
    IMAGE_HOTSPOTS: 'H5P.ImageHotspots 1.8',
    IMAGE_SLIDER: 'H5P.ImageSlider 1.0',
    IMAGE_HOTSPOT_QUESTION: 'H5P.ImageHotspotQuestion 1.8',
    IMAGE_HOTSPOT_QUESTION_SUBACTIVITY:
      'H5P.ImageHotspotQuestionSubactivity 1.8',
    DRAG_DROP: 'H5P.DragQuestion 1.14',
    DRAG_DROP_SUBACTIVITY: 'H5P.DragQuestionSubactivity 1.14',
    QUIZ: 'H5P.QuestionSet 1.17',
    SCORM: 'H5P.Scorm 1.0',
    SLIDES: 'H5P.Slides 1.0',
    TEXT: 'H5P.Text 1.1',
    VIDEO_SUBACTIVITY: 'H5P.Video 1.5',
    IMAGE_HOTSPOT_VIDEO_QUESTION: 'H5P.ImageHotspotVideoQuestion 1.0',
    MEMORY_GAME: 'H5P.MemoryGame 1.3',
    DRAG_TEXT: 'H5P.DragText 1.8',
    MULTICHOICE: 'H5P.MultiChoice 1.14',
    TRUE_FALSE: 'H5P.TrueFalse 1.6',
    TASK: 'H5P.Task 1.0',
  },
};
