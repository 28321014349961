import { MultiSelectPosition } from '@shared/multi-select-server-side-search/interfaces';

export const multiSelectCenterBottomPosition: MultiSelectPosition = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'center',
  overlayY: 'bottom',
};

export const multiSelectStartTopPosition: MultiSelectPosition = {
  originX: 'start',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'top',
};

export const multiSelectCenterBottomTopPosition: MultiSelectPosition = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'center',
  overlayY: 'top',
};
