import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );

  constructor(private authService: AuthService) {}

  private static addAuthCredentialsToRequest(request: HttpRequest<any>) {
    const httpHeaders = {};

    // Exception for form-data sending
    if (!request.headers.has('x-is-form-data')) {
      Object.assign(httpHeaders, {
        'Content-Type': 'application/vnd.api+json',
      });
    }

    if (request.headers.has('Microsoft-Token')) {
      // TODO Investigate line 28 and refactor
      Object.assign(httpHeaders, {
        'Microsoft-Token': request.headers.get('Microsoft-Token'),
      });
    }

    // Is request without credentials
    const withoutCredentials = environment.withoutCredentialUrls.find(
      url => request.url.indexOf(url) !== -1,
    );
    if (withoutCredentials) {
      if (request.body && request.body instanceof File) {
        Object.assign(httpHeaders, {
          'Content-Type': request.body.type,
        });
      }
      return request.clone({
        headers: new HttpHeaders(httpHeaders),
        withCredentials: false,
      });
    }

    return request.clone({
      headers: new HttpHeaders(httpHeaders),
      withCredentials: true,
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // dont process assets
    if (request.url.indexOf('assets/') > -1) {
      return next.handle(request);
    }

    // dont inject on login
    if (request.url.indexOf('users/login') > -1) {
      return next.handle(request);
    }

    request = AuthInterceptor.addAuthCredentialsToRequest(request);

    // dont handle refresh_token on refresh_token 401
    if (request.url.indexOf('token_refresh') > -1) {
      return next.handle(request);
    }

    // dont handle 401 with refresh_token if we dont have a token
    // -> (or token info cookie)
    if (!this.authService.hasAuthCookie()) {
      return next.handle(request);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() =>
          next.handle(AuthInterceptor.addAuthCredentialsToRequest(request)),
        ),
      );
    }

    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        this.isRefreshing = false;
        // dummy value - token is set by secure http only cookie
        this.refreshTokenSubject.next(1);
        return next.handle(
          AuthInterceptor.addAuthCredentialsToRequest(request),
        );
      }),
    );
  }
}
