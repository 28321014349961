import { ILTCourse } from '@models/ilt-course.model';

export enum ILTCourseStatus {
  assigned = 'assigned',
  subscribed = 'subscribed',
  completed = 'completed',
}

export function filterByStatus(
  iltCourses: ILTCourse[],
  status: ILTCourseStatus,
): ILTCourse[] {
  switch (status) {
    case ILTCourseStatus.assigned:
      return iltCourses.filter(
        course =>
          !course.courseResult?.completionDate &&
          !course.iltCourseEvents?.some(e => e.assignedTo && !e.isOldEvent),
      );
    case ILTCourseStatus.subscribed:
      return iltCourses.filter(
        course =>
          !course.courseResult?.completionDate &&
          course.iltCourseEvents?.some(e => e.assignedTo && !e.isOldEvent),
      );
    case ILTCourseStatus.completed:
      return iltCourses.filter(course => !!course.courseResult?.completionDate);
  }
}

export function filterByStatuses(
  courses: ILTCourse[],
  statuses: ILTCourseStatus[],
): ILTCourse[] {
  const reducer = (accumulator: ILTCourse[], status: ILTCourseStatus) =>
    accumulator.concat(filterByStatus(courses, status));

  return statuses.reduce(reducer, []);
}
