/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { LearningPath } from '../../learning-path.model';
import { Member } from '../../member.model';
import { Certificate } from '@models/template/certificate.model';

@JsonApiModelConfig({
  type: 'learning_path_results',
})
export class LearningPathMemberResult extends JsonApiModel {
  @Attribute({ serializedName: 'learning_path_id' })
  learningPathId!: string;

  @Attribute({ serializedName: 'member_id' })
  memberId!: string;

  @Attribute()
  status!: string;

  @Attribute()
  progress!: number;

  @Attribute()
  deadline!: Date;

  @BelongsTo()
  member!: Member;

  @BelongsTo()
  learningPath!: LearningPath;

  @BelongsTo()
  certificate!: Certificate;
}
