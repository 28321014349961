import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { PhysicalLocation } from '../physical-location.model';
import { VirtualLocation } from '../virtual-location.model';
import { Member } from '../member.model';
import { ILTCourse } from '../ilt-course.model';

@JsonApiModelConfig({
  type: 'scheduled_ilt_courses',
})
export class TrainingScheduleIltCourse extends JsonApiModel {
  @Attribute()
  description!: string;

  @Attribute({ serializedName: 'event_title' })
  eventTitle!: string;

  @Attribute({ serializedName: 'duration_in_minutes' })
  durationInMinutes!: number;

  @Attribute({ serializedName: 'organizer' })
  eventOrganizer!: string;

  @Attribute({ serializedName: 'host_id' })
  hostId!: string;

  @BelongsTo()
  host!: Member;

  @HasMany({ key: 'instructors' })
  instructors!: Member[];

  @Attribute({ serializedName: 'ilt_course_id' })
  iltCourseId!: number;

  @BelongsTo({ key: 'ilt_course' })
  iltCourse!: ILTCourse;

  @BelongsTo({ key: 'physical_location' })
  physicalLocation!: PhysicalLocation | null | undefined;

  @BelongsTo({ key: 'virtual_location' })
  virtualLocation!: VirtualLocation | null | undefined;
}
