<div class="h5p-player" [ngClass]="{ 'course-completed': course?.courseResult?.progress === 100 }" [class.mobile]="mobileQuery.matches">
  <app-h5p-player-header
    [courseTitle]="course?.name || ''"
    [progress]="progress"
    [hasNext]="hasNext || adminPreview"
    [hasPrevious]="hasPrevious || adminPreview"
    [isLastActivity]="isLastActivity"
    [adminPreview]="adminPreview"
    [isPreview]="isPreview"
    [courseId]="courseId"
    [learningPathId]="learningPathId"
    [showCourseNavigation]="showCourseNavigation"
    (previous)="previous()"
    (next)="next()"
  >
  </app-h5p-player-header>
  <app-h5p-player-timer-component
    *ngIf="displayTimer"
    [durationInMinutes] = "durationInMinutes"
    (timerZero) = "timerZero()"
  >
  </app-h5p-player-timer-component>
  <div
    class="h5p-player-container"
    ngClass.gt-md="medium-screen"
    ngClass.gt-lg="large-screen"
  >
    <mat-spinner *ngIf="displaySpinner"></mat-spinner>
    <div #h5pContent class="h5p-content notranslate" translate="no" [class.d-hide]="displaySpinner">
    </div>
    <div *ngIf="mobileQuery.matches" [class.hide]="!showIcon" id="h5p-scroll-fade-footer" class="h5p-scroll-fade-footer">
      <div class="h5p-scroll-indicator" (click)="scrollToBottom()">
        <mat-icon class="h5p-scroll-indicator-icon size-sm">keyboard_double_arrow_down</mat-icon>
      </div>
      <div *ngIf="showFade" class="h5p-container-fade"></div>
    </div>
  </div>
  <app-h5p-player-footer
    *ngIf="showCourseNavigation"
    [hasNext]="hasNext"
    [hasPrevious]="hasPrevious"
    [isLastActivity]="isLastActivity"
    (previous)="previous()"
    (next)="next()"
  >
  </app-h5p-player-footer>
</div>
