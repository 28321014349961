import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'certificate_templates',
})
export class CertificateTemplate extends JsonApiModel {
  @Attribute()
  name!: string;

  @Attribute()
  language!: string;

  @Attribute()
  html!: string;

  @Attribute({ serializedName: 'top_offset' })
  topOffset!: number;

  @Attribute({ serializedName: 'left_offset' })
  leftOffset!: number;

  @Attribute({ serializedName: 'background_image' })
  backgroundImage!: string;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;
}
