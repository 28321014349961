import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';
import { CourseReminder } from '@models/course/course-reminder.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'reminder_types',
})
export class CourseReminderType extends JsonApiModel {
  @Attribute({ serializedName: 'reminder_type' })
  reminderType!: string;

  @BelongsTo()
  reminder!: CourseReminder;
}
