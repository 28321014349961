import { AuthService } from '@services/auth.service';
import { take } from 'rxjs/operators';

export function appInitializer(authService: AuthService) {
  return (): Promise<void> =>
    new Promise(resolve => {
      if (!authService.hasAuthCookie()) {
        return resolve();
      }
      // attempt to refresh token on app start up to auto authenticate
      authService
        .refreshToken()
        .pipe(take(1))
        .subscribe(
          () => {
            resolve();
          },
          () => {
            resolve();
          },
        );
    });
}
