/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { CourseActivity } from './course/course-activity.model';
import { Course } from './course.model';
import { Member } from './member.model';
import { CourseMemberAnswer } from './course/member/course-member-answer.model';

@JsonApiModelConfig({
  type: 'task_activity_reviews',
})
export class TaskActivityReview extends JsonApiModel {
  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute({ serializedName: 'all_reviewers' })
  allReviewersJson!: string;

  @Attribute({ serializedName: 'task_type' })
  taskType!: string;

  @BelongsTo({ key: 'course_member_answer' })
  courseMemberAnswer!: CourseMemberAnswer;

  @BelongsTo({ key: 'course_activity' })
  courseActivity!: CourseActivity;

  @BelongsTo()
  member!: Member;

  @BelongsTo()
  course!: Course;

  @HasMany()
  reviewers!: Member[];

  public get type(): string {
    return this.courseActivity.content.wizard.taskDetails.taskType;
  }
}
