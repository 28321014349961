<div class="navigation-sidebar" fxLayout="column" fxLayoutAlign="space-between flex-start">
  <div class="main-links" fxLayout="column" >
    <a (click)="closeSidebar()" class="sidebar-item" [class.active]="router.isActive('my', true)"
       routerLink="/my/dashboard">
      <mat-icon>dashboard</mat-icon>
      {{ 'sections.dashboard' | translate }}</a>
    <a (click)="closeSidebar()" class="sidebar-item" routerLinkActive="active"
       [routerLink]="'/courses/my'">
      <mat-icon>school</mat-icon>
      {{ 'courses.assigned-courses' | translate }}</a>
    <a (click)="closeSidebar()" class="sidebar-item" routerLinkActive="active"
       [routerLink]="'/ilt-courses/my'">
      <mat-icon svgIcon="live_training_teacher"></mat-icon>
      {{ 'iltCourses.assignedILTCourses' | translate }}</a>
    <a (click)="closeSidebar()" class="sidebar-item" routerLinkActive="active"
       [routerLink]="'/learning-paths/my'">
      <mat-icon>route</mat-icon>
      {{ 'learningPaths.assignedLearningPaths' | translate }}</a>
    <a (click)="closeSidebar()" class="sidebar-item" routerLinkActive="active"
       [routerLink]="'/courses/catalogue'">
      <mat-icon svgIcon="course_add"></mat-icon>
      {{ 'courses.catalogue' | translate }}</a>
    <a
      *ngIf="hasTrainingScheduleFeature && hasAssignedCohort"
      routerLinkActive="active"
      [routerLink]="'/training-schedules/my'"
      class="sidebar-item"
      (click)="closeSidebar()"
    >
      <mat-icon>date_range</mat-icon>
      <span class="sidebar-item-span">{{ 'sections.trainingSchedules' | translate }}</span>
    </a>
    <h3 class="admin-area-title instructor-area" *ngIf="hasInstructorViewPermission()">{{ 'courses.sidebar.admin.instructor-area' | translate }}</h3>
    <a *ngIf="hasInstructorViewPermission()" (click)="closeSidebar()" class="sidebar-item instructor-list" routerLinkActive="active"
      [routerLink]="'/ilt-courses/instructors-list'">
      <mat-icon svgIcon="live_training_teacher"></mat-icon>
        {{ 'sections.iltCourses' | translate }}
    </a>
  </div>

  <div class="session-links" fxLayout="column">
    <a (click)="closeSidebar()" class="sidebar-item" routerLinkActive="active"
       [routerLink]="'/my/profile'">
      <mat-icon>account_circle</mat-icon>
      {{ 'common.profile' | translate }}</a>
    <a [class.hidden]="!isAdmin" (click)="openHelpCenter()" class="sidebar-item">
      <mat-icon>menu_book</mat-icon>
      {{ 'main.header.helpCenter' | translate }}
    </a>
    <a [class.hidden]="!isAdmin" (click)="openRoadmap()" class="sidebar-item">
      <mat-icon>map</mat-icon>
      {{ 'main.header.roadmap' | translate }}
    </a>
    <a (click)="openUserback()" class="sidebar-item">
      <mat-icon>bug_report</mat-icon>
      {{ 'main.header.feedback' | translate }}
    </a>
    <a (click)="logout()" class="sidebar-item sidebar-item-logout">
      <mat-icon>logout</mat-icon>
      {{ 'common.logout' | translate }}</a>
  </div>
</div>
