import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'countries',
})
export class Country extends JsonApiModel {
  @Attribute()
  name!: string;
}
