<section ngClass.gt-sm="large">
  <h2 mat-dialog-title>
    {{ 'attributes.talent.invite.title' | translate }}
  </h2>
  <div mat-dialog-content>
    <form [formGroup]="memberInviteByEmailForm" fxLayout="column" fxLayoutGap="32px">
      <div fxLayout="column" fxFlex="100%">
        <mat-form-field appearance="outline" fxFlex="grow">
          <mat-label>
            {{ 'common.emailAddresses' | translate }}
          </mat-label>
          <textarea
            formControlName="email"
            matInput
            rows="5"
          ></textarea>
          <mat-error *ngIf="memberInviteByEmailForm.get('email')?.errors?.duplicateEmail">
            {{ 'attributes.talent.invite.duplicateEmail' | translate }}
          </mat-error>
          <mat-error *ngIf="memberInviteByEmailForm.get('email')?.errors?.invalidEmail">
            {{ 'common.invalidEmail' | translate }}
          </mat-error>
          <mat-error *ngIf="memberInviteByEmailForm.get('email')?.errors?.required">
            {{ 'common.requiredField' | translate }}
          </mat-error>
        </mat-form-field>
        <span class="description">{{ 'common.emailAddressesComma' | translate }}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="32px">
        <mat-form-field appearance="outline" fxFlex="50%">
          <mat-label>
            {{ 'common.role' | translate }}
          </mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of rolesList" [value]="role">
              {{'roles.' + role | translate}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="memberInviteByEmailForm.get('email')?.errors?.required">
            {{ 'common.requiredField' | translate }}
          </mat-error>
        </mat-form-field>
        <app-multi-select-server-side-search
          fxFlex="50%"
          [className]="Group"
          [placeholder]="'sections.groups' | translate"
          [placeholderLabel]="'filters.groupsPlaceholder' | translate"
          [noEntriesFoundLabel]="'filters.noDataFound' | translate"
          [multiSelectPosition]="multiSelectPosition"
          (selectEvent)="onFilter($event)"
        ></app-multi-select-server-side-search>
      </div>
    </form>
  </div>

  <mat-toolbar
    fxLayout="row"
    fxLayoutAlign="flex-end flex-end"
    fxLayoutGap="20px"
    >
    <button
      mat-stroked-button
      color="darkblue"
      [mat-dialog-close]="false"
    >
      {{ 'common.cancel' | translate }}
    </button>

    <button
      [disabled]="memberInviteByEmailForm.invalid"
      mat-stroked-button
      color="yellow"
      class="filled no-border"
      type="submit"
      (click)="sendInvites()"
    >
      {{ 'attributes.talent.invite.sendInvites' | translate }}
    </button>
  </mat-toolbar>
</section>