/* tslint:disable:no-any */
import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'virtual_locations',
})
export class VirtualLocation extends JsonApiModel {
  @Attribute()
  link!: string;

  @Attribute()
  capacity!: number;

  @Attribute({ serializedName: 'virtual_location_type' })
  type!: string;

  @Attribute({ serializedName: 'event_day_id' })
  eventDayId!: number;
}
