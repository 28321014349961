export function type2img(type: string): string {
  let path = '/assets/images/content-types/';
  switch (type) {
    case 'Interactive video':
    case 'H5P.InteractiveVideo':
      path += 'interactive_video.svg';
      break;
    case 'Audio':
    case 'H5P.Audio':
      path += 'audio.svg';
      break;
    case 'Content page':
    case 'H5P.ContentPage':
      path += 'content_page.svg';
      break;
    case 'Checklist':
    case 'H5P.Checklist':
      path += 'checklist.svg';
      break;
    case 'Slides':
    case 'Image slider':
    case 'H5P.Slides':
    case 'H5P.ImageSlider':
      path += 'slides.svg';
      break;
    case 'Image hotspots':
    case 'H5P.ImageHotspots':
      path += 'hotspots.svg';
      break;
    case 'File':
    case 'H5P.File':
      path += 'file.svg';
      break;
    case 'H5P.Questionnaire':
      path += 'feedback.svg';
      break;
    case 'Feedback':
    case 'H5P.Feedback':
      path += 'feedback.svg';
      break;
    case 'Flashcards':
    case 'H5P.Flashcards':
      path += 'flash_cards.svg';
      break;
    case 'Link':
    case 'H5P.Link':
      path += 'link.svg';
      break;
    case 'Question set':
    case 'H5P.QuestionSet':
      path += 'quiz.svg';
      break;
    case 'H5P.MediaTask':
      path += 'media_task.svg';
      break;
    case 'Memory game':
    case 'H5P.MemoryGame':
      path += 'memory.svg';
      break;
    case 'Blanks':
    case 'H5P.Blanks':
      path += 'fill_the_blanks.svg';
      break;
    case 'Drag text':
    case 'H5P.DragText':
      path += 'drag_the_words.svg';
      break;
    case 'H5P.Essay':
      path += 'essay.svg';
      break;
    case 'Drag question':
    case 'H5P.DragQuestion':
      path += 'drag_and_drop.svg';
      break;
    case 'Image hotspot question':
    case 'H5P.ImageHotspotQuestion':
      path += 'hotspot_search.svg';
      break;
    case 'Dialogcards':
    case 'H5P.Dialogcards':
      path += 'dialogcards.svg';
      break;
    case 'H5P.Timeline':
      path += 'timeline.svg';
      break;
    case 'Scorm':
    case 'H5P.Scorm':
      path += 'scorm.svg';
      break;
    case 'Task':
    case 'H5P.Task':
      path += 'task.svg';
      break;
    default:
      path += 'quiz.svg';
  }
  return path;
}
