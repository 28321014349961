import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ElearnioLanguageSpanComponent } from './elearnio-language-span.component';
import { LanguageFlagIconPipe } from '@pipes/language-flag-icon/language-flag-icon.pipe';
import { LanguageFlagIconModule } from '@pipes/language-flag-icon/language-flag-icon.module';

export const imports = [CommonModule, LanguageFlagIconModule];

export const declarations = [ElearnioLanguageSpanComponent];

@NgModule({
  declarations,
  exports: [ElearnioLanguageSpanComponent],
  imports,
  providers: [LanguageFlagIconPipe],
})
export class ElearnioLanguageSpanModule {}
