import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Member } from '../member.model';
import { OrganizationLevel } from '@models/organization/organization-level.model';

@JsonApiModelConfig({
  type: 'level_permissions',
})
export class OrganizationLevelPermission extends JsonApiModel {
  @BelongsTo({ key: 'organisation_level' })
  organizationLevel!: OrganizationLevel;

  @BelongsTo()
  member!: Member;

  @Attribute({ serializedName: 'organisation_level_id' })
  organizationLevelId!: number;

  @Attribute({ serializedName: 'admin' })
  admin!: boolean;

  @BelongsTo({ key: 'inherited_admin_organisation_level_from' })
  adminInheritedFromOrgaLevel!: OrganizationLevel;

  @Attribute({ serializedName: 'create_training' })
  createTraining!: boolean;

  @BelongsTo({
    key: 'inherited_create_training_organisation_level_from',
  })
  createTrainingInheritedFromOrgaLevel!: OrganizationLevel;

  @Attribute({ serializedName: 'assign_training' })
  assignTraining!: boolean;

  @BelongsTo({
    key: 'inherited_assign_training_organisation_level_from',
  })
  assignTrainingInheritedFromOrgaLevel!: OrganizationLevel;

  @Attribute({ serializedName: 'complete_training' })
  completeTraining!: boolean;

  @BelongsTo({
    key: 'inherited_complete_training_organisation_level_from',
  })
  completeTrainingInheritedFromOrgaLevel!: OrganizationLevel;

  @Attribute({ serializedName: 'reset_training' })
  resetTraining!: boolean;

  @BelongsTo({
    key: 'inherited_reset_training_organisation_level_from',
  })
  resetTrainingInheritedFromOrgaLevel!: OrganizationLevel;

  @Attribute({ serializedName: 'people_management' })
  peopleManagement!: boolean;

  @BelongsTo({
    key: 'inherited_people_management_organisation_level_from',
  })
  peopleManagementInheritedFromOrgaLevel!: OrganizationLevel;

  @Attribute({ serializedName: 'reporting' })
  reporting!: boolean;

  @BelongsTo({ key: 'inherited_reporting_organisation_level_from' })
  reportingInheritedFromOrgaLevel!: OrganizationLevel;

  @Attribute({ serializedName: 'feedback_reporting' })
  feedbackReporting!: boolean;

  @BelongsTo({
    key: 'inherited_feedback_reporting_organisation_level_from',
  })
  feedbackReportingInheritedFromOrgaLevel!: OrganizationLevel;
}
