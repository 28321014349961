/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import * as dayjs from 'dayjs';

import { ILTCourse } from '../ilt-course.model';
import { ILTCourseEventAssignment } from './ilt-course-event-assignment.model';
import { ILTCourseEventDay } from './ilt-course-event-day.model';
import { Member } from '../member.model';

@JsonApiModelConfig({
  type: 'events',
})
export class ILTCourseEvent extends JsonApiModel {
  @Attribute({ serializedName: 'ilt_course_id' })
  iltCourseId!: string;

  @Attribute({ serializedName: 'host_id' })
  hostId!: string;

  @Attribute({ serializedName: 'start_date' })
  startDate!: Date;

  @Attribute({ serializedName: 'end_date' })
  endDate!: Date;

  @Attribute({ serializedName: 'cohort_event' })
  isCohortEvent!: boolean;

  @Attribute({ serializedName: 'assigned_to' })
  assignedTo!: boolean;

  @Attribute({ serializedName: 'event_name' })
  eventName!: string;

  @Attribute({ serializedName: 'organizer' })
  eventOrganizer!: string;

  @Attribute({ serializedName: 'event_description' })
  eventDescription!: string;

  @Attribute({ serializedName: 'seats_taken' })
  seatsTaken!: number;

  @Attribute({ serializedName: 'total_seats' })
  totalSeats!: number;

  @BelongsTo()
  host!: Member;

  @BelongsTo({ key: 'ilt_course' })
  iltCourse!: ILTCourse;

  @HasMany({ key: 'event_days' })
  iltCourseEventDays!: ILTCourseEventDay[];

  @HasMany({ key: 'ilt_event_assignments' })
  iltCourseEventAssignments!: ILTCourseEventAssignment[] | undefined;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  get name(): string {
    return this.eventName || dayjs(this.startDate).format('YYYY-MM-DD');
  }

  public get isOldEvent(): boolean {
    const date = dayjs(this.endDate).format('YYYY-MM-DD');
    const currentDay = dayjs();
    return currentDay.isAfter(date, 'day') || currentDay.isSame(date);
  }
}
