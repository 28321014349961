import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'templates',
})
export class CustomEmail extends JsonApiModel {
  @Attribute({ serializedName: 'id' })
  customEmailId!: number;

  @Attribute({ serializedName: 'title' })
  name!: string;

  @Attribute({ serializedName: 'subject' })
  customEmailSubject!: string;

  @Attribute({ serializedName: 'language' })
  customEmailLanguage!: string;

  @Attribute({ serializedName: 'template_type' })
  customEmailType!: string;

  @Attribute({ serializedName: 'body' })
  customEmailBody!: string;

  @Attribute({ serializedName: 'updated_at' })
  customEmailUpdatedAt!: string;

  @Attribute({ serializedName: 'available_variables' })
  replacements!: string[];

  @Attribute({ serializedName: 'template_key' })
  customEmailKey!: string;
}
