import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'company_news_settings',
})
export class CompanyNewsSetting extends JsonApiModel {
  @Attribute({ serializedName: 'company_news' })
  companyNews!: string;

  @Attribute({ serializedName: 'name' })
  name!: string;

  @Attribute({ serializedName: 'default_news' })
  isDefaultNews!: boolean;
}
