import { Injectable } from '@angular/core';

import { RouteActionInterface } from '../interfaces/route-action.interface';
import { routeActions } from '../menu/config/route-toolbar-actions.config';

@Injectable({
  providedIn: 'root',
})
export class ToolbarActionsService {
  constructor() {}

  getActionsForCurrentRoute(route: string): RouteActionInterface | undefined {
    if (!route) {
      return undefined;
    }

    return this.getActions(route);
  }

  private getActions(route: string): RouteActionInterface | undefined {
    const actions = routeActions.find(a =>
      this.routeNamesContainsRoute(a.routeNames, route),
    );

    return actions;
  }

  private routeNamesContainsRoute(
    routeNames: (string | RegExp)[],
    route: string,
  ): boolean {
    return routeNames.some(
      (r: string | RegExp) =>
        (r instanceof RegExp && r.test(route)) ||
        (typeof r === 'string' && r === route),
    );
  }
}
