import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NotificationCenterComponent } from './notification-center.component';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

export const declarations = [NotificationCenterComponent];

export const imports = [
  TranslateModule,
  MatTableModule,
  MatIconModule,
  FlexLayoutModule,
  MatButtonModule,
];

@NgModule({
  declarations,
  imports: [CommonModule, RouterModule, ...imports],
  exports: [NotificationCenterComponent],
})
export class NotificationCenterModule {}
