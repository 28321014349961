import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Course } from '@models/course.model';
import { Meeting } from '@models/meeting.model';
import { TrainingScheduleCohort } from '@models/training-schedule/training-schedule-cohort.model';
import { ITEM_TYPES } from '@app/core/constants/training-schedules.constant';
import { Member } from '../member.model';
import { ILTCourse } from '../ilt-course.model';
import { PhysicalLocation } from '../physical-location.model';
import { VirtualLocation } from '../virtual-location.model';
import { ILTCourseEventDay } from '../ilt-course/ilt-course-event-day.model';
import { ILTCourseEvent } from '../ilt-course/ilt-course-event.model';
import { getTrainingScheduleTimeString } from '@app/core/helper/training-schedule';

@JsonApiModelConfig({
  type: 'cohort_items',
})
export class TrainingScheduleCohortItem extends JsonApiModel {
  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute({ serializedName: 'item_id' })
  itemId!: string;

  @Attribute({ serializedName: 'item_type' })
  itemType!: string;

  @Attribute({ serializedName: 'start_date' })
  date!: Date;

  @Attribute({ serializedName: 'start_time' })
  startTime!: string | null;

  @Attribute()
  disabled!: boolean;

  @Attribute({ serializedName: 'cohort_id' })
  cohortId!: string;

  @BelongsTo({ key: 'cohort' })
  cohort!: TrainingScheduleCohort;

  @BelongsTo({ key: 'item' })
  item!: Course | Meeting | ILTCourseEventDay;

  @BelongsTo({ key: 'event_day_resource' })
  eventDay!: ILTCourseEventDay | undefined;

  public get iltCourse(): ILTCourse | null | undefined {
    if (this.itemType === ITEM_TYPES.EVENT_DAY) {
      return this.event?.iltCourse;
    }

    return undefined;
  }

  public get instructors(): Member[] | undefined {
    if (this.itemType === ITEM_TYPES.EVENT_DAY) {
      return this.eventDay?.instructors;
    }

    return undefined;
  }

  public get host(): Member | null | undefined {
    if (this.itemType === ITEM_TYPES.EVENT_DAY) {
      return this.eventDay?.iltCourseEvent?.host;
    }

    return undefined;
  }

  public get event(): ILTCourseEvent | null | undefined {
    if (this.itemType === ITEM_TYPES.EVENT_DAY) {
      return this.eventDay?.iltCourseEvent;
    }

    return undefined;
  }

  public get physicalLocation(): PhysicalLocation | null | undefined {
    if (this.itemType === ITEM_TYPES.EVENT_DAY) {
      return this.eventDay?.physicalLocation;
    }

    return undefined;
  }

  public get virtualLocation(): VirtualLocation | null | undefined {
    if (this.itemType === ITEM_TYPES.EVENT_DAY) {
      return this.eventDay?.virtualLocation;
    }

    return undefined;
  }

  public get startTimeFormatted(): string | null {
    if (!this.startTime) {
      return null;
    }

    return getTrainingScheduleTimeString(this.startTime);
  }

  public get endTimeFormatted(): string {
    dayjs.extend(customParseFormat);
    if (this.itemType === ITEM_TYPES.EVENT_DAY) {
      return dayjs(this.item.endTime, 'hh:mmA').format('HH:mm');
    }

    let itemTime = 30;
    const minutes =
      this.itemType === ITEM_TYPES.COURSE
        ? this.item.estimatedTimeInMinutes
        : this.item.durationInMinutes;
    if (minutes > 30) {
      itemTime = Math.ceil(minutes / 30) * 30;
    }

    return dayjs(this.startTimeFormatted, 'HH:mm')
      .add(itemTime, 'minutes')
      .format('HH:mm');
  }
}
