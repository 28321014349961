import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class GtmWrapperService {
  constructor(private gtmService: GoogleTagManagerService) {}

  pushTag(item: any): void {
    this.gtmService.pushTag(item).catch(() => {});
  }
}
