import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import { Group } from '@models/group.model';

@JsonApiModelConfig({
  type: 'group_rules',
})
export class GroupRule extends JsonApiModel {
  @BelongsTo()
  group!: Group;

  @Attribute({ serializedName: 'group_id' })
  groupId!: string;

  @Attribute({ serializedName: 'conditions' })
  conditions!: string;

  @Attribute({ serializedName: 'eligible_attributes' })
  eligibleAttributes!: Array<string>;
}
