import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { TrainingStatusComponent } from './training-status.component';

export const imports = [CommonModule, FlexLayoutModule, TranslateModule];

export const declarations = [TrainingStatusComponent];

@NgModule({
  declarations,
  exports: [TrainingStatusComponent],
  imports,
  providers: [],
})
export class TrainingStatusModule {}
