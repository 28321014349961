// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getH5PIntegration } from 'app/shared/h5p/assets/h5p-integration';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getAssets } from 'app/shared/h5p/assets/h5p-assets';
import { CourseActivity } from '@models/course/course-activity.model';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  ScriptInjectorService,
  ScriptStore,
} from '@services/script-injector.service';
import { TIME_UNITS } from '@core/constants/time-units.constants';
import { LanguageService } from '@app/core/services/language.service';
import { LANGUAGES } from '@core/constants/language.constants';

@Injectable({
  providedIn: 'root',
})
export class H5PInitialization {
  private promiseInProgress: Promise<unknown> | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private assets: any;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private H5PIntegration: any;
  constructor(
    private scriptService: ScriptInjectorService,
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {}

  initGlobalH5P(lang = '', fallbackLang = ''): Promise<unknown> {
    const supportedLang = this.getH5PLanguage(
      lang || this.languageService.getCurrentLanguage(),
      fallbackLang,
    );
    this.assets = getAssets(supportedLang);
    this.H5PIntegration = getH5PIntegration(supportedLang);
    if (this.promiseInProgress) {
      return this.promiseInProgress;
    }

    this.promiseInProgress = new Promise((resolve, reject) => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (globalThis.H5PIntegration || globalThis.H5PEditor) {
          // Check if language changed and load correct editor language
          if (this.scriptService.isLoaded(this.assets.currentTranslation)) {
            resolve(true);
          } else {
            this.scriptService.unload(this.assets.allTranslations);
            this.scriptService.load(this.assets.currentTranslation).then(() => {
              resolve(true);
            });
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          globalThis.H5PIntegration = this.H5PIntegration;
          const baseUrl = this.H5PIntegration.editor.baseUrl;
          const allScripts = this.assets.js.concat(this.assets.allTranslations);
          const usedScripts = this.assets.js.concat(
            this.assets.currentTranslation,
          );
          ScriptStore.push(
            ...allScripts.map((asset: string) => ({
              name: asset,
              src: baseUrl + '/' + asset,
            })),
          );
          this.scriptService.setScripts();
          this.scriptService.load(usedScripts).then(() => {
            this.loadH5PAssets(
              this.assets.css,
              baseUrl,
              this.loadStyleSheet,
              resolve,
            );
          });
        }
      } catch (error) {
        reject(error);
      }
    });

    this.promiseInProgress.then(() => {
      this.promiseInProgress = undefined;
    });

    return this.promiseInProgress;
  }

  initH5PEditorScriptsAndStyles(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const baseUrl = H5PIntegration.editor.baseUrl;
        console.info('Loading editor styles');
        this.loadH5PAssets(
          this.assets.editorCss,
          baseUrl,
          this.loadStyleSheet,
          resolve,
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  initH5PEditor(
    h5pEditorWrapper: HTMLElement,
    loadInIframe = true,
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        globalThis.isExistingContent = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        globalThis.h5peditor = new globalThis.H5PEditor.Editor(
          undefined,
          undefined,
          h5pEditorWrapper,
          resolve,
          loadInIframe,
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  runActivity(courseActivity: CourseActivity): void {
    const { h5pLibrary, contentJSON } = courseActivity;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const loadInIframe = globalThis.H5PEditor.loadInIframe;
    const h5pEditorContentWindow = loadInIframe
      ? this.getH5PEditorContainer().contentWindow
      : globalThis;
    const containerSelector = loadInIframe
      ? 'body > .h5p-editor'
      : '.h5p-editor';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const $ = h5pEditorContentWindow.H5P.jQuery;
    const $container = $(containerSelector);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.isExistingContent = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const libraries = globalThis.h5peditor.selector.libraries;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const libraryLoader = new h5pEditorContentWindow.H5PEditor.LibrarySelector(
      libraries,
      h5pLibrary,
      contentJSON,
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.h5peditor.selector = libraryLoader;
    libraryLoader.appendTo($container.html(''));

    // Set library if editing
    if (h5pLibrary) {
      libraryLoader.setLibrary(h5pLibrary);
    }
  }

  setupGlobalH5PEditor(lang = '', fallbackLang = ''): void {
    const H5PEditor = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      $: globalThis.H5P.jQuery,
      basePath: this.H5PIntegration.editor.baseUrl + '/editor',
      fileIcon: this.H5PIntegration.editor.fileIcon,
      ajaxPath: this.H5PIntegration.editor.ajaxPath,
      filesPath: this.H5PIntegration.editor.filesPath,
      apiVersion: this.H5PIntegration.editor.apiVersion,
      contentLanguage: this.getH5PLanguage(
        lang || this.languageService.getCurrentLanguage(),
        fallbackLang,
      ),
      // Semantics describing what copyright information can be stored for media.
      copyrightSemantics: this.H5PIntegration.editor.copyrightSemantics,
      metadataSemantics: this.H5PIntegration.editor.metadataSemantics,
      assets: this.H5PIntegration.editor.assets, // Required styles and scripts for the editor
      baseUrl: '', // Required for assets
      contentId: null,
      dateTimeConstants: TIME_UNITS,
    };

    if (this.H5PIntegration.editor.nodeVersionId !== undefined) {
      H5PEditor.contentId = this.H5PIntegration.editor.nodeVersionId;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!globalThis.H5PEditor) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      globalThis.H5PEditor = {};
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.assign(globalThis.H5PEditor, H5PEditor);

    this.setupGlobalH5PAjaxUrl();
  }

  private setupGlobalH5PAjaxUrl(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.H5PEditor.getAjaxUrl = (action, parameters) => {
      let url = this.H5PIntegration.editor.ajaxPath + action;

      if (parameters !== undefined) {
        for (const property in parameters) {
          if (parameters.hasOwnProperty(property)) {
            url += '&' + property + '=' + parameters[property];
          }
        }
      }

      return url;
    };
  }

  private getH5PEditorContainer(): HTMLIFrameElement {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return globalThis.document.querySelector('.h5p-editor-iframe');
  }

  // Use only for initial loading h5p
  private loadH5PAssets(
    assetsArr: Array<string>,
    baseUrl: string,
    executiveFunc: CallableFunction,
    done: CallableFunction,
  ): void {
    let assetCounter = 0;

    assetsArr.forEach(asset => {
      assetCounter++;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      executiveFunc.call(this, baseUrl + '/' + asset, assetCounter, done);
    });
  }

  private loadStyleSheet(
    src: string,
    styleCounter: number,
    done: CallableFunction,
  ): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.ns
      .$('head')
      .append(`<link rel="stylesheet" href="${src}" type="text/css" />`);
    styleCounter++;
    if (styleCounter === this.assets.css.length) {
      done();
    }
  }

  private getH5PLanguage(currentLang: string, fallbackLang: string): string {
    return this.translateService
      .getLangs()
      .filter(l => l === LANGUAGES.DE || l === LANGUAGES.EN)
      .includes(currentLang)
      ? currentLang
      : fallbackLang
      ? fallbackLang
      : LANGUAGES.EN;
  }
}
