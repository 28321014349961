import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'gamification_levels',
})
export class GamificationLevel extends JsonApiModel {
  @Attribute()
  name!: string;

  @Attribute()
  score!: number;

  @Attribute()
  image?: string;
}
