<div class="header" ngClass.lt-md="mobile" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px" fxLayoutGap.lt-md="20px">
  <div class="headline-container" fxFlex="grow">
    <span class="headline-h3" ngClass.lt-md="mobile">
      {{ courseTitle?.toUpperCase() || '' }}
    </span>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" fxFlex="grow" fxFlex.lt-md="0">
    <app-progress
      [value]="progress"
      [allGreen]="true" 
      [height]="12" 
      [showValue]="false"
      ngClass.lt-md="mobile"
    >
    </app-progress>
    <div class="progress-value">
      {{ progress || '0'}}%
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="30px" fxFlex="grow" fxFlex.lt-md="0">
    <div>
      <div
        class="more-options"
        ngClass.lt-md="mobile"
        [mdePopoverTriggerFor]="optionsPopover"
        mdePopoverTriggerOn="click"
        mdePopoverPositionX="after"
        mdePopoverPositionY="below"
        fxLayout="row"  
        fxLayoutAlign="center center"
        fxLayoutGap="10px" 
        fxLayoutGap.lt-md="0px"
      >
        <button mat-icon-button class="more-options-button">
          <mat-icon aria-hidden="true">more_horiz</mat-icon>
        </button>
        <span ngClass.lt-md="hide">{{ 'courses.moreOptions' | translate }}</span>
      </div>
      <mde-popover #optionsPopover="mdePopover" [mdePopoverOverlapTrigger]="false"
              [mdePopoverOffsetY]="-8" [mdePopoverOffsetX]="10" classList="actions-popover h5p-player-header-popover">
        <div fxLayout="column">
          <div [routerLink]="'/courses/' + courseId + '/overview'" [queryParams]="getQueryParams()">
            <mat-icon>exit_to_app</mat-icon>
            <a>{{ 'courses.leave' | translate }}</a>
          </div>
          <div (click)="openUserback()">
            <mat-icon>feedback</mat-icon>
            <a>{{ 'main.header.feedback' | translate }}</a>
          </div>
        </div>
      </mde-popover>
    </div>
    <button ngClass.lt-md="hide" [disabled]="!hasPrevious" mat-stroked-button color="darkblue" (click)="onPrevious()" [ngClass]= "{'visibility-hidden': !showCourseNavigation }">
      {{ 'common.previous' | translate }}
    </button>
    <button ngClass.lt-md="hide" [disabled]="!hasNext" class="filled no-border" mat-stroked-button color="yellow" (click)="onNext()" [ngClass]= "{'visibility-hidden': !showCourseNavigation }">
      {{ (isLastActivity ? 'common.complete' : 'common.next') | translate }}
    </button>
  </div>
</div>
