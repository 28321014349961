import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface IHeaderConfig {
  [key: string]: boolean | string;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  data = new ReplaySubject<IHeaderConfig>();

  private defaultFavicon = 'favicon.ico';
  private favIcon: HTMLLinkElement | null = document.querySelector('#favicon');
  private settings: IHeaderConfig = {
    showHeader: true,
    logo: '',
    showGamificationBadge: false,
    showGamificationRanking: false,
    profilePicture: '',
  };

  constructor() {}

  setValue(key: string, value: boolean | string): void {
    this.settings[key] = value;
    this.data.next(this.settings);
  }

  setValues(settings: IHeaderConfig): void {
    this.settings = settings;
    this.data.next(this.settings);
  }

  changeFavicon(url: string): void {
    if (this.favIcon) {
      this.favIcon.href = url || this.defaultFavicon;
    }
  }
}
