import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'blocks',
})
export class Block extends JsonApiModel {
  @Attribute()
  headline!: string;

  @Attribute()
  description!: string;

  @Attribute({ serializedName: 'duration_in_minutes' })
  durationInMinutes!: string;

  @Attribute({ serializedName: 'is_busy' })
  isBusy!: boolean;
}
