import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { JsonTranslateHttpMultiLoader } from '@shared/translation-loader/lib/json.translate.http.multiLoader';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: JsonTranslateHttpMultiLoader.factory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
  ],
})
export class TranslationLoaderModule {}
