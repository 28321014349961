import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Roles } from '@core/constants/roles.constant';
import { Group } from '@models/group.model';
import { isEmailValid } from '@helper/validators';
import { Datastore } from '@services/datastore';
import { SnackBarService } from '@services/snack-bar.service';
import { BulkInvitation } from '@models/auth/bulk-invitation.model';
import { Resource } from '@shared/multi-select-server-side-search/interfaces';
import { multiSelectStartTopPosition } from '@core/constants/multi-select-position.constant';

@Component({
  selector: 'app-member-invite-email-dialog',
  templateUrl: './member-invite-email-dialog.component.html',
  styleUrls: ['./member-invite-email-dialog.component.scss'],
})
export class MemberInviteEmailDialogComponent implements OnInit {
  public selectedGroups: Group[] = [];
  public ROLES = Roles;
  public rolesList = Object.values(this.ROLES);
  public memberInviteByEmailForm: UntypedFormGroup;
  public Group = Group;
  public multiSelectPosition = multiSelectStartTopPosition;

  constructor(
    private translateService: TranslateService,
    private snackbarService: SnackBarService,
    private formBuilder: UntypedFormBuilder,
    private datastore: Datastore,
    public dialogRef: MatDialogRef<MemberInviteEmailDialogComponent>,
  ) {
    this.memberInviteByEmailForm = this.formBuilder.group({
      email: new UntypedFormControl('', [
        Validators.required,
        this.emailsListValid(),
        this.emailsListUnique(),
      ]),
      role: new UntypedFormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {}

  sendInvites(): void {
    const controls = this.memberInviteByEmailForm.controls;
    const data = {
      emails: this.getEmailsList(controls.email),
      role: controls.role.value,
      groups: this.selectedGroups,
    };
    const profileInvite = this.datastore.createRecord(BulkInvitation, data);
    profileInvite
      .save()
      .pipe(take(1))
      .subscribe(
        () => {
          this.showSuccessNotification();
          this.memberInviteByEmailForm.reset();
          this.selectedGroups = [];
        },
        () => this.showErrorNotification(),
      );
  }

  onFilter(resources: Resource[]): void {
    const groups = resources as Group[];
    this.selectedGroups = groups;
  }

  private getEmailsList(control: AbstractControl): string[] {
    return control.value.replace(/\s/g, '').split(',');
  }

  private emailsListValid(): ValidatorFn {
    // tslint:disable-next-line:no-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const emailsList = this.getEmailsList(control);
      const isInvalid = emailsList.some(
        (email: string) => !isEmailValid(email),
      );
      return isInvalid ? { invalidEmail: { value: control.value } } : null;
    };
  }

  private emailsListUnique(): ValidatorFn {
    // tslint:disable-next-line:no-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const emailsList = this.getEmailsList(control);
      const isInvalid = emailsList.find(
        (email: string, index: number) => emailsList.indexOf(email) !== index,
      );
      return isInvalid ? { duplicateEmail: { value: control.value } } : null;
    };
  }

  private showSuccessNotification(): void {
    this.snackbarService.success(
      this.translateService.instant(
        'attributes.talent.invite.successInvitesMessage',
      ),
    );
    this.dialogRef.close();
  }

  private showErrorNotification(): void {
    this.snackbarService.error(
      this.translateService.instant(
        'attributes.talent.invite.errorInvitesMessage',
      ),
    );
    this.dialogRef.close();
  }
}
