import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

@JsonApiModelConfig({
  type: 'company_consent_settings',
})
export class CompanyConsentSetting extends JsonApiModel {
  @Attribute({ serializedName: 'company_consent' })
  companyConsent!: string;

  @Attribute()
  language!: string;
}
