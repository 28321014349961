<div class="footer" fxHide.gt-sm fxLayout="row" fxLayoutAlign="space-between center">
  <button class="with-icon-margin" [class.inactive]="!hasPrevious" [disabled]="!hasPrevious" mat-stroked-button color="darkblue" (click)="onPrevious()">
    <mat-icon>chevron_left</mat-icon>
    {{ 'common.previous' | translate }}
  </button>
  <button class="with-icon-margin" [class.inactive]="!hasNext" [disabled]="!hasNext" class="filled no-border" mat-stroked-button color="yellow" (click)="onNext()">
    {{ (isLastActivity ? 'common.complete' : 'common.next') | translate }}
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
