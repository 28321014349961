/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Member } from '../member.model';
import { ILTCourseEvent } from './ilt-course-event.model';

@JsonApiModelConfig({
  type: 'ilt_event_assignments',
})
export class ILTCourseEventAssignment extends JsonApiModel {
  @Attribute()
  status!: string;

  @Attribute({ serializedName: 'event_id' })
  iltCourseEventId!: number;

  @Attribute({ serializedName: 'member_id' })
  memberId!: number;

  @Attribute({ serializedName: 'self_assigned' })
  selfAssigned!: boolean;

  @BelongsTo({ key: 'event' })
  iltCourseEvent!: ILTCourseEvent;

  @BelongsTo()
  member!: Member;
}
