import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time12To24',
})
export class Time12To24Pipe implements PipeTransform {
  constructor() {}

  // Format: 02:30PM => 14:30
  transform(timeStr: string): string {
    return this.convertTimeTo24Format(timeStr);
  }

  private convertTimeTo24Format(timeStr: string): string {
    if (!timeStr) {
      return '';
    }

    const time = timeStr.substring(0, 5);
    const modifier = timeStr.substring(5, 7);
    let hours = time.split(':')[0];
    const minutes = time.split(':')[1];

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }

    return `${hours}:${minutes}`;
  }
}
