import { Injectable } from '@angular/core';
import { Datastore } from '@services/datastore';
import { UserService } from '@services/user.service';
import { CompanySetting } from '@models/company-setting.model';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from '@environment';
import { take, map } from 'rxjs/operators';
import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingService {
  companySettingListener = new ReplaySubject<CompanySetting>();
  private company: CompanySetting | null = null;
  private isLoading = false;
  private concurrentRequestSubject = new Subject<CompanySetting | null>();

  private constructor(
    private datastore: Datastore,
    private userService: UserService,
  ) {}

  public current(): Observable<CompanySetting | null> {
    const subject = new ReplaySubject<CompanySetting | null>(1);

    if (this.isLoading) {
      return this.concurrentRequestSubject.asObservable();
    }

    if (!this.company) {
      this.userService
        .getCurrentUser()
        .pipe(take(2))
        .subscribe((user: User | null) => {
          if (user === null) {
            return;
          }
          this.isLoading = true;

          this.datastore
            .findRecord(CompanySetting, user.companyId as string)
            .subscribe((company: CompanySetting) => {
              this.company = company;
              this.concurrentRequestSubject.next(company);
              this.isLoading = false;
              subject.next(this.company);
            });
        });
    } else {
      subject.next(this.company);
    }

    return subject;
  }

  public currentWithReset(): Observable<CompanySetting | null> {
    const subject = new ReplaySubject<CompanySetting | null>(1);
    if (this.company?.id) {
      this.datastore
        .findRecord(CompanySetting, this.company.id)
        .subscribe((company: CompanySetting) => {
          this.company = company;
          this.concurrentRequestSubject.next(company);
          this.isLoading = false;
          subject.next(this.company);
        });
      return subject;
    }

    return this.current();
  }

  public currentBrandColor(): Observable<string> {
    return this.current().pipe(
      map(
        (companySetting: CompanySetting | null) =>
          companySetting?.brandColor || environment.defaultBrandColor,
      ),
    );
  }

  public defaultBrandColor(): string {
    return environment.defaultBrandColor;
  }

  public defaultAccentColor(): string {
    return environment.defaultAccentColor;
  }

  getCompany(): CompanySetting | null {
    return this.company;
  }

  setCurrentCompany(company: CompanySetting): void {
    this.company = company;
    this.companySettingListener.next(this.company);
  }
}
