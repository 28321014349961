import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Country } from './country.model';

@JsonApiModelConfig({
  type: 'holidays',
})
export class Holiday extends JsonApiModel {
  @Attribute()
  date!: Date;

  @Attribute()
  name!: string;

  @Attribute()
  region!: string;

  @BelongsTo()
  country!: Country;
}
