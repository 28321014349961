import { Attribute, JsonApiModel } from '@elearnio/angular2-jsonapi';

export class EmailTemplate extends JsonApiModel {
  @Attribute()
  body?: string;

  @Attribute()
  language?: string;

  @Attribute()
  replacements?: string[];

  @Attribute()
  subject!: string;

  @Attribute({ serializedName: 'template_key' })
  templateKey!: string;

  @Attribute()
  title!: string;
}
