import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { ErrorParser } from '@shared/error/parser';
import { NotificationInterface } from '@shared/notification/interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorResponse } from '@elearnio/angular2-jsonapi';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public isOpen = false;
  public notification: NotificationInterface = {
    type: '',
    title: '',
    message: '',
    button: '',
    detailedMessage: '',
  };

  constructor(
    public translateService: TranslateService,
    private errorParser: ErrorParser,
    private snackBarService: MatSnackBar,
  ) {}

  // Main function
  public show(notification: NotificationInterface, showDialog = true): void {
    if (showDialog) {
      this.isOpen = true;
      this.notification = notification;
    } else {
      this.snackBarService.open(
        `${notification.title}. ${notification.message}`,
        notification.button,
        {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        },
      );
    }
  }

  public close(): void {
    this.isOpen = false;
    this.notification = {
      type: '',
      title: '',
      message: '',
      button: '',
      detailedMessage: '',
    };
  }

  public showError(
    message = 'notification.error.generic.message',
    title = 'notification.error.generic.title',
    detailedMessage = '',
    button = 'notification.error.generic.button',
    showDialog = true,
  ): void {
    this.showErrorRaw(
      this.t(message),
      this.t(title),
      detailedMessage,
      this.t(button),
      showDialog,
    );
  }

  public showSuccess(
    message = 'notification.success.generic.message',
    title = 'notification.success.generic.title',
    detailedMessage = '',
    button = 'notification.success.generic.button',
  ): void {
    this.showSuccessRaw(
      this.t(message),
      this.t(title),
      detailedMessage,
      this.t(button),
    );
  }

  // Main error handler
  public handleError(
    error: HttpErrorResponse | ErrorResponse,
    showDialog = true,
  ): void {
    if (error instanceof HttpErrorResponse) {
      if ([401, 404, 500, 423, 504].includes(error.status)) {
        return this.showError(
          this.t(`notification.error.e${error.status}.message`),
          this.t(`notification.error.e${error.status}.title`),
          undefined,
          undefined,
          showDialog,
        );
      }
    }

    this.showError(this.errorParser.flattenErrors(error));
  }

  private showSuccessRaw(
    message: string,
    title: string,
    detailedMessage: string,
    button: string,
  ): void {
    this.show({ type: 'success', button, title, message, detailedMessage });
  }

  private showErrorRaw(
    message: string,
    title: string,
    detailedMessage: string,
    button: string,
    showDialog = true,
  ): void {
    this.show(
      { type: 'error', button, title, message, detailedMessage },
      showDialog,
    );
  }

  // Shortcut for translate function
  private t = (text: string): string => this.translateService.instant(text);
}
