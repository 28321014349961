/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { LearningPath } from '@app/models/learning-path.model';
import { OrganizationLevel } from '@models/organization/organization-level.model';

@JsonApiModelConfig({
  type: 'org_levels_learning_paths',
})
export class OrganizationLevelLearningPath extends JsonApiModel {
  @Attribute({ serializedName: 'learning_path_id' })
  lpId!: string;

  @Attribute({ serializedName: 'organisation_level_id' })
  organizationLevelId!: string;

  @Attribute({ serializedName: 'show_in_catalog' })
  showInCatalogue!: boolean;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: boolean;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: boolean;

  @BelongsTo()
  lp: LearningPath | undefined;

  @BelongsTo({ key: 'organisation_level' })
  organizationLevel!: OrganizationLevel;

  @BelongsTo({ key: 'inherited_from' })
  inheritedFrom!: OrganizationLevel | undefined;
}
