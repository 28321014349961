import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectLocalSearchComponent } from './multi-select-local-search.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DirectivesModule } from '@core/directives/directives.module';

export const imports = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  TranslateModule,
  MatSelectModule,
  MatFormFieldModule,
  NgxMatSelectSearchModule,
  DirectivesModule,
];

export const declarations = [MultiSelectLocalSearchComponent];
export const providers = [DatePipe];

@NgModule({
  declarations,
  exports: [MultiSelectLocalSearchComponent],
  imports,
  providers,
})
export class MultiSelectLocalSearchModule {}
