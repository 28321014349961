import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LanguageFlagIconPipe } from '@pipes/language-flag-icon/language-flag-icon.pipe';

@NgModule({
  declarations: [LanguageFlagIconPipe],
  exports: [LanguageFlagIconPipe],
  imports: [CommonModule, TranslateModule],
})
export class LanguageFlagIconModule {}
