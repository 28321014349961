/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';
import { Course } from '../../course.model';
import { Certificate } from '@models/template/certificate.model';
import { CourseMemberAnswer } from './course-member-answer.model';

@JsonApiModelConfig({
  type: 'course_member_results',
})
export class CourseMemberResult extends JsonApiModel {
  @Attribute({ serializedName: 'course_id' })
  courseId!: string;

  @Attribute({ serializedName: 'member_id' })
  memberId!: string;

  @Attribute({ serializedName: 'courses_member_id' })
  courseMemberId!: string;

  @Attribute()
  success!: boolean;

  @Attribute({ serializedName: 'total_activities' })
  totalActivities!: number;

  @Attribute({ serializedName: 'answered_activities' })
  answeredActivities!: number;

  @Attribute()
  grading!: number;

  @Attribute()
  progress!: number;

  @Attribute({ serializedName: 'valid_until' })
  validUntil!: Date;

  @Attribute({ serializedName: 'completion_date' })
  completionDate!: Date;

  @Attribute({ serializedName: 'time_spent_in_seconds' })
  timeSpentInSeconds!: number;

  @Attribute({ serializedName: 'course_rating' })
  courseRating!: number;

  @Attribute({ serializedName: 'course_gamification_points' })
  gamificationPoints!: number;

  @Attribute()
  deadline!: Date;

  @Attribute()
  status!: string; // Delegated from courses member

  @BelongsTo()
  course!: Course;

  @HasMany({ key: 'course_member_answers' })
  courseMemberAnswers: CourseMemberAnswer[] = [];

  @BelongsTo()
  certificate!: Certificate;
}
