<div *ngIf="section" [class.expanded]="isSidebarExpanded" class="title" fxLayout="row" fxLayoutAlign="start center">
  <div class="title-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="onSideToolbarClick()">
    <mat-icon>{{ isSidebarExpanded ? 'menu_open' : 'menu' }}</mat-icon>
    <span *ngIf="isSidebarExpanded" class="sidebar-item-span">{{ ('sidebar.' + section) | translate }}</span>
  </div>
</div>
<div class="courses-list-sidebar" [class.expanded]="isSidebarExpanded" fxLayout="column">
  <div class="admin-area"
    *ngIf="section && [SECTIONS.COURSES, SECTIONS.CATEGORIES, SECTIONS.LEARNING_PATHS, SECTIONS.ILT_COURSES, SECTIONS.TRAINING_SCHEDULES].includes(section)"
  >
    <h3 class="admin-area-title" *ngIf="hasCourseCreatePermission() || hasCoursePermission() || hasIltCoursePermission() || hasLPPermission() || hasTrainingSchedulePermission()">{{ isSidebarExpanded ? ('courses.sidebar.admin.my-area' | translate) : ('courses.sidebar.admin.my-area-admin' | translate) }}</h3>
    <ng-container *ngIf="hasCourseCreatePermission() || hasCoursePermission() || hasIltCoursePermission() || hasLPPermission() || hasTrainingSchedulePermission()">
      <a *ngIf="hasCoursePermission()" class="sidebar-item" [class.active]="router.isActive('/courses/list', true)"
         [routerLink]="'/courses/list'" fxLayoutGap="8px">
         <mat-icon>school</mat-icon>
         <span class="sidebar-item-span">{{ 'sections.courses' | translate }}</span>
        </a>
      <a *ngIf="hasIltCoursePermission()" class="sidebar-item beta-item" [class.active]="router.isActive('/ilt-courses/list', true)"
        [routerLink]="'/ilt-courses/list'" fxLayoutGap="8px">
        <mat-icon svgIcon="live_training_teacher"></mat-icon>
        <span class="sidebar-item-span">{{ 'sections.iltCourses' | translate }}</span>
      </a>
      <a *ngIf="hasLPPermission()" class="sidebar-item" [class.active]="router.isActive('/learning-paths/list', true)"
         [routerLink]="'/learning-paths/list'" fxLayoutGap="8px">
         <mat-icon>route</mat-icon>
         <span class="sidebar-item-span">{{ 'sections.learningPaths' | translate }}</span>
      </a>
      <a *ngIf="hasTrainingScheduleCreatePermission() || hasTrainingSchedulePermission()" class="sidebar-item" [class.active]="router.isActive('training-schedules', false)"
        [routerLink]="'/training-schedules/list'" fxLayoutGap="8px">
        <mat-icon>date_range</mat-icon>
        <span class="sidebar-item-span">{{ 'sections.trainingSchedules' | translate }}</span>
      </a>
      <a *ngIf="hasCategoriesPermission()" class="sidebar-item" [class.active]="router.isActive('categories', false)"
         [routerLink]="'/categories/list'" fxLayoutGap="8px">
         <mat-icon>workspaces_filled</mat-icon>
         <span class="sidebar-item-span">{{ 'sections.categories' | translate }}</span>
      </a>
    </ng-container>

    <h3 class="admin-area-title instructor-area" *ngIf="hasInstructorViewPermission() || hasTaskReviewerViewPermission() || hasAssignmentApprovalRequestsViewPermission()">
      {{ isSidebarExpanded ? ('courses.sidebar.admin.instructor-area' | translate) : ('courses.sidebar.admin.instructor-area-admin' | translate) }}
    </h3>
    <ng-container *ngIf="hasInstructorViewPermission()">
      <a class="sidebar-item" [class.active]="router.isActive('/ilt-courses/instructors-list', true)"
        [routerLink]="'/ilt-courses/instructors-list'" fxLayoutGap="8px">
        <mat-icon svgIcon="live_training_teacher"></mat-icon>
        <span class="sidebar-item-span">{{ 'sections.iltCourses' | translate }}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="hasTaskReviewerViewPermission()">
      <a class="sidebar-item" [class.active]="router.isActive('/courses/task-activity-review', false)"
        [routerLink]="'/courses/task-activity-review'" fxLayoutGap="8px">
        <mat-icon svgIcon="task_activity"></mat-icon>
        <span class="sidebar-item-span">{{ 'sections.reviewTasks' | translate }}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="hasAssignmentApprovalRequestsViewPermission()">
      <a class="sidebar-item" [class.active]="router.isActive('/assignment-approvals/list', false)"
        [routerLink]="'/assignment-approvals/list'" fxLayoutGap="8px">
        <mat-icon>done_all</mat-icon>
        <span class="sidebar-item-span">{{ 'sections.assignmentApprovals' | translate }}</span>
      </a>
    </ng-container>

    <div class="admin-area talent-area">
      <h3>{{ isSidebarExpanded ? ('courses.sidebar.admin.talent-area' | translate) : '' }}</h3>
    </div>
    <ng-container>
      <div class="talent-area">
        <a class="sidebar-item" routerLinkActive="active"
          [routerLink]="'/courses/my'" fxLayoutGap="8px">
          <mat-icon>school</mat-icon>
          <span class="sidebar-item-span">{{ 'courses.assigned-courses' | translate }}</span>
        </a>
        <a class="sidebar-item beta-item" routerLinkActive="active" [routerLink]="'/ilt-courses/my'" fxLayoutGap="8px">
          <mat-icon svgIcon="live_training_teacher"></mat-icon>
          <span class="sidebar-item-span">{{ 'iltCourses.assignedILTCourses' | translate }}</span>
        </a>
        <a class="sidebar-item" routerLinkActive="active" [routerLink]="'/learning-paths/my'" fxLayoutGap="8px">
          <mat-icon>route</mat-icon>
          <span class="sidebar-item-span">{{ 'learningPaths.assignedLearningPaths' | translate }}</span>
        </a>
        <a class="sidebar-item" routerLinkActive="active" [routerLink]="'/courses/catalogue'" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
          <mat-icon svgIcon="course_add"></mat-icon>
          <span class="sidebar-item-span" [matBadge]="this.member?.subscribedCoursesCount" [matBadgeOverlap]="false"
                matBadgeSize="small">{{ 'courses.catalogue' | translate }}</span>
        </a>
        <a *ngIf="hasTrainingScheduleFeature && hasAssignedCohort" class="sidebar-item" routerLinkActive="active" [routerLink]="'/training-schedules/my'" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
          <mat-icon>date_range</mat-icon>
          <span class="sidebar-item-span">{{ 'sections.trainingSchedules' | translate }}</span>
        </a>
      </div>
    </ng-container>
  </div>
  <div *ngIf="(hasMemberUpdatePermission() || getAssignPermission() || getAdminPermission() || getPeopleManagementPermission()) && (section === SECTIONS.TALENTS || section === SECTIONS.GROUPS)">
   <ng-container>
    <a *ngIf="companySettings && companySettings.enableOrga && (getAssignPermission() || getAdminPermission() || getPeopleManagementPermission())" class="sidebar-item" [class.active]="router.isActive('organizations', false)"
      [routerLink]="'/organizations/list'" fxLayoutGap="8px">
      <mat-icon>bubble_chart</mat-icon>
      <span class="sidebar-item-span">{{ 'sections.organizations' | translate }}</span>
     </a>
     <a *ngIf="getPermissionsService().getMemberPermissions().update || getPeopleManagementPermission()" class="sidebar-item" [class.active]="router.isActive('talents', false)"
        [routerLink]="'/talents/list'" fxLayoutGap="8px">
        <mat-icon>people</mat-icon>
        <span class="sidebar-item-span">{{ 'sections.talents' | translate }}</span>
      </a>
     <a *ngIf="getGroupPermissionUpdate()" class="sidebar-item" [class.active]="router.isActive('groups', false)"
        [routerLink]="'/groups/list'" fxLayoutGap="8px">
        <mat-icon>groups</mat-icon>
        <span class="sidebar-item-span">{{ 'sections.groups' | translate }}</span>
      </a>
   </ng-container>
  </div>
  <div *ngIf="section === SECTIONS.REPORTING" class="no-title"> 
    <ng-container>
      <a class="sidebar-item" [class.active]="router.isActive('reporting/groups', false)"
         [routerLink]="'/reporting/groups'" fxLayoutGap="8px">
         <mat-icon>groups</mat-icon>
         <span class="sidebar-item-span">{{ 'sections.groups' | translate }}</span>
      </a>
      <a class="sidebar-item" [class.active]="router.isActive('reporting/talents', false)"
         [routerLink]="'/reporting/talents'" fxLayoutGap="8px">
         <mat-icon>people</mat-icon>
         <span class="sidebar-item-span">{{ 'sections.talents' | translate }}</span>
      </a>
      <a class="sidebar-item" [class.active]="router.isActive('reporting/trainings', false)"
         [class.subactive]="router.isActive('reporting/ilt-courses', false) || router.isActive('reporting/courses', false) || router.isActive('reporting/learning-paths', false) || router.isActive('reporting/schedules', false)"
         [routerLink]="'/reporting/trainings'" fxLayoutGap="8px">
         <mat-icon class="mat-icon-subitem svg-item" svgIcon="books"></mat-icon>
         <span class="sidebar-item-span">{{ 'sections.trainings' | translate }}</span>
      </a>
      <a class="sidebar-item sidebar-subitem child" [class.active]="router.isActive('reporting/courses', false)"
         [routerLink]="'/reporting/courses'" fxLayoutGap="4px">
         <mat-icon class="mat-icon-subitem mat-icon-subitem-margin">school</mat-icon>
         <span class="sidebar-item-span sidebar-subitem-span">{{ 'sections.courses' | translate }}</span>
      </a>
      <a class="sidebar-item sidebar-subitem child" [class.active]="router.isActive('reporting/ilt-courses', false)"
         [routerLink]="'/reporting/ilt-courses'" fxLayoutGap="12px">
         <mat-icon class="mat-icon-subitem svg-item-mini mat-icon-subitem-margin" svgIcon="live_training_teacher"></mat-icon>
         <span class="sidebar-item-span sidebar-subitem-span">{{ 'sections.iltCourses' | translate }}</span>
      </a>
      <a class="sidebar-item sidebar-subitem child" [class.active]="router.isActive('reporting/learning-paths', false)"
         [routerLink]="'/reporting/learning-paths'" fxLayoutGap="4px">
         <mat-icon class="mat-icon-subitem mat-icon-subitem-margin">route</mat-icon>
         <span class="sidebar-item-span sidebar-subitem-span">{{ 'sidebar.learningPaths' | translate }}</span>
      </a>
      <a *ngIf="hasTrainingScheduleFeature" class="sidebar-item sidebar-subitem child" [class.active]="router.isActive('reporting/schedules', false)"
         [routerLink]="'/reporting/schedules'" fxLayoutGap="4px">
         <mat-icon class="mat-icon-subitem mat-icon-subitem-margin">date_range</mat-icon>
         <span class="sidebar-item-span sidebar-subitem-span">{{ 'sidebar.schedule' | translate }}</span>
      </a>
    </ng-container>
  </div>
  <div class="admin-area" *ngIf="false">
    <h3>{{ 'courses.sidebar.admin.quick' | translate }}</h3>
    <a class="sidebar-item" routerLinkActive="active"
       [routerLink]="'/feedback/list'">{{ 'courses.sidebar.admin.feedback' | translate }}</a>
    <a class="sidebar-item" routerLinkActive="active"
       [routerLink]="'/comments/list'">{{ 'courses.sidebar.admin.comments' | translate }}</a>
  </div>
  <app-settings-sidebar *ngIf="hasCourseCreatePermission() && section === SECTIONS.SETTINGS" [isSidebarExpanded]="isSidebarExpanded"></app-settings-sidebar>
</div>
